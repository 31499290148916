import React, {useEffect, useReducer, useRef, useState} from "react";
import {
  Alert,
  Autocomplete,
  AutocompleteValue,
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import {timezones} from "@/components/resource/timezone";
import {object, TypeOf, z} from "zod";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useDispatch, useSelector} from "react-redux";
import {getOrganSites} from "@/store/slices/Site";
import {getOrganZones} from "@/store/slices/Zone";
import DeviceService from "@/services/dms/DeviceService";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import WebURL from "@/urls";
import {useTranslation} from "react-i18next";
import ControlTextFieldReadOnly from "@/components/hook-form/ControlTextFieldReadOnly";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import SiteService from "@/services/location/SiteService";
import ZoneService from "@/services/location/ZoneService";
import ControlAutoCompleteTimeZone from "@/components/hook-form/ControlAutoCompleteTimeZone";
import ControlAutoCompleteBindSite from "@/components/hook-form/ControlAutoCompleteBindSite";
import { ConstructionOutlined } from "@mui/icons-material";

const deviceStyle = {
  '& .MuiPaper-root': {width: '100%'}
}

type DeviceBindingProps = {
  visible: boolean;
  onClose: () => void;
  device?: { deviceId: string, sn: string, deviceType:string };
}

const DeviceBinding: React.FC<DeviceBindingProps> = (props) => {
  const {t} = useTranslation()
  const deviceSchema = object({
    site: z.object({
      id: z.string(),
      name: z.string(),
      address: z.object({
        timeZone: z.string().nullish()
      })
    }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
    // zone: z.object({
    //   id: z.string(),
    //   name: z.string()
    // }),
    timezone: z.union([z.string({ required_error: t("common.This field is required!")}), z.object({
      value: z.string(),
      name: z.string()
    },
    { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")}
    )])
  })
  type deviceType = TypeOf<typeof deviceSchema>

  const theme = useTheme()
  const navigate = useNavigate()
  const deviceForm = useForm<deviceType>({
    resolver: zodResolver(deviceSchema)
  })
  const sites: { id: string, name: string, address: Record<string, any> }[] = useSelector((state: any) => state.site.sites)
  // const zones: { id: string, name: string }[] = useSelector((state: any) => state.zone.zones)
  const [zones, setZones] = useState<[]>([]);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch<any>(getOrganSites())
    // dispatch<any>(getOrganZones())
  }, [])
  const [loading, setLoading] = useState<boolean>(false);
  const [zoneLoading, setZoneLoading] = useState<boolean>(false);
  const timezoneList = timezones()
  const [timeZone, setTimeZone] = useState<string>();
  const [timezoneEmpty, setTimezoneEmpty] = useState<boolean>(false);

  const [zoneInputValue, setZoneInputValue] = useState<any>('');
  const [zoneValue, setZoneValue] = useState<any>(null);
  const [selectZone, setSelectZone] = useState<boolean>(true);

  const onSubmit = async (formData: any) => {
    console.log(formData)
  }

  const utcTimeZone = {
    value: '+00:00',
    name: '(UTC)Dublin, Edinburgh, Lisbon, London, The International Date Line West',
  }

  useEffect(() => {
    setTimezoneEmpty(true)
    deviceForm.reset({})
    setZones([]);
    setSelectZone(true);
    setZoneValue(null)
  }, [props.visible])

  return (
    <Dialog
      open={props.visible}
      onClose={() => {
        props.onClose
      }}
      sx={deviceStyle}
    >
      <DialogTitle>{t("dms.tip01")}</DialogTitle>
      <DialogContent sx={{overflowX: "hidden", padding: 0, m: 2}}>
        <Stack spacing={1}>
          {/*<Alert severity="success" sx={{alignItems: "center", justifyContent: "center"}}>*/}
          {/*  Successful*/}
          {/*</Alert>*/}
          <Box style={{ padding: "8px 0"}}>
            <Stack direction={"row"} spacing={2}>
              <Avatar sx={{width: 50, height: 50}}>
                <AdUnitsIcon sx={{fontSize: 30}} color="action"/>
              </Avatar>
              <Box sx={{fontSize: 16, display: "flex", alignItems: "center"}}>
                <Box sx={{color: 'gray'}}>{t("dms.Serial Number")}: {props.device?.sn}</Box>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Typography gutterBottom>{t("dms.tip02")}<br/></Typography>
            <Typography gutterBottom color={theme.palette.primary.main}>{t("dms.tip03")}</Typography>
          </Box>
          <Box component={"form"}>
            <Stack>
              <ControlAutoCompleteBindSite
                label={t("dms.Bind Site")} name={'site'} form={deviceForm}
                required={true}
                options={sites}
                id={"DMS-site-select"}
                onChange={(value) => {

                  if(value !== null) {
                    const timezone = value?.address.timeZone
                    deviceForm.setValue('timezone', timezoneList.find(item => item.value === timezone)?.name || `UTC${timezone}`)
                    setTimeZone(timezone)
                    // if(timezone === undefined) {
                    //   deviceForm.setValue('timezone', utcTimeZone)
                    //   setTimezoneEmpty(true)
                    //   setTimeZone(utcTimeZone.value);
                    // } else {
                    //   if (timezone) {
                    //     deviceForm.setValue('timezone', timezoneList.find(item => item.value === timezone)?.name || `UTC${timezone}`)
                    //     setTimezoneEmpty(false);
                    //     setTimeZone(undefined);
                    //   } else {
                    //     setTimezoneEmpty(true);
                    //   }
                    // }
                  } else {
                    deviceForm.reset({})
                    setTimezoneEmpty(true);
                  }
                  setZoneLoading(true);
                  setZones([]);
                  setZoneInputValue('');
                  setZoneValue(null);
                  if(value) {
                    ZoneService.list({keyword: value.id, current: 1, pageSize: 100}).then((zones) => {
                      const {data} = zones.data;
                      setZones(data);
                      setZoneLoading(false);
                    });
                  }
                  deviceForm.trigger()
                }}
              />
              {/* {
                timezoneEmpty
                ? <ControlAutoCompleteTimeZone label="" name={'timezone'} form={deviceForm}
                    options={timezoneList}
                    required={false}
                    groupBy={(option)=>option.group}
                    getOptionLabel={(option) => option.name || ""}
                    id={"DMS-timezone-select"}
                     onChange={({value}) => {
                       if(value) {
                        setTimeZone(value);
                       } else {
                          deviceForm.resetField("timezone")
                          // setTimeZone("")
                       }
                     }}
                  />
                : <ControlTextFieldReadOnly
                    label={t("dms.Site Time Zone")} name={'timezone'} form={deviceForm} required={false}
                  />
              } */}
              <div style={{ height: "7px"}}></div>
              <Autocomplete
                options={zones}
                loading={loading}
                value={zoneValue}
                id={"DMS-zone-select"}
                onChange={(_, v) => setZoneValue(v)}
                inputValue={zoneInputValue}
                onInputChange={(_, v) => {
                  setZoneInputValue(v)
                  setSelectZone(true)
                }}
                getOptionLabel={option => option.name}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label={t("dms.Bind Zone")}
                    required={true}
                    error={!selectZone}
                    helperText={(!selectZone && t("common.This field is required!")) || " "}
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: "false",
                      endAdornment: (
                        <React.Fragment>
                          {loading && <CircularProgress color="inherit" size={20} />}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />}
              />
            </Stack>
          </Box>
          <Box>
            {
              props.device?.deviceType == 'acc'
                ?<Alert severity="warning">{t("dms.warning01")}</Alert> : <></>
            }
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} sx={{justifyContent: 'flex-end', paddingRight: "10px"}} spacing={2}>
          <LoadingButton id='SaveButton' type={'submit'} loading={loading} size={"medium"} variant={"contained"}
            sx={{ width: "100px" }}
           onClick={async () => {
            setLoading(true)
            try {
              const isValid = await deviceForm.trigger()
              if (isValid && !!zoneValue) {
                setSelectZone(true);
                const values = deviceForm.getValues()
                // set regtistartion device default
                const origin = {
                  ...props.device,
                  siteId: values.site.id,
                  zoneId: zoneValue.id,
                  registrationDevice: 1
                };
                const site_res = timezoneEmpty ?
                await SiteService.update(values.site.id, {address: {timeZone, city: "default", addressLine1: "default", siteId: values.site.id}}) : {};
                const device_res = await DeviceService.deviceBinding(origin);
                props.onClose()
                navigate(WebURL.DMS_DEVICE)
              } else {
                // setSelectZone(false);
                if (!zoneInputValue) {
                  setSelectZone(false);
                }
                deviceForm.handleSubmit(onSubmit)()
              }
            } catch (e) {
              console.log(e)
            } finally {
              setLoading(false)
            }
          }}>{t("common.Save")}</LoadingButton>
          <Button id='CancelButton' size={"medium"} variant={"outlined"}
            sx={{ width: "100px" }}
            onClick={() => {
            // deviceForm.reset({})
            // setZones([]);
            // setSelectZone(true);
            // setZoneValue(null)
            props.onClose()
          }}>{t("common.Cancel")}</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
export default DeviceBinding
