import * as React from "react";
import {SnackbarProvider} from "notistack";
import {BrowserRouter} from "react-router-dom";
import MenuRoutes from "../routes";
import '../assets/layout.sass'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {SnackbarUtilsConfigurator} from "@/utils/SnackbarUtil";
import { AxiosHttpClient } from "@/utils/request/index";
import { AxiosAuthInterceptor } from "@/utils/request/interceptors/auth.interceptor";
import { AxiosSnackbarInterceptor } from "@/utils/request/interceptors/snackbar.interceptor";

const App = () => {

  React.useEffect(() => {
    AxiosHttpClient.getInstance().setInterceptorsObserver(new AxiosAuthInterceptor());
    AxiosHttpClient.getInstance().setInterceptorsObserver(new AxiosSnackbarInterceptor());
  }, [])

  return (
    <BrowserRouter basename={"/"}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <SnackbarUtilsConfigurator />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MenuRoutes/>
        </LocalizationProvider>
      </SnackbarProvider>
    </BrowserRouter>
  )
};
export default App;