import React from "react";
import CustomDataGrid from "@/components/data-grid/CustomDataGrid";
import WebURL from "@/urls";
import {RemoveRedEyeOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {GridColDef, GridColTypeDef} from "@mui/x-data-grid";
import {LocationEndpoints} from "@/services/location/endpoints";
import {timezones} from "@/components/resource/timezone";
import CustomRowButton from "@/components/data-grid/CustomRowButton";
import {useTranslation} from "react-i18next";
import { Tooltip } from "@mui/material";


const ZoneGird: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const timezoneList = timezones()
  const timezoneCol: GridColTypeDef = {
    type: "string",
    flex: 0.2,
    valueGetter: value => value.row.address.timeZone,
    renderCell: (params) => {
      const timezone = params.row.address.timeZone
      if(!timezone){
        return '-'
      }
      return timezoneList.find(option => option.value === timezone)?.name
    }
  }
  const columns: GridColDef[] = [
    {field: 'name', headerName: t("dms.Zone Name"), flex: 0.3},
    {field: 'site_name', headerName: t("dms.Site Name"), type: "string", minWidth: 120, flex: 0.2},
    {field: 'timezone', headerName: t("dms.Site Time Zone"), type: "string", minWidth: 200, flex: 0.4, ...timezoneCol},
    // {field: 'description', headerName: "Description", flex: 0.2},
    {
      field: 'actions',
      headerName: t("common.Action"),
      headerAlign: "center",
      type: "actions",
      align: "center",
      minWidth: 200,
      flex: 0.1,
      renderCell: (cell) => {
        return (
           <Tooltip title={t('common.View')} placement="bottom">
            <div>
              <CustomRowButton insideIcon={RemoveRedEyeOutlined} buttonProps={{
              key: "view",
              onClick: () => {
                navigate(WebURL.LOCATION_ZONE_VIEW, {state: {id: cell.id}});
              }
            }} />
            </div>
           </Tooltip>
        )
      }
    },
  ]
  return (
    <CustomDataGrid
      uri={LocationEndpoints.ZONE_URI}
      authedAction={"ZONE_MANAGE"}
      columns={columns}
      checkboxSelection={false}
      noResultFound={t("common.Zone no rows")}
      toolbar={{
        title: "Zone",
        breadcrumbs: [
          {breadcrumbName: t("common.sidebar.Site Management"), path: WebURL.LOCATION_HOME },
          {breadcrumbName: t("common.sidebar.Zone"), path: WebURL.LOCATION_ZONE}
        ]
      }}
    />
  )
}
export default ZoneGird
