import React, {useContext, useRef, useState} from "react";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import CustomDataGrid, { DataGridRef } from "@/components/data-grid/CustomDataGrid";
import { ATTEndpoints } from "@/services/att/endpoints";
import { useNavigate } from "react-router-dom";
import WebURL from "@/urls";

import { useSnackbar } from "notistack";
import {useTranslation} from "react-i18next";

const CalcTaskLog: React.FC = () => {
  const {t} = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { field: 'calc_key', headerName: "calc_key", flex: 2},
    { field: 'calc_created', headerName: "calc_created", type: 'date'},
    { field: 'calc_start', headerName: "calc_start", type: "date",},
    { field: 'calc_duration', headerName: "calc_duration", flex: 0.5},
    { field: 'calc_status', headerName: "calc_status", flex: 0.3},
    { field: 'trigger', headerName: "trigger", flex: 0.5},
  ]
  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <CustomDataGrid
        uri={ATTEndpoints.ATT_CALCTASK_LOG}
        authedAction={"SCHEDULE_MANAGE"}
        columns={columns}
        ref={dataGridRef}
        checkboxSelection={false}
        toolbar={{
          title: "Schedule",
          search: true,
          searchPlaceholder: 'Search by calc_key',
          breadcrumbs: [
            { breadcrumbName: "CalcTaskLog", path: WebURL.ATT_CALCTASK_LOG }
          ],
        }}
      />
    </Box>
  )
}
export default CalcTaskLog
