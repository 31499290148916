import React, {useContext, useEffect, useRef, useState} from "react";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import CustomDataGrid, { DataGridRef } from "../../../components/data-grid/CustomDataGrid";
import { ATTEndpoints } from "../../../services/att/endpoints";
import { Add, DeleteOutline, RemoveRedEyeOutlined } from "@mui/icons-material";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import { useNavigate } from "react-router-dom";
import WebURL from "../../../urls";
import Dialog from "../../../components/dialog";
import GenericService from "../../../services/GenericService";
import { useSnackbar } from "notistack";
import {useTranslation} from "react-i18next";
import HistoryAction from "@/components/actions/History";
import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import Client from "@/services";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import CustomDataGridSearchCollapse from "@/components/data-grid/CustomDataGridSearchCollapse";
import CompanyService from "@/services/auth/CompanyService";
import {HREndpoints} from "@/services/hr/endpoints";


const customEmployeeDataGridStyle = {
  '& .MuiDataGrid-columnHeaders': {backgroundColor: '#F0F1F4!important', color: '#304669!important'},
  '.MuiDataGrid-columnHeader:focus-within': {outline: 'none'},
  '.MuiDataGrid-cell': {borderBottom: '1px solid #E7EBF0', color: '#304669'},
  '.MuiDataGrid-cell:focus-within': {outline: 'none!important', color: '#36c96d!important'},
  '.MuiDataGrid-columnSeparator': {display: 'none !important'},
  '.MuiSvgIcon-root': {backgroundColor: 'transparent'},
  '.MuiDataGrid-root': {padding: '8px', borderRadius: '4px'},
  '.MuiDataGrid-row': {maxHeight: '48px', minHeight: '48px!important'},
  '.MuiCheckbox-root': {color: '#D1D1D1'},
  '.MuiDataGrid-cellContent': {
    color: '#304669!important',
    fontFamily: 'Roboto',
    letterSpacing: '0.4px',
    fontSize: '14px'
  },
  '.MuiDataGrid-virtualScrollerRenderZone': {backgroundColor: '#FFFF'},
  '.MuiDataGrid-footerContainer': {display: 'block', width: '100%', border: '0px'},
  '.MuiTablePagination-spacer': {display: 'none'},
  '.MuiTablePagination-actions': {position: 'absolute', right: '0px', backgroundColor: '#FFF'},
  '.MuiTablePagination-displayedRows': {position: 'absolute', right: '90px'},
  // '.MuiDataGrid-virtualScroller': {overflow: 'hidden'},
  '.MuiTablePagination-select': {backgroundColor: '#F0F1F4', borderRadius: '4px!important'},
  '.MuiTablePagination-toolbar': {paddingLeft: '10px'},
  '.MuiIconButton-root.Mui-disabled': {backgroundColor: '#F0F1F4', borderRadius: '0px'},
  // '.MuiIconButton-root': {borderRadius: '0px'},
  '.Mui-checked': {color: 'rgb(54, 201, 109)!important'},
}

const ExtraToolBar = () => {
  return (
    <Grid item={true}>
      <HistoryAction historyUrl={WebURL.ATT_SCHEDULE_HISTORY} />
    </Grid>
  )
}

const ScheduleDataGrid: React.FC = () => {
  const {t} = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dataGridRef = useRef<DataGridRef>();
  const personDataGridRef = useRef<DataGridRef>();
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useState<Record<any, any>>({});
  const [state, setState] = React.useState({
    right: false,
  });

  const [timeSheetName, setTimeSheetName] = React.useState("")
  const [totalPerson, setTotalPerson] = React.useState(0)

  React.useEffect( () => {

    const getCompanyInfo = async () => {
      try {
        const {data} = await CompanyService.companyInfo();
        return data
      } catch (error) {
        return undefined
      }
    }

    getCompanyInfo().then((obj) => {
      const data = {company_id: obj.id}
      Client().post(`${HREndpoints.HR_EMPLOYEE_URI}update_or_create_cache_employee/`, data)
    })

  }, [])


  const toggleDrawer = (anchor: any, open: any, data: any) => (event: any) => {
    setTimeSheetName(data.timesheet_name)
    setTotalPerson(data.person_total)
    setFilterParams({
      timesheet_name: data.timesheet_name,
      assigned_start: data.assigned_start,
      assigned_end: data.assigned_end
    })
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({...state, [anchor]: open});
  };
  const columns_person: GridColDef[] = [
    { field: 'employee_code', headerName: t("common.Person ID"), flex: 0.3, minWidth: 150, align: "left"},
    { field: 'employee_name', headerName: t("common.Person Name"), flex: 0.3, minWidth: 150},
    { field: 'department_name', headerName: t("common.Department"), flex: 0.3, minWidth: 150},
    {
      field: 'actions',
      headerName: t("common.Action"),
      headerAlign: "center",
      type: "actions",
      align: "center",
      minWidth: 10,
      flex: 0.1,
      renderCell: (cell) => {
        return (
          <Stack spacing={1} direction={"row"}>
            <Tooltip title={t('common.Delete')} placement="bottom">
            <div>
            <CustomRowButton insideIcon={DeleteOutline} buttonProps={{
              key: "delete",
              onClick: async () => {
                Dialog.confirm({
                  title: t("common.Are you sure to delete?"),
                  content: t("common.You want to delete this data permanently?"),
                  onConfirm: () => {
                    const data = {timesheet_id: cell.row.id};
                    Client().post(ATTEndpoints.ATT_SCHEDULE_DELETE_PERSON_URI, data).then(
                      (result) => {
                        enqueueSnackbar(t("common.Success"), {
                          variant: "success",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                          }
                        });
                        setFilterParams({
                          timesheet_name: cell.row.timesheet_name,
                          assigned_start: cell.row.assigned_start,
                          assigned_end: cell.row.assigned_end
                        })
                        // personDataGridRef.current?.refresh();
                        dataGridRef.current?.refresh();
                      });
                  }
                });
              }
            }} />
            </div>
            </Tooltip>
          </Stack>
        )
      }
    },
  ]
  const list = (anchor: any) => (
    <Box sx={{ maxWidth: "1024px", paddingRight: "5px" }}>
    <Box sx={{height: 600, width: '100%'}}>
      <CustomDataGridSearchCollapse
        // uri={`${ATTEndpoints.ATT_SCHEDULE_EMPLOYEE_URI}?timesheet_name=${timesheetName}&assigned_start=${assignedStart}&assigned_end=${assignedEnd}`}
        uri={`${ATTEndpoints.ATT_SCHEDULE_EMPLOYEE_URI}`}
        authedAction={"SCHEDULE_MANAGE"}
        columns={columns_person}
        requestParams={filterParams}
        ref={personDataGridRef}
        getRowId={(row) => {
          return Math.random()
        }}
        checkboxSelection={false}
        toolbar={{
          title: 'att',
          search: true,
          refresh: false,
          searchPlaceholder: t("att.report.Search by Person ID, Person Name"),
        }}
        sx={customEmployeeDataGridStyle}
      />
    </Box>
    </Box>
  );
  const columns: GridColDef[] = [
    { field: 'timesheet_name', headerName: t("att.timesheet.Timesheet Name"), flex: 0.2},
    { field: 'person_total', headerName: t("att.timesheet.Total Person"), flex: 0.2, renderCell: (e) => (
        <div onClick={(event) => event.stopPropagation()}>
          {['right',].map((anchor: any) => (
            <React.Fragment key={anchor}>
              <Link sx={{ cursor: 'pointer' }} title={"Click to view the persons in the " + e.row.timesheet_name + " Timesheet."} onClick={toggleDrawer(anchor, true, e.row)}>{e.row.person_total || 'None'}</Link>
                <Drawer
                  anchor={anchor}
                  open={state['right']}
                  onClose={toggleDrawer(anchor, false, '')}
                  PaperProps={{ sx: {
                    width: '50%',
                  } }}
                  sx= {{
                    '& .MuiModal-backdrop' : {
                      backgroundColor: "rgba(0, 0, 0, 0.1)"
                    },
                  }}
                >
                  <Box>
                    <Box  sx={{paddingLeft: '10px', paddingBottom: '10px', paddingTop: '15px' }} >
                      <Grid container>
                      <Grid item xs={12} >
                        <Grid container={true} rowSpacing={2} columnSpacing={6} >
                        <Grid item={true} xs={7}  md={6}>
                          <Typography  sx={{ fontSize: '20px', fontWeight: "500", color: "#304669"}}>{ t("att.timesheet.Schedule person")}  ({totalPerson || 'None'}) </Typography>
                        </Grid>
                        <Grid item={true} xs={5}  md={6}>
                          <Box style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: "34px", paddingTop: "5px" }}>
                            <Tooltip title={t("common.Close")}>
                              <CloseIcon onClick={toggleDrawer(anchor, false, '')} sx={{ cursor: 'pointer' }}/>
                            </Tooltip>
                          </Box>
                        </Grid>
                        </Grid>
                      </Grid>
                      </Grid>
                  </Box>
                  <Divider sx={{ paddingTop: "10px"}} />
                  {list(anchor)}
                  </Box>
                </Drawer>
            </React.Fragment>
          ))}
        </div>
      )
    },
    { field: 'assigned_start', headerName: t("att.timecard.Start Date" ), type: "date", flex: 0.2 },
    { field: 'assigned_end', headerName: t("att.timecard.End Date"), type: "date", flex: 0.2 },
    {
      field: 'actions',
      headerName: t("common.Action"),
      headerAlign: "center",
      type: "actions",
      align: "center",
      minWidth: 200,
      flex: 0.3,
      renderCell: (cell) => {
        return (
          <Stack spacing={1} direction={"row"}>
            <Tooltip title={t('common.Delete')} placement="bottom">
            <div>
            <CustomRowButton insideIcon={DeleteOutline} buttonProps={{
              key: "delete",
              onClick: async () => {
                Dialog.confirm({
                  title: t("common.Are you sure to delete?"),
                  content: t("common.You want to delete this data permanently?"),
                  onConfirm: () => {
                    const data = {
                      timesheet_name: cell.row.timesheet_name,
                      assigned_start: cell.row.assigned_start,
                      assigned_end: cell.row.assigned_end
                    };
                    const del_sheet_name = cell.row.timesheet_name;
                    const del_assigned_start = cell.row.assigned_start;
                    const del_assigned_end = cell.row.assigned_end;
                    Client().post(`${ATTEndpoints.ATT_SCHEDULE_DELETE_URI}?timesheet_name=${del_sheet_name}&assigned_start=${del_assigned_start}&assigned_end=${del_assigned_end}`, data).then(
                      (result) => {
                        enqueueSnackbar(t("common.Success"), {
                          variant: "success",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                          }
                        });
                        dataGridRef.current?.refresh();
                      });
                  }
                });
              }
            }} />
            </div>
            </Tooltip>
          </Stack>
        )
      }
    },
  ]
  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <CustomDataGrid
        uri={ATTEndpoints.ATT_SCHEDULE_URI}
        authedAction={"SCHEDULE_MANAGE"}
        columns={columns}
        ref={dataGridRef}
        checkboxSelection={false}
        noResultFound={t("att.timesheet.No schedule found")}
        getRowId={(row) => {
          // return (row.timesheet_id + row.assigned_start)
          return Math.random()
        }}
        toolbar={{
          title: "Schedule",
          search: true,
          searchPlaceholder: t("att.report.TimeSheet"),
          breadcrumbs: [
            { breadcrumbName: "", path: WebURL.ATT_RULE_HOME},
            { breadcrumbName: t("common.sidebar.Schedule"), path: WebURL.ATT_SCHEDULE }
          ],
          actions: [{
            key: 'add', authedAction: "SCHEDULE_ADD", icon: Add, helpTxt: t("common.Add"), callback: async () => {
              navigate(WebURL.ATT_SCHEDULE_NEW);
            }
          }],
          children: <ExtraToolBar/>
        }}
      />
    </Box>
  )
}
export default ScheduleDataGrid
