import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export interface Observer {
  id: string;
  update(config: AxiosRequestConfig): any;
  doResponse(response: AxiosResponse): any;
  doError(err: AxiosError): any;
}

export abstract class BaseObserver implements Observer {
  abstract id: string;

  update(config: AxiosRequestConfig<any>) {
    return config;
  }

  doResponse(response: AxiosResponse<any, any>) {
    return response;
  }

  doError(err: AxiosError<unknown, any>) {
    return err;
  }
}

