import {useState, useEffect} from "react";
import PermissionService from "@/services/auth/PermissionService";
import RolePermissionService from "@/services/base/RolePermissionService"

export const SetRolePerms = async () => {
  // 获取该角色的权限列表，并存入全局变量中。 登录后/从zlink跳转后/rolePermission变更后 调用
  console.log("start Set Role Perms")
  const allPermsList = await RolePermissionService.ownPermissions()
  localStorage.setItem("tmpUserPerms", JSON.stringify(allPermsList.own_permissions || []));
  console.log("finish")
}

