import axios, { AxiosInstance } from "axios";
import { AxiosInterceptorSubject, InterceptorSubject } from "./subject";
import { Observer } from "./observer";
import { CustomAxiosRequestConfig, CustomAxiosResponse } from "./interface";
import { TIMEOUT_TIME } from "./config"

export class AxiosHttpClient {
  private okHttp: AxiosInstance;
  private CancelToken = axios.CancelToken;
  private $interceptorSubject: InterceptorSubject;

  private cache: Map<string | undefined, any> = new Map();

  private static instance: AxiosHttpClient;
  public static getInstance() {
    if (!AxiosHttpClient.instance) {
      AxiosHttpClient.instance = new AxiosHttpClient();
    }
    return AxiosHttpClient.instance;
  }

  public constructor() {
    this.$interceptorSubject = new AxiosInterceptorSubject();
    this.okHttp = axios.create({ timeout: TIMEOUT_TIME, responseType: "json" })

    this.okHttp.interceptors.request.use(config => {
      config = this.$interceptorSubject.notifyRequest(config);
      return config;
    })

    this.okHttp.interceptors.response.use(
      response => {
        response = this.$interceptorSubject.notifyResponse(response);
        return response;
      },
      err => {
        // The canceled request
        // we need to move the message to the response resolve for a normal call.
        if (axios.isCancel(err)) {
          return Promise.resolve(err.message);
        }
        console.log({ err });
        err = this.$interceptorSubject.notifyError(err);
        return Promise.reject(err);
      }
    )
  }

  public setCache(key: string, value: any) {
    this.cache.set(key, value);
  }

  public getCache(key: string | undefined) {
    return this.cache.get(key);
  }

  public getCancelToken() {
    return this.CancelToken;
  }

  public setInterceptorsObserver(ob: Observer) {
    this.$interceptorSubject.attach(ob);
  }

  public removeInterceptorSubject(obId: string) {
    this.$interceptorSubject.detach(obId);
  }

  /**
   * proxy to axios
   */

  public request<T = any, R = CustomAxiosResponse<T>, D = any>(config: CustomAxiosRequestConfig<D>): Promise<R> {
    return this.okHttp.request(config);
  }

  public async get<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>): Promise<R> {
    return this.okHttp.get<T, R>(url, config);
  }

  public async post<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R> {
    return this.okHttp.post<T, R>(url, data, config);
  }

  public async put<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R> {
    return this.okHttp.put<T, R>(url, data, config);
  }

  public async delete<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>): Promise<R> {
    return this.okHttp.delete(url, config);
  }

  public async patch<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R> {
    return this.okHttp.patch(url, data, config);
  }

  public async postForm<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R> {
    return this.okHttp.postForm(url, data, config);
  }
}

export default AxiosHttpClient;
