import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import GenericService from "@/services/GenericService";
import {LocationEndpoints} from "@/services/location/endpoints";
import _ from "lodash";

const initialState = {
  zones: [],
}

export const getOrganZones = createAsyncThunk('zone/byOrgan', async () => {
  const {data} = await GenericService.list(LocationEndpoints.ZONE_URI, {current: 1, pageSize: 50});
  return data;
})

const zoneSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganZones.fulfilled, (state, action) => {
      if(_.isEqual(state.zones, action.payload.data)){
        console.log("zone>lodash>>Equal>>>>>", action.payload.data)
        return state
      }
      return {
        zones: action.payload.data
      }
    })
  }
});
export default zoneSlice.reducer
