const APITRANS: Record<string, any> = {
  "Wrong time range selection for Day [1]": {
    "en": "Wrong time range selection for Day [1]",
    "en-US": "Wrong time range selection for Day [1]",
    "id": "Wrong time range selection for Day [1]",
    "th": "Wrong time range selection for Day [1]",
    "vi": "Wrong time range selection for Day [1]",
    "vn": "Day [1] အတွက် အချိန်အပိုင်းအခြား ရွေးချယ်မှု မှားယွင်းနေသည်",
    "zh-CN": "Wrong time range selection for Day [1]"
    },
  "Wrong time range selection for Day [2]": {
    "en": "Wrong time range selection for Day [2]",
    "en-US": "Wrong time range selection for Day [2]",
    "id": "Wrong time range selection for Day [2]",
    "th": "Wrong time range selection for Day [2]",
    "vi": "Wrong time range selection for Day [2]",
    "vn": "Day [2] အတွက် အချိန်အပိုင်းအခြား ရွေးချယ်မှု မှားယွင်းနေသည်",
    "zh-CN": "Wrong time range selection for Day [2]"
    },
  "Wrong time range selection for Day [3]": {
      "en": "Wrong time range selection for Day [3]",
      "en-US": "Wrong time range selection for Day [3]",
      "id": "Wrong time range selection for Day [3]",
      "th": "Wrong time range selection for Day [3]",
      "vi": "Wrong time range selection for Day [3]",
      "vn": "Day [3] အတွက် အချိန်အပိုင်းအခြား ရွေးချယ်မှု မှားယွင်းနေသည်",
      "zh-CN": "Wrong time range selection for Day [3]"
    },
  "Wrong time range selection for Day [4]": {
      "en": "Wrong time range selection for Day [4]",
      "en-US": "Wrong time range selection for Day [4]",
      "id": "Wrong time range selection for Day [4]",
      "th": "Wrong time range selection for Day [4]",
      "vi": "Wrong time range selection for Day [4]",
      "vn": "Day [4] အတွက် အချိန်အပိုင်းအခြား ရွေးချယ်မှု မှားယွင်းနေသည်",
      "zh-CN": "Wrong time range selection for Day [4]"
      },
  "Wrong time range selection for Day [5]": {
      "en": "Wrong time range selection for Day [5]",
      "en-US": "Wrong time range selection for Day [5]",
      "id": "Wrong time range selection for Day [5]",
      "th": "Wrong time range selection for Day [5]",
      "vi": "Wrong time range selection for Day [5]",
      "vn": "Day [5] အတွက် အချိန်အပိုင်းအခြား ရွေးချယ်မှု မှားယွင်းနေသည်",
      "zh-CN": "Wrong time range selection for Day [5]"
      },
  "Wrong time range selection for Day [6]": {
      "en": "Wrong time range selection for Day [6]",
      "en-US": "Wrong time range selection for Day [6]",
      "id": "Wrong time range selection for Day [6]",
      "th": "Wrong time range selection for Day [6]",
      "vi": "Wrong time range selection for Day [6]",
      "vn": "Day [6] အတွက် အချိန်အပိုင်းအခြား ရွေးချယ်မှု မှားယွင်းနေသည်",
      "zh-CN": "Wrong time range selection for Day [6]"
      },
  "Wrong time range selection for Day [7]": {
      "en": "Wrong time range selection for Day [7]",
      "en-US": "Wrong time range selection for Day [7]",
      "id": "Wrong time range selection for Day [7]",
      "th": "Wrong time range selection for Day [7]",
      "vi": "Wrong time range selection for Day [7]",
      "vn": "Day [7] အတွက် အချိန်အပိုင်းအခြား ရွေးချယ်မှု မှားယွင်းနေသည်",
      "zh-CN": "Wrong time range selection for Day [7]"
      },
  }

export default APITRANS
