import React, {useEffect, useState} from "react";
import {Autocomplete, Avatar, Button, CircularProgress, Grid, Paper, Stack, TextField} from "@mui/material";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {grey} from "@mui/material/colors";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import {object, TypeOf, z} from "zod";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {timezones} from "@/components/resource/timezone";
import {useDispatch, useSelector} from "react-redux";
import {getOrganSites} from "@/store/slices/Site";
import {getOrganZones} from "@/store/slices/Zone";
import {LoadingButton} from "@mui/lab";
import GenericService from "@/services/GenericService";
import {DMSEndpoints} from "@/services/dms/endpoints";
import {useTranslation} from "react-i18next";
import WebURL from "@/urls";
import ControlTextFieldReadOnly from "@/components/hook-form/ControlTextFieldReadOnly";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import ControlTextField from "@/components/hook-form/ControlTextField";
import SiteService from "@/services/location/SiteService";
import DeviceService from "@/services/dms/DeviceService";
import ZoneService from "@/services/location/ZoneService";
import SnackbarUtil from '@/utils/SnackbarUtil';
import { useSnackbar } from "notistack";

const DeviceEdit: React.FC = () => {
  const {t} = useTranslation()
  const { enqueueSnackbar } = useSnackbar();
  const deviceSchema = object({
    alias: z.string().min(1, t("common.This field is required!")),
    timezone: z.union([z.string().nullish(), z.object({
      value: z.string(),
      name: z.string()
    }).nullish()]),
    sn: z.string().nullish(),
    model: z.string().nullish(),
    ipv4: z.string().nullish(),
    firmware_ver: z.string().nullish(),
    status: z.string().nullish(),
    parameter_ip: z.string().nullish()
  })
  type deviceFormType = TypeOf<typeof deviceSchema>
  const location = useLocation()
  const state = location.state as { id: string }
  const navigate = useNavigate()

  const timezoneList = timezones()
  const sitesInitial: { id: string, name: string, address: Record<string, any> }[] = useSelector((state: any) => state.site.sites)
  const zonesInitial: { id: string, name: string }[] = useSelector((state: any) => state.zone.zones)
  const deviceForm = useForm<deviceFormType>({
    resolver: zodResolver(deviceSchema),
    defaultValues: {
      alias: "",
      sn: "",
      timezone: "",
      model: "",
      status: "",
      ipv4: "",
      firmware_ver: "",
      parameter_ip: ""
    }
  })
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [timeZone, setTimeZone] = useState<string>();
  const [timezoneEmpty, setTimezoneEmpty] = useState<boolean>(false);

  const [siteInputValue, setSiteInputValue] = useState<any>('');
  const [siteValue, setSiteValue] = useState<any>(null);
  const [selectSite, setSelectSite] = useState<boolean>(true);

  const [zones, setZones] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [zoneInputValue, setZoneInputValue] = useState<any>('');
  const [zoneValue, setZoneValue] = useState<any>(null);
  const [selectZone, setSelectZone] = useState<boolean>(true);
  const [aliasMsg, setAliasMsg] = useState<string | null>(null);

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch<any>(getOrganSites())
    dispatch<any>(getOrganZones())
  }, [])
  useEffect(() => {
    GenericService.retrieve(DMSEndpoints.DMS_DEVICE_URI, state.id || "").then(res => {
      const device = res.data
      const site = sitesInitial.find(item => item.id === device.site)
      const zone = zonesInitial.find(item => item.id === device.zone)
      const timezone = timezoneList.find(item => item.value === site?.address?.timeZone)
      deviceForm.reset({
        timezone: timezone?.name,
        alias: device.alias,
        sn: device.sn,
        model: device.model,
        ipv4: device.ipv4 || "",
        firmware_ver: device.firmware_ver || "",
        status: device.status === '1' ? t("dms.Online") : t("dms.Offline"),
        parameter_ip: device.parameters?.ip
      })
      setSiteValue(site || {id: device.site, name: device.site_name});
      setSiteInputValue(device.site_name);
      ZoneService.list({keyword: device.site, current: 1, pageSize: 100}).then((zones) => {
        const {data} = zones.data;
        setZones(data);
      });
      // setZoneValue(device.zone_name);
      setZoneValue(zone || {id: device.zone, name: device.zone_name});
    })
  }, [sitesInitial, zonesInitial])

  const onSaveEdit = async () => {
    setSaveLoading(true)
    try {
      const isValid = await deviceForm.trigger()
      if (isValid && !!zoneValue && !!siteValue) {
        setSelectZone(true);
        setSelectSite(true);
        const values = deviceForm.getValues()

        if(values.alias.length > 50) {
          enqueueSnackbar(
            t("dms.Device Name can not be greater than 50 characters"),
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            }
          );
          setSaveLoading(false)
          return;
        }

        const origin = {
          deviceAlias: values.alias,
          siteId: siteValue.id,
          zoneId: zoneValue.id
        }
        const site_res = timezoneEmpty ?
        await SiteService.update(siteValue.id, {address: {timeZone, city: "default", addressLine1: "default", siteId: siteValue.id.id}}) : {}
        const device_res = await DeviceService.update(state.id || '', origin);
        SnackbarUtil.success(t("common.Success"))
        navigate(WebURL.DMS_DEVICE)
      } else {
        !zoneValue && setSelectZone(false);
        !siteValue && setSelectSite(false);
      }
    } catch (e) {
      console.log(e)
    } finally {
      setSaveLoading(false)
    }
  }
  return (
    <>
      <CustomBreadcrumbs title={t("dms.breadcrumbDeviceEdit")}/>
      <Paper elevation={0} sx={{ paddingTop: '20px'}}>
        <Grid container spacing={2}>
          <Grid item lg={3} xs={4} sx={{display: "flex", justifyContent: "center"}}>
            <Avatar sx={{width: 200, height: 200, bgcolor: grey[100]}}>
              <AdUnitsIcon sx={{fontSize: 150}} color="action"/>
            </Avatar>
          </Grid>
          <Grid item lg={4.3} xs={4}>
            <Controller
              name={"alias"}
              control={deviceForm.control}
              render={({ field, fieldState }) => {
                if (deviceForm.getValues("sn")) {
                  if (!field.value) {
                    setAliasMsg(t("common.This field is required!"));
                  } else {
                    setAliasMsg(null)
                  }
                }
                return (
                  <TextField
                    fullWidth
                    required={true}
                    label={t('dms.Device Name')}
                    error={!!fieldState.error || !!aliasMsg}
                    helperText={fieldState.error?.message || aliasMsg || " "}
                    {...field}
                    InputLabelProps={{
                      shrink: !!field.value
                    }}
                  />
                )
              }}
            />
            <Autocomplete
              options={sitesInitial}
              loading={loading}
              value={siteValue}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(_, value) => {
                const timezone = value?.address.timeZone;
                if (timezone) {
                  deviceForm.setValue('timezone', timezoneList.find(item => item.value === timezone)?.name || `UTC${timezone}`)
                  setTimezoneEmpty(false);
                  setTimeZone(undefined);
                } else {
                  setTimezoneEmpty(true);
                }
                setSiteValue(value);
                setZones([]);
                setZoneInputValue('');
                setZoneValue(null);
                if(value) {
                  ZoneService.list({keyword: value.id, current: 1, pageSize: 100}).then((zones) => {
                    const {data} = zones.data;
                    setZones(data);
                  });
                }
              }}
              inputValue={siteInputValue}
              onInputChange={(_, v) => {
                setSiteInputValue(v);
                if (v) setSelectSite(true)
                else {
                  setSelectSite(false);
                  setSelectZone(false);
                }
              }}
              getOptionLabel={option => option.name}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={t("dms.Bind Site")}
                  required={true}
                  error={!selectSite}
                  helperText={(!selectSite && t("common.This field is required!")) || " "}
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: "false",
                    endAdornment: (
                      <React.Fragment>
                        {loading && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />}
            />
            <Autocomplete
              options={zones}
              loading={loading}
              value={zoneValue}
              onChange={(_, v) => {
                setZoneValue(v);
                if (v) setSelectZone(true)
                else setSelectZone(false)
              }}
              inputValue={zoneInputValue}
              onInputChange={(_, v) => setZoneInputValue(v)}
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={t("dms.Bind Zone")}
                  required={true}
                  error={!selectZone}
                  helperText={(!selectZone && t("common.This field is required!")) || " "}
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: "false",
                    endAdornment: (
                      <React.Fragment>
                        {loading && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />}
            />
            {timezoneEmpty
              ? <ControlAutoComplete label="" name={'timezone'} form={deviceForm} options={timezoneList} required={true}
                  groupBy={(option)=>option.group} isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={(option) => option.name || ""}
                  onChange={({value}) => {
                    setTimeZone(value);
                  }}
                />
              :
              <ControlTextFieldReadOnly label={t("dms.Site Time Zone")} name={"timezone"} form={deviceForm}/>
            }
            <ControlTextFieldReadOnly label={t("dms.IP Address")} name={"parameter_ip"} form={deviceForm}/>
            <ControlTextFieldReadOnly label={t("dms.Firmware Ver.")} name={"firmware_ver"} form={deviceForm}/>
          </Grid>
          <Grid item lg={4.3} xs={4}>
            <ControlTextFieldReadOnly label={t("dms.Serial Number")} name={"sn"} form={deviceForm}/>
            <ControlTextFieldReadOnly label={t("dms.Device Model")} name={"model"} form={deviceForm}/>
            <ControlTextFieldReadOnly label={t("dms.Status")} name={"status"} form={deviceForm}/>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={3} xs={4} sx={{display: "flex", justifyContent: "center"}}>
          </Grid>
          <Grid item lg={4.3} xs={4}>
          </Grid>
          <Grid item lg={4.3} xs={4}>
              <Stack direction={"row"} spacing={2} sx={{justifyContent: "end"}}>
                <LoadingButton sx={{ width: "100px" }} id='SaveButton' loading={saveLoading} variant={"contained"} onClick={deviceForm.handleSubmit(onSaveEdit)}>
                  {t('common.Save')}
                </LoadingButton>
                <Button sx={{ width: "100px" }} id='CancelButton' variant={"outlined"} onClick={() => navigate(WebURL.DMS_DEVICE)}>
                  {t('common.Cancel')}
                </Button>
              </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
export default DeviceEdit
