import React from "react";
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WebURL from "@/urls";
import {useTranslation} from "react-i18next";
import EligibleMenu from "@/pages/index"

const LocationHome: React.FC = () => {
  const {t} = useTranslation()
  const items = [
    {name: "site_list", title: t("common.sidebar.Site"), icon: ApartmentIcon, path: WebURL.LOCATION_SITE},
    {name: "zone_list", title: t("common.sidebar.Zone"), icon: LocationOnIcon, path: WebURL.LOCATION_ZONE}
  ]
  return (
    <EligibleMenu title={t("common.sidebar.Site Management")} menus={items}/>
  )
}
export default LocationHome
