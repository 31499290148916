import React, { useEffect, useState, useMemo } from "react";
import { Box, Button, Grid } from "@mui/material";

import { TransferedProps } from "./Sheet.Types";

const TransferedClick: React.FC<TransferedProps> = (props: TransferedProps) => {

  return (

    <Grid container direction="column" alignItems="center">
      <Box sx={{ height: 70 }} />
      <Button sx={{ my: 0.5 }} variant="outlined" size="small" aria-label="move all right" onClick={props.handleAllRight}
      disabled={ (props.isTotalPageSame === false) ? true: props.leftChecked.length === 0}>
      {/* disabled={(props.isTotalPageSame === false) ? true : (props.isDepartmentLeftSearchEmpty === false) ? true : props.left.length === 0 }> */}
        ≫
      </Button>
      <Box sx={{ height: 40 }} />
      <Button sx={{ my: 0.5 }} variant="outlined" size="small" aria-label="move selected right"
        onClick={props.handleCheckedRight}
        disabled={ (props.isTotalPageSame === false) ? true: props.leftChecked.length === 0}>
        &gt;
      </Button>
      <Box sx={{ height: 40 }} />
      <Button sx={{ my: 0.5 }} variant="outlined" size="small" aria-label="move selected left"
      onClick={props.handleCheckedLeft}
      disabled={props.rightChecked.length === 0}>
        &lt;
      </Button>
      <Box sx={{ height: 40 }} />
      <Button sx={{ my: 0.5 }} variant="outlined" size="small" aria-label="move all left"
        onClick={props.handleAllLeft}
        disabled={(props.isDepartmentRightSearchEmpty === false) ? true : props.right.length === 0}>
        ≪
      </Button>
      <Box sx={{ height: 40 }} />
    </Grid>

  );
};

export default TransferedClick;



