import Client from "..";
import {BaseEndpoints} from "./endpoints";

const view = async (params: Record<string, any>) => {
  const response = await Client().get(BaseEndpoints.BASE_ROLEPERMISSION_URI, {params});
  return response.data;
}

const edit = async (roldId: string, data: Array<Base.RolePermssion>) => {
    const response = await Client().post(`${BaseEndpoints.BASE_ROLEPERMISSION_URI}${roldId}/edit/`, data);
  return response.data;
}

const ownPermissions = async () => {
  const response = await Client().get(`${BaseEndpoints.BASE_ROLEPERMISSION_URI}own_permission/`);
  return response.data;
}

const RolePermissionService = {
  view,
  edit,
  ownPermissions,
}

export default RolePermissionService