import Client from "@/services";
import {ATTEndpoints} from "../endpoints";

const exportData = async (params: string, data: any) => {
  const url = `${ATTEndpoints.ATT_PUNCH_RECORD_URI}export/?${params}`;
  const response = await Client().post(url, data, {responseType: "blob"});
  return response;
}

const PunchRecordService = {
  exportData
}
export default PunchRecordService
