import {useSnackbar, VariantType, WithSnackbarProps, OptionsObject} from 'notistack'
import React from 'react'

let useSnackbarRef: WithSnackbarProps
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar()
  return null
}
const toast = (msg: React.ReactNode, options?: OptionsObject) => {
  useSnackbarRef.enqueueSnackbar(msg, options)
}
const success = (msg: React.ReactNode, options?: OptionsObject) => {
  const default_options: OptionsObject = {
    'variant': 'success',
    'autoHideDuration': 2000,
    'anchorOrigin': {vertical: 'top', horizontal: 'center'},
  };
  const config = {...default_options, ...options};
  toast(msg, config)
}
const warning = (msg: React.ReactNode, options?: OptionsObject) => {
  const default_options: OptionsObject = {
    'variant': 'warning'
  };
  const config = {...default_options, ...options};
  toast(msg, config)
}
const info = (msg: React.ReactNode, options?: OptionsObject) => {
  const default_options: OptionsObject = {
    'variant': 'info'
  };
  const config = {...default_options, ...options};
  toast(msg, config)
}
const errorMsg = (msg: React.ReactNode, options?: OptionsObject) => {
  const default_options: OptionsObject = {
    'variant': 'error'
  };
  const config = {...default_options, ...options};
  toast(msg, config)
}
export default {
  success,
  warning,
  info,
  error: errorMsg,
  toast
}
