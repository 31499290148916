import _ from "lodash";
import { EMPTYPES } from "./Sheet.Types";

export function union(a: any[], b: any[]) {
  return [...a, ...not(b, a)];
}

const intersect = function (arr1: any[], arr2: any[]) {
  const intersect: any[] = [];
  _.each(arr1, function (a) {
      _.each(arr2, function (b) {
          if (compare(a, b))
              intersect.push(a);
      });
  });

  return intersect;
};

export const unintersect = function (arr1: any[], arr2: any[]) {
  const unintersect: any[] = [];
  _.each(arr1, function (a) {
    let found = false;
      _.each(arr2, function (b) {
          if (compare(a, b)) {
              found = true;
          }
      });

      if (!found) {
          unintersect.push(a);
      }
  });

  return unintersect;
};

export function compare(a: EMPTYPES, b: EMPTYPES) {
  if (a.id  === b.id )
      return true;
  else return false;
}

export function not(a: EMPTYPES[], b: EMPTYPES[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

export function intersection(a: EMPTYPES[], b: EMPTYPES[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export const customBoxStyle = {
  "&.MuiBox-root": {
    color: 'rgba(71, 75, 79, 0.8) !important',
    letterSpacing: '0.4px',
    fontSize: { xl: '16px', lg: "14px", md: "14px", sm: "14px", xs: "14px" },
    border: "none",
    lineHeight: "19px",
    padding: "0px",
    height: { xl: "60px", xs: "50px" },
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }
}

export const checkStringLength = ( value: string | undefined, length: number) : string | undefined  => {
  if(value !== undefined) {
    if(value?.length >= length) {
      return value?.substring(0, length) + '...'
    } else {
      const resultLength = length - value.length
      return value + ' '.repeat(resultLength)
    }
  }
}

export const stringLimitLength = 18
