import React, {useEffect} from "react";
import {AppBar, Box, Container, Toolbar} from "@mui/material";
import CompanyProfile from "./components/CompanyProfile";
import UserProfile from "./components/UserProfile";
import Help from "./components/Help";
import ProductLogo from "./components/ProductLogo";
import {useDispatch} from "react-redux";
import {getInitialState} from "../../store/slices/App";


const Header: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getInitialState());
  }, [])
  return (
    <AppBar position={"static"}>
      <Container maxWidth='xl' style={{padding: '0px 12px', margin: '0px', minWidth: '100%'}}>
        <Toolbar disableGutters>
          <ProductLogo/>
          <Box sx={{flexGrow: 1}}/>
          <Box display='flex'>
            <Help/>
            <CompanyProfile/>
            <UserProfile/>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
