import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon404 } from "@/assets/icons/404Icon.svg";
function PageNotFound() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "100px"
      }}
      id={"error-box"}
    >
      <Box sx={{
        display: { xl: "flex", xs: "none" },
        flexDirection: "column",
        alignItems: "center",
      }}>
        <Icon404 />
        <Box mt={9} >
          <Typography >{t("common.The page you are looking for might have been removed or is temporarily unavailable")}</Typography>
        </Box>
      </Box>
      <Box sx={{
        display: { xl: "none", xs: "flex" },

        flexDirection: "column",
        alignItems: "center",
      }}>
        <Icon404 width={500} height={250} />
        <Box mt={3} >
          <Typography >{t("common.The page you are looking for might have been removed or is temporarily unavailable")}</Typography>
        </Box>
      </Box>


    </Box >
  )
}
export default PageNotFound
