import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import _ from "lodash";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from "react-i18next";
import SearchBarRight from "./SearchBarRight";
import DepartmentListRight from "./DepartmentListRight";
import { TransferedEmployeeProps } from "./Sheet.Types";
import { Popover } from 'antd';
import { customBoxStyle, checkStringLength, stringLimitLength } from "./utils";
import NoRecordFound from "./NoRecordFound";


const TransferListRight: React.FC<TransferedEmployeeProps> = (props: TransferedEmployeeProps) => {
  const { t } = useTranslation();
  const [searchError, setSerachError] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(false);

  const contentUI = (items:any) => (
    <Box maxHeight={"300px"} maxWidth={"180px"} mr={-2} overflow={"auto"}>
      {
        items.map((obj: any, index: number) => {
            return (
              <Box key={index} display={"flex"} alignItems={"center"}
                sx={{
                  ":hover": {
                    backgroundColor: '#36c96d1f',
                    borderRadius: "6px"
                  },
                  cursor: 'pointer',
                  padding: "2px"
                }}
              >
                <Typography pl={"5px"}>
                  {`${obj.firstName} ${obj.lastName}`}
                </Typography>
              </Box>
            );
        })
      }
    </Box>
  );

  const handleHoverChange = (open:  React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement> | boolean) => {
    if (props.rightChecked.length > 0) {
      if (open)
        setAnchorEl(true)
      else
        setAnchorEl(false)
    } else {
      setAnchorEl(false)
    }
  };

  return (
    <Box
        sx={{
          position: "relative",
          border: "1px solid #DDDDDD",
          width: "90%",
          borderRadius: { xl: "8px", xs: "6px" },
          padding: { xl: '40px 30px 30px 30px', xs: '35px 25px 25px 25px' },
          font: "normal normal medium 16px/19px Roboto",
          "&::after": {
            content: `"${t("common.Selected Person")}"`,
            position: "absolute",
            top: "-13px",
            left: "30px",
            backgroundColor: "#F5F6F6",
            padding: "0 5px",
            whiteSpace: "nowrap",
            color: "rgba(71, 75, 79, 0.8)",
            fontSize: "16px",
            fontWeight: 500,
            fontFamily: "Roboto",
          },
          "&:hover": {
            boxShadow:
              "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          },
        }}
        id="assigning-person-page"
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={{ height: { xl: "68px", xs: "54px" } }}>
              <Grid item xs={6} sx={{ pr: { xl: "70px", xs: "50px" } }}>
                <DepartmentListRight departments={props.departments} handleDepartmentRight={props.handleDepartmentRight}
                  rightDepartmentValue={props.rightDepartmentValue}
                 />
              </Grid>
              <Grid item xs={6} sx={{ pl: { xl: "30px", xs: "20px" }, display: "flex", justifyContent: "end" }}>
              <SearchBarRight placeholder={t("common.Search by Name and ID")}
                clearSearch={() => props.handleSetNameAndIdRight("") }
                onSearch={() => console.log()} name={"search"}
                resetError={() => setSerachError("")} helperText={searchError}
                handleChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                  props.handleSetNameAndIdRight(event.target.value);
                }}
                value={props.nameAndIdRight}
              />
              </Grid>
            </Grid>
          </Grid>
            <Grid item xs={12}>
            <Box height={"36px"} width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Popover placement="right" title={"Selected Persons " + props.rightChecked.length}
                    open={anchorEl} onOpenChange={(e) => handleHoverChange(e)}
                    content={contentUI(props.rightChecked)}
                  >
                  <Typography sx={{
                    cursor: "default",
                    color: "#36C96D"
                  }}>
                    {(props.rightChecked && props.rightChecked.length > 0) ? (
                      (props.rightChecked.length <= 1) ?
                        (t("common.Selected - {count}  Person", {count:props.rightChecked.length})) :
                        (t("common.Selected - {count}  Persons", {count:props.rightChecked.length}))
                    )
                    :
                    (
                      t("common.Selected - 0 Person")
                    )}
                  </Typography>
                </Popover>
            </Box>
            <Box  sx={{ width: '100%', height: 350, backgroundColor: "#F5F6F6 !important" }}>
              <List dense component="div" role="list">
              <Box sx={{...customBoxStyle}}>
                  <ListItemButton sx={{ maxWidth: (props.items && props.items.length > 5) ? "97.5%": "100%",
                                  "&.MuiListItemButton-root:hover": { bgcolor: "transparent" },
                                  backgroundColor: "#F0F1F4 !important" }}>
                <ListItemIcon>
                  {(props.items && props.items.length > 0) ? (
                    <Checkbox
                      onClick={props.handleToggleAllRight(props.items)}
                      checked={ props.numberOfCheckedRight(props.items) === props.items.length && props.items.length !== 0}
                      indeterminate={
                        props.numberOfCheckedRight(props.items) !== props.items.length && props.numberOfCheckedRight(props.items) !== 0
                      }
                      disabled={props.items.length === 0}
                      inputProps={{
                        'aria-label': 'all items selected',
                      }}
                  />
                  ) :
                  (
                    <Checkbox checked={false} inputProps={{'aria-label': 'all items selected', }}/>
                  ) }

                </ListItemIcon>
                <ListItemText id="person-name-right" primary={t("common.Person Name")} sx={{width: "160px", color: "#212121", textAlign: 'left'}}/>
                <ListItemText id="person-id-right" primary={t("common.Person ID")} sx={{width: "160px", color: "#212121", textAlign: 'left'}}/>
                <ListItemText id="department-name-right" primary={t("common.Department Name")} sx={{width: "150px", color: "#212121", textAlign: 'left'}}/>
                </ListItemButton>
                </Box>
                {props.departmentRightSearch.length === 0 ?  (
                  <NoRecordFound text={t("common.Select Department to view persons")} />
                ) : (
                  (props.items && props.items.length > 0) ? (
                    <Box sx={{ width: '100%', height: 290, backgroundColor: "#F5F6F6 !important", overflow: 'auto' }}>
                    {props.items && props.items.map((obj, index) => {

                      const labelId = `transfer-list-item-${obj.id}-label`;
                      return (
                        <ListItemButton key={index} role="listitem" onClick={props.handleToggleRight(obj)}>
                        <ListItemIcon>
                          <Checkbox checked={props.rightChecked.indexOf(obj) !== -1}
                            tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId,}}/>
                        </ListItemIcon>
                        <ListItemText title={`${obj.fullName}`} id={labelId} primary={`${checkStringLength(obj.fullName, stringLimitLength)}`} sx={{width: "160px", textAlign: 'left' }}/>
                        <ListItemText title={`${obj.employeeCode}`} id={labelId} primary={`${checkStringLength(obj.employeeCode, stringLimitLength)}`} sx={{width: "160px", textAlign: 'left' }}/>
                        <ListItemText title={`${obj.department_name}`} id={labelId} primary={`${checkStringLength(obj.department_name, stringLimitLength)}`} sx={{width: "150px", textAlign: 'left'}}/>
                        </ListItemButton>
                      );

                    })}
                  </Box>
                  ) :
                  (
                    <Box sx={{ textAlign: "center", paddingTop: 15 }}>
                        {t("organ.Employee no rows")}
                      </Box>
                  )
                )}

              </List>
              </Box>

            </Grid>
        </Grid>
    </Box>
  );
};

export default TransferListRight;
