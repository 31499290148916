import React, { ReactNode, useContext } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import {useTranslation} from "react-i18next";
import {isEligibleAction} from "@/utils/permissions/FilterUnauthed"
import { DataGridContext, DataGridDispatchContext } from "./DataGridContext";
import RefreshAction from "../actions/Refresh";
import FilterAction from "../actions/Filter";
import { Cancel } from '@mui/icons-material';
import { ReactComponent as Search } from '@/assets/icons/SearchIcon.svg';

type ActionProps = {
  key: string;
  icon: SvgIconComponent;
  helpTxt?: string;
  authedAction?: string;
  callback: () => Promise<void>;
}
type Breadcrumb = {
  breadcrumbName: string;
  path: string
}
type Upload = {
  setUpload?: any;
}

export type ToolBarProps = {
  title: string;
  authedAction?: string;
  total?: number;
  info?: boolean;
  upload?: Upload;
  refresh?: boolean | ReactNode;

  search?: boolean;
  searchPlaceholder?: string;

  filter?: boolean | ReactNode;
  actions?: ActionProps[];
  breadcrumbs?: Breadcrumb[];

  children?: ReactNode;
  belowChildren?: ReactNode;
}

const CustomToolBar: React.FC<ToolBarProps> = (props) => {
  const {t} = useTranslation();
  let showTotal = false;
  if (props.total) {
    showTotal = true;
  }
  const navigate = useNavigate();

  const dispatch = useContext(DataGridDispatchContext)
  const {searchKeyword} = useContext(DataGridContext)

  const handleRefresh = () => {
    dispatch({type: "refreshed"})
  }

  const handleSearch = (searchKeyword: string) => {
    dispatch({type: "searched", searchKeyword})
  }

  return (
    <>
      <Grid className={"toolbar-container"}>
        <Box sx={{ display: 'flex' }}>
          <Breadcrumbs separator="" sx={{ height: "32px", p: '8px 0' }}>
            {
              props.breadcrumbs?.map((item, index) => {
                const last = (index + 1) === props.breadcrumbs?.length;
                return (
                  last
                    ? <Typography key={index}>{item.breadcrumbName}</Typography>
                    :  <IconButton id='BackIconButton' key={index} onClick={() => navigate(item.path)}>
                         <Tooltip title={t('common.Back')} placement="bottom">
                          <div><ArrowBackIosNewIcon sx={{ display: "flex" }} fontSize="small" /></div></Tooltip>
                      </IconButton>
                )
              })
            }
          </Breadcrumbs>
          {showTotal && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                pt: "5px",
                pl:"8px",
                m: 0,
                borderRadius: 1,
              }}
            >
              <Typography component='div' style={{ color: '#36C96D', fontSize: "16px" }}>
                {" - "}{props?.total}{t("common.records")}
              </Typography>
            </Box>
          )}

        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Grid
            container={true}
            columnSpacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            {props.search &&
            <Grid item={true}>
              <Grid
                container={true}
                columnSpacing={1}
                sx={{
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "400px" }}>
                <TextField
                  size='small'
                  fullWidth
                  placeholder={props.searchPlaceholder ? props.searchPlaceholder : ''}
                  value={searchKeyword}
                  onChange={(event) => {
                    handleSearch(event.target.value)
                    handleRefresh()
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {searchKeyword ? (
                          <Box id='close-icon' style={{ cursor: "pointer" }}>
                            <Tooltip title={t("common.Clear")}>
                              <Cancel
                                style={{ color: "rgba(71, 75, 79, 0.5)", marginTop: "8px" }}
                                onClick={() => { if(searchKeyword) { handleSearch(""); handleRefresh() } }}
                              />
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box id='search-icon' style={{ cursor: "pointer" }}>
                            <Tooltip title={t("common.Search")}>
                              <Search style={{ cursor: "pointer", marginTop: "6px" }} onClick={() => { handleRefresh() }} />
                            </Tooltip>
                          </Box>
                        )}
                      </>
                    ),
                  }}
                   />
                </Box>
              </Grid>
            </Grid>}
            {props.filter && <Grid item={true}>
              {typeof props.filter === "boolean" ? <FilterAction /> : props.filter}
            </Grid>}
            {/* TODO(lowell): 将输入型action和按钮型action区分开来, 后续可以考虑优化 */}
            {props.children}
            {props.upload && isEligibleAction(props?.authedAction) && <Grid item={true}>
              <Tooltip title={t("att.report.Upload")}>
                <IconButton id='UploadIconButton' sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }} onClick={()=>{
                  props.upload?.setUpload(true)
                }}>
                  <CloudUploadIcon />
                </IconButton>
              </Tooltip>
            </Grid>}
            {props.refresh && <Grid item={true}>
              {typeof props.refresh === "boolean" ? <RefreshAction /> : props.refresh}
            </Grid>}
            {props.actions?.map((action, index) => {
              if (!isEligibleAction(action?.authedAction)){ // 无权限则不创建新增对应按钮
                return (<></>)
              }
              return (
                <Grid key={action.key} item={true} onClick={action.callback}>
                  <Tooltip title={action.helpTxt || ""}>
                    <Box
                      id={`${action.key}Button`}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.primary.main,
                        padding: "8px 8px",
                        borderRadius: "6px",
                        display: "flex",
                        cursor: "pointer",
                      })}
                    >
                      <action.icon sx={{ color: "white" }} />
                    </Box>
                  </Tooltip>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Grid>
      <Box sx={{width:"100%", my:"10px"}}>
      {props.belowChildren}
      </Box>
    </>
  )
}
CustomToolBar.defaultProps = {
  search: false,
  refresh: true,
  filter: false,
}
export default CustomToolBar
