import { LocationEndpoints } from '@/services/location/endpoints';
import Client from '..';


const list = async (params: Record<string, any>) => {
  const response = await Client().get(LocationEndpoints.ZONE_URI, {params});
  return response.data;
}

const ZoneService = {
  list,
}

export default ZoneService;