import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import {ArrowForwardIosSharp} from "@mui/icons-material";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {object, TypeOf, z} from "zod";
import {TimePicker} from "@mui/x-date-pickers";
import Util from "../../../../utils/Util";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp';
import { styled } from '@mui/material/styles';
import GenericService from "@/services/GenericService";
import {ATTEndpoints} from "@/services/att/endpoints";
import {useTranslation} from "react-i18next";
import SnackbarUtil from "@/utils/SnackbarUtil";


const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:"white",
    border: "solid #36C96D 1px",
    color: "gray",
    fontSize: 14,
    lineHeight: 2,
    whiteSpace:true,
    "& .MuiTooltip-arrow": {
        top: "-5px !important",
        "&::before": {
          border: "solid #36C96D 1px",
          backgroundColor:"white",
        },},
  },
});

const PairingRuleText_12 = 'If calculation rule is First and Last and clocking status is In/Out, then system will' +
  ' gain the first In clocking record and last Out clocking record to calculate the working hours';
const patternSchema = object({
  max_hrs: z.string(),
  pairing: z.string(),
  required_record: z.string(),
  day_change: z.string(),
  break_start: z.string(),
  break_end: z.string(),
  break_calculate_type: z.string(),
})

type PatternInput = TypeOf<typeof patternSchema>;

export type PatternSettingFields = {
  max_hrs: string,
  pairing: string;
  required_record: string;
  day_change: string;
  break_start: string;
  break_end: string;
  break_calculate_type: string;
}
type PatternSettingProps = {
  pattern: PatternSettingFields;
  view_data?: any;
  option?:any;
}

const PatternSetting = (props: PatternSettingProps, ref: any) => {
  const defaultPattern = props.pattern;
  const param_id = props.view_data;
  const {t} = useTranslation();
  const [PairingRuleText, SetPairingRuleText] = React.useState('');
  const [MaxHoursText, setMaxHoursText] = React.useState<string>("");
  const [MaxHoursError, setMaxHoursError] = React.useState<boolean>(false);
  const [BreakStartValue, setBreakStartValue] = React.useState<any>(defaultPattern.break_start);
  const [BreakEndValue, setBreakEndValue] = React.useState<any>(defaultPattern.break_end);
  const [DayChangeValue, setDayChangeValue] = React.useState<any>(defaultPattern.day_change);
  const patternSettingForm = useForm<PatternInput>({
    defaultValues: defaultPattern
  })

  const handleDayTimeText = (Form:any)=>{
    const pairing = Form.getValues("pairing");
    const required_record = Form.getValues("required_record");
     if (pairing == "first_last"){
       if (required_record == "any"){SetPairingRuleText(t("att.timesheet.PairingRuleText_11"))}
       else{SetPairingRuleText(t("att.timesheet.PairingRuleText_12"))}
     }
     else{
       if (required_record == "any"){SetPairingRuleText(t("att.timesheet.PairingRuleText_21"))}
       else{SetPairingRuleText(t("att.timesheet.PairingRuleText_22"))}
     }
  };

  useEffect(() => {
    handleDayTimeText(patternSettingForm)
    if (props.option == "working"){
      patternSettingForm.setValue("max_hrs", "24")
      setMaxHoursError(false);
      setMaxHoursText("")
    }},
    [patternSettingForm.getValues("pairing"), patternSettingForm.getValues("required_record"), props.option]);

  useEffect(() => {
    if (param_id){
       GenericService.retrieve(ATTEndpoints.ATT_TIMESHEET_URI, `${param_id}`).then((res) => {
         patternSettingForm.setValue("max_hrs", res.data.pattern?.max_hrs.toString());
         patternSettingForm.setValue("pairing", res.data.pattern?.pairing);
         patternSettingForm.setValue("required_record", res.data.pattern?.required_record);
         setDayChangeValue(res.data.pattern?.day_change);
         setBreakStartValue(res.data.pattern?.break_start);
         setBreakEndValue(res.data.pattern?.break_end);
         patternSettingForm.setValue("break_calculate_type", res.data.pattern?.break_calculate_type);
      })
     }
  })
  useImperativeHandle(ref, () => ({
    pattern: () => {
      patternSettingForm.setValue("day_change", (typeof DayChangeValue === "string") ? DayChangeValue : Util.datetime2string(DayChangeValue, 'HH:mm'))
      patternSettingForm.setValue("break_start", (typeof BreakStartValue === "string") ? BreakStartValue : Util.datetime2string(BreakStartValue, 'HH:mm'))
      patternSettingForm.setValue("break_end", (typeof BreakEndValue === "string") ? BreakEndValue : Util.datetime2string(BreakEndValue, 'HH:mm'))
      return patternSettingForm.getValues()
    }
  }))
  return (
    <Accordion disableGutters={true} sx={{
      boxShadow: "none",
      border: "none",
    }}>
      <AccordionSummary expandIcon={<ArrowForwardIosSharp sx={{fontSize: '0.9rem'}}/>} sx={(theme) => ({
        flexDirection: "row-reverse",
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
          marginLeft: theme.spacing(1),
        },
      })}>
        <Typography >{t("att.timesheet.Rule Setting")}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{
        borderTop: '1px solid rgba(0, 0, 0, .125)',
      }}>
        <FormProvider {...patternSettingForm}>
        <Grid container={true} rowSpacing={2} component={"form"}>
          { props.option === "clocking" &&
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} alignItems={"center"}>
                <Grid sx={{width: "8px", height: "8px", borderRadius: "4px", backgroundColor: "#36C96D"}}/>
                <Typography sx={{ fontSize: "14px"}}>{t("att.timesheet.Max Work Hours")}</Typography>
                <Controller
                  control={patternSettingForm.control}
                  name={"max_hrs"}
                  render={({field}) => {
                    return (
                      <TextField
                        {...field}
                        sx={{ '& .MuiOutlinedInput-input': {fontSize: "14px"} }}
                        size={"small"}
                        disabled={!!param_id}
                        error={MaxHoursError}
                        helperText={MaxHoursText}
                        onChange={(newValue) => {
                          const value = newValue.target.value;
                          patternSettingForm.setValue("max_hrs", value)
                          if (props.option == "clocking"){
                            if (Number(value) < 1 || Number(value) > 24 || String (value).indexOf (".") > -1
                              || isNaN(Number(value))){
                              setMaxHoursError(true);
                              setMaxHoursText(t("att.timesheet.Max Work Hours is positive integer and in the range 1-24"))
                              }
                              else{
                                setMaxHoursError(false);
                                setMaxHoursText("")
                              }
                            }
                            else {
                              setMaxHoursError(false);
                              setMaxHoursText("")
                            }
                        }}
                        InputProps={{
                          inputMode: "numeric",
                          endAdornment: <InputAdornment position={"end"}>
                            <Typography sx={{ fontSize: "14px" }}>{t("att.timesheet.Hours")}</Typography>
                          </InputAdornment>
                        }}
                      />
                    )
                  }}
                />
                <CustomWidthTooltip title={t("att.timesheet.MaxWorkHoursText")} placement="right-end" arrow>
                  <IconButton id='HelpIconButton'>
                    <QuestionMarkSharpIcon sx={{fontSize: 19, color: '#36C96D'}}/>
                  </IconButton>
                </CustomWidthTooltip>
              </Stack>
            </Grid>
          }
          <Grid item xs={12}>
            <Stack spacing={1} direction={"row"} alignItems={"center"}>
              <Grid sx={{width: "8px", height: "8px", borderRadius: "4px", backgroundColor: "#36C96D"}}/>
              {/*<ThemeProvider theme={MuiTheme}>*/}
                {/*<InputLabel>*</InputLabel>*/}
              {/*</ThemeProvider>*/}
              <Typography sx={{ fontSize: "14px" }}>{t("att.timesheet.Pairing Rule")}</Typography>
              <Controller
                control={patternSettingForm.control}
                name={"pairing"}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      sx={{ fontSize: "14px" }}
                      size={"small"}
                      disabled={!!param_id}
                      id={"dropdown-timesheet-create-pairingRule"}
                      defaultValue={"first_last"}
                      onChange={(newValue) => {
                        const value = newValue.target.value;
                        patternSettingForm.setValue("pairing", value)
                        handleDayTimeText(patternSettingForm)
                      }}
                    >
                      <MenuItem sx={{ fontSize: "14px"}}  value={"first_last"}>{t("att.timesheet.First and Last")}</MenuItem>
                      <MenuItem sx={{ fontSize: "14px"}} value={"odd_even"}>{t("att.timesheet.Odd Even")}</MenuItem>
                    </Select>
                  )
                }}
              />
              <Controller
                control={patternSettingForm.control}
                name={"required_record"}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      size={"small"}
                      disabled={!!param_id}
                      defaultValue={"any"}
                      sx={{display: 'none', fontSize: "14px" }} // current version is useless
                      onChange={(newValue) => {
                        const value = newValue.target.value;
                        patternSettingForm.setValue("required_record", value)
                        handleDayTimeText(patternSettingForm)
                      }}
                    >
                      <MenuItem  sx={{ fontSize: "14px" }} value={"any"}>{t("att.timesheet.Any")}</MenuItem>
                      {/* <MenuItem value={"in_out"}>IN/OUT</MenuItem> */}
                    </Select>
                  )
                }}
              />
              <CustomWidthTooltip  title={PairingRuleText} placement="right-end" arrow>
                <IconButton id='HelpIconButton'>
                  <QuestionMarkSharpIcon sx={{ fontSize: 19, color: '#36C96D'}}/>
                </IconButton>
              </CustomWidthTooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"row"} alignItems={"center"}>
              <Grid sx={{width: "8px", height: "8px", borderRadius: "4px", backgroundColor: "#36C96D"}}/>
              {/*<ThemeProvider theme={MuiTheme}>*/}
                {/*<InputLabel>*</InputLabel>*/}
              {/*</ThemeProvider>*/}
              <Typography sx={{ fontSize: "14px" }}>{t("att.timesheet.Day Change Time")}</Typography>
              <Controller
                control={patternSettingForm.control}
                name={"day_change"}
                render={(controlProps) => {
                  return (
                    <TimePicker
                    sx={{
                        '& .MuiInputBase-input': { fontSize: '14px' },
                      }}
                      {...controlProps.field}
                      ampm={false}
                      value={(typeof DayChangeValue === "string") ? Util.time2datetime(DayChangeValue) : DayChangeValue}
                      disabled={!!param_id}
                      onChange={(newValue) => {
                        setDayChangeValue(newValue);
                      }}
                    />
                  )
                }}
              />
              <CustomWidthTooltip  title={t("att.timesheet.DayChangeTimeText")} placement="right-end" arrow>
                <IconButton id='HelpIconButton'>
                  <QuestionMarkSharpIcon sx={{ fontSize: 19, color: '#36C96D'}}/>
                </IconButton>
              </CustomWidthTooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"row"} alignItems={"center"}>
              <Grid sx={{width: "8px", height: "8px", borderRadius: "4px", backgroundColor: "#36C96D"}}/>
              <Typography sx={{ fontSize: "14px" }}>{t("att.timesheet.Break Time From")}</Typography>
              <Controller
                control={patternSettingForm.control}
                name={"break_start"}
                render={(controlProps) => {
                  return (
                    <TimePicker
                      sx={{
                        '& .MuiInputBase-input': { fontSize: '14px' },
                      }}
                      {...controlProps.field}
                      ampm={false}
                      value={(typeof BreakStartValue === "string") ? Util.time2datetime(BreakStartValue) : BreakStartValue}
                      disabled={!!param_id}
                      onChange={(newValue) => {
                        if (newValue === null) {
                            SnackbarUtil.error(t("att.timesheet.Please Select Time"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          setBreakStartValue(Util.time2datetime("12:00"));
                          return;
                          }
                        else{
                        setBreakStartValue(newValue);
                        }
                      }}
                    />
                  )
                }}
              />
              <Typography sx={{ fontSize: "14px" }}>{t("att.timesheet.to")}</Typography>
              <Controller
                control={patternSettingForm.control}
                name={"break_end"}
                render={(controlProps) => {
                  return (
                    <TimePicker
                      sx={{
                        '& .MuiInputBase-input': { fontSize: '14px' },
                        '& .Mui-selected .MuiMultiSectionDigitalClockSection-item': { fontSize: '14px'}
                      }}
                      {...controlProps.field}
                      ampm={false}
                      value={(typeof BreakEndValue === "string") ? Util.time2datetime(BreakEndValue) : BreakEndValue}
                      disabled={!!param_id}
                      onChange={(newValue) => {
                        if (newValue === null) {
                            SnackbarUtil.error(t("att.timesheet.Please Select Time"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          setBreakEndValue(Util.time2datetime("12:30"));
                          return;
                          }
                        else{
                        setBreakEndValue(newValue);
                        }
                      }}
                    />
                  )
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"row"} alignItems={"center"}>
              <Grid sx={{width: "8px", height: "8px", borderRadius: "4px", backgroundColor: "#36C96D"}}/>
              <Typography sx={{ fontSize: "14px" }}>{t("att.timesheet.Break Time Calculation Type")}</Typography>
              <Controller
                control={patternSettingForm.control}
                name={"break_calculate_type"}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      sx={{ fontSize: "14px" }}
                      size={"small"}
                      id={"dropdown-timesheet-create-breakRule"}
                      disabled={!!param_id}
                      defaultValue={"auto_deducted"}
                    >
                      <MenuItem sx={{ fontSize: "14px" }} value={"auto_deducted"}>{t("att.timesheet.Automatically deducted")}</MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value={"punch_required"}>{t("att.timesheet.Punch Time is Required")}</MenuItem>
                    </Select>
                  )
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  )
};
export default forwardRef(PatternSetting)
