import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CompanyService from "@/services/auth/CompanyService";
const defaults = [
  {id: '8a81942f834d6e44018369502dc903bb', name: 'xxx', code: 'xx'},
  {id: '8a81942f834d6e44018368215a360371', name: 'kk5', code: 'kk5'},
  {id: '8a81942f834d6e44018363c6c18f02c5', name: 'sad', code: 'a-101'},
  {id: '8a81942f834d6e4401835f09ad380219', name: 'k333', code: 'k333'},
  {id: '8a81942f834d6e4401835a489d230105', name: 'kk3', code: 'kk3'},
  {id: '8a81942f834d6e44018358d56feb0081', name: 'Test for p', code: 'p-0212'},
  {id: '8a81942f834d6e44018358c2b00d007b', name: 'Test for k', code: 'T-0214'},
  {id: '8a81942f834d6e440183587c04920073', name: 'zzz1', code: 'zzz'},
  {id: '8a818af0833a357101834470117301af', name: 'test for c', code: 'B-1041'},
  {id: '8a818af0833a357101833ff2620400c3', name: 'xxx Company 0916', code: 'C-0916'},
  {id: '8a818af0833a357101833fb663e8008b', name: 'asdaw', code: 'qwdq'},
  {id: '8a818af0833a357101833f3ebf98006e', name: 'aavvvx', code: 'C-0915'},
  {id: '8a818af0833a357101833b58d1800033', name: 'XXX Company au', code: 'ZKTECO_aa'},
  {id: '8a818a948292285201829f1ce481000e', name: 'zzzz', code: 'zzzz'},
  {id: '8a8194748287ac3201828c9052ce0019', name: 'Test-1', code: 'test0002'},
  {id: '8a81945582357bdb0182381cb3910003', name: '20220726', code: '20220726'},
  {id: '8a81944c822e55be01823403fe7d006d', name: 'arvin test company', code: 'arvin_test_company'},
  {id: '8a81946a81859cd10181895b32fa0031', name: 'KS001', code: 'KS001'},
  {id: '8a8194cc81386eb301816f9bea140376', name: 'Immortal', code: 'Immortal'},
  {id: '8a8194cc81386eb301815baba54d01d9', name: 'test_D', code: 'D001'},
  {id: '8a81941a810801310181226ee98102ff', name: 'Arvin-04', code: 'arvin4'},
  {id: '8a818a677faa3eb5017ffe07839503b8', name: 'Arvin Tech 03', code: 'bd2ee888b4294bca87ae80247c812b73'},
  {id: '8a818a677faa3eb5017fb59be0d900fc', name: 'Arvin Tech02', code: '77ca70cf1c884340b2a450366b56ce20'},
  {id: '8a818a677faa3eb5017fb59b5f7500fa', name: 'Arvin Tech01', code: 'e8e8e1f4e3ed4773a4bce549ff73f73b'},
]
const initialState = {
  organs: [],
  organ: {}
}

export const getUserOrgans = createAsyncThunk('organ/byUser', async () => {
  const {data} = await CompanyService.companyList({current: 1, pageSize: 50});
  return data;
})

const organSlice = createSlice({
  name: 'organ',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserOrgans.fulfilled, (state, action) => {
      return {
        ...state,
        organs: action.payload.data
      }
    })
  }
});
export default organSlice.reducer
