import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DepartmentListRightProps } from "./Sheet.Types";

const DepartmentListRight: React.FC<DepartmentListRightProps> = (props: DepartmentListRightProps) => {
  const { t } = useTranslation();

  const ValidationTextField = {
    "& input::placeholder": {
      fontSize: "13px"
    },
  };
  const style = { width: '100%' } as const

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo-right"
      value={props.rightDepartmentValue.id.length > 1 ? props.rightDepartmentValue.name : null}
      sx={ style }
      renderInput={(params) =>
        <TextField {...params}
          placeholder={t("common.Select Department")} size='small'
          sx={{ ...ValidationTextField }}
          />
      }
      options={
        props.departments.length > 0
          ? props.departments.map(option => option.name)
          : []
      }
      onChange={(event, newValue) => {
        if(newValue !== null){
          props.handleDepartmentRight(newValue)
        } else {
          props.handleDepartmentRight("")
        }
      }}

    />
  );
};

export default DepartmentListRight;
