import React from "react";
import {Box, Breadcrumbs, Grid, Typography} from "@mui/material";
import SubMenuItem from "../../../components/menu/SubMenuItem";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WarehouseIcon from '@mui/icons-material/Warehouse';

const SubscriptionHome: React.FC = () => {
  const reports = [
    {name: "Subscription", title: "Subscription", icon: ShoppingCartIcon, path: "/sub/new/"},
    {name: "Subscription Order", title: "Subscription Order", icon: WarehouseIcon, path: "/sub/order/"},
  ]
  return (
    <Box>
      <Grid className={"toolbar-container"}>
        <Breadcrumbs sx={{height: "32px", p: '8px'}}>
          <Typography>Subscription</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container={true} rowSpacing={2} sx={{margin: "0"}}>
        {
          reports.map((item) => <SubMenuItem key={item.name} {...item} />)
        }
      </Grid>
    </Box>
  )
};
export default SubscriptionHome
