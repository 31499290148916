import Client from "@/services";
import {ATTEndpoints} from "../endpoints";

const presentSummary = async (category: string) => {
  const {data} = await Client().get(`${ATTEndpoints.ATT_PRESENT_SUMMARY_TODAY}${category}/`);
  // console.log("presentSummaryToday >>>", data)
  return data;
}

const DashboardService = {
  presentSummary
}
export default DashboardService