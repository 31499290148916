
const Prefix = {
  HEADER_HELP: "header_help",
  SIDEBAR_ICON: "siderbar_icon_",
  SIDEBAR_TEXT: "siderbar_icon_",
  SIDEBAR_SHRINK: "siderbar_shrink",
  MENU_ICON: "menu_icon_",
  MENU_TEXT: "menu_text_",
  ARROW_BACK: "arrow_back"
}
export default Prefix



