import React from 'react'
import { ReactComponent as NoResultFoundPIC } from '../../assets/icons/noResultFound.svg';
import { Box } from '@mui/material';

type Props = {
    messages: any;
}
const NoResultFound: React.FC<Props> = (props) => {
  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor:"#FFF"
    }}>
        {props.messages !=""&& <NoResultFoundPIC/>}
        <Box sx={{ mt: 1, 
         font:"normal normal normal 16px/19px Roboto",
         letterSpacing:"0.32px",
         color: 'rgba(71, 75, 79, 0.8)',
        textAlign:'center',
        }}>{props.messages}
        </Box>
    </Box>
  )
}
export default NoResultFound;