import React, {useRef, useState} from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";
import Util from "@/utils/Util";
import FilterAction from "@/components/actions/Filter";
import RefreshAction from "@/components/actions/Refresh";
import SnackbarUtil from '@/utils/SnackbarUtil';
import {ATTEndpoints} from "../../../../../services/att/endpoints";
import WebURL from "../../../../../urls";
import CustomDataGrid, { DataGridRef } from '@/components/data-grid/CustomDataGrid';
import { Popover } from "antd";

const [firstDate, lastDate] = Util.getCurrentMonthOfStartAndEndDayObj()
const [firstDay, lastDay] = Util.getCurrentMonthOfStartAndEndDay()

const TimecardTimes: React.FC = () => {
  const {t} = useTranslation();
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const dataGridRef = useRef<DataGridRef>();

  const [filterParams, setFilterParams] = useState<Record<any, any>>({
    start_time: firstDay,
    end_time: lastDay,
  });

  const handleShowFilter = () => {
    setFilterShow(!filterShow)
  }

  function isValidDate(date: any) {
    return date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 2000
  }

  const filterFormValidate = object({
    keyword: z.string().optional(),
    start_time: z.date().nullable(),
    end_time: z.date().nullable(),
  })
  type FilterForm = TypeOf<typeof filterFormValidate>;
  const filterForm = useForm<FilterForm>({
    defaultValues: {keyword: '', start_time: firstDate, end_time: lastDate}
  });
  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = filterForm;

  React.useEffect(() => {
    const refreshPage = () => {
      filterForm.reset()
      setFilterParams({})
      dataGridRef.current?.refresh(true);
    };
    refreshPage();
  }, [!filterShow]);

  const FilterComponent = () => {
    if (!filterShow) {
      return (
        <></>
      )
    }
    const onSubmit = async (formData: any) => {
      formData.start_time = Util.convertDateToString(formData.start_time)
      formData.end_time = Util.convertDateToString(formData.end_time)
      setFilterParams(formData)
      if (formData.start_time || formData.end_time || formData.keyword) {
        dataGridRef.current?.refresh(true);
      }
      else {
        SnackbarUtil.error(t("dms.Enter at least 1 character please"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      }
    }

    return (
      <>
      <Grid container={true} component={'form'} onSubmit={handleSubmit(onSubmit)}
        sx={{borderRadius: '8px',
          padding: { xl: '30px', xs: "20px" },
          width: '100%',
          border: '1px solid #DDDDDD',
          backgroundColor:"#FFF"
        }}
          >
          <Grid item xs={12} md={3.4}>
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'} sx={{p:{md:"0 20px 0 0", xs:"20px 0 0 0"}}}>
              <Controller
                control={control}
                name="keyword"
                render={({field}) => (
                  <TextField
                    fullWidth
                    placeholder={t("att.timecard.Search by Person ID/Name / Department Name")}
                    value={field.value}
                    size="small"
                    error={!!errors.keyword}
                    helperText={errors.keyword?.message || ""}
                    {...register("keyword")}
                  />
                )}
              />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={1.2}>
            <Box display={'flex'}>
              <Typography sx={{lineHeight: '40px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: "ellipsis"}}>
                {t("common.Clocking Date")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.4}>
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'} sx={{p:{md:"0 20px 0 0", xs:"20px 0 0 0"}}}>
              <Controller
                control={control}
                name="start_time"
                render={({ field }) => (
                  <DatePicker
                    label={t("att.timecard.From")}
                    onChange={(value) => {
                      const tempEndTime = getValues('end_time')
                      if (value && tempEndTime) {
                        const newValue = Util.convertDateToString(value)
                        const newTempEndTime = Util.convertDateToString(tempEndTime)
                        if (newValue > newTempEndTime) {
                          SnackbarUtil.error(t("common.Invalid input."), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          // return;
                        }
                      }
                      field.onChange(value)
                    }}
                    value={field.value}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth:true
                      }
                    }}
                  />
                )}
              />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.4}>
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'} sx={{p:{md:"0 20px 0 0", xs:"20px 0 0 0"}}}>
              <Controller
                control={control}
                name="end_time"
                render={({ field }) => (
                  <DatePicker
                    label={t("att.timecard.To")}
                    onChange={(value) => {
                      const tempStartTime = getValues('start_time')
                      if (value && tempStartTime) {
                        const newValue = Util.convertDateToString(value)
                        const newTempStartTime = Util.convertDateToString(tempStartTime)
                        if (newValue < newTempStartTime) {
                          SnackbarUtil.error(t("common.Invalid input."), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          return;
                        }
                      }
                      field.onChange(value)
                    }}
                    value={field.value}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth:true
                      }
                    }}
                  />
                )}
              />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={0.2}>
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'} sx={{padding: "10px"}}>  </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.4}
            sx={{
              mt: { md: "0px", xs: "20px" },
              display: "flex",
              justifyContent: { md: "flex-end", xs: "center" },
            }}
            >
              <Box display={'flex'}>
                <Button
                  id='SearchButton'
                  variant={"contained"}
                  type='submit'
                  sx={{
                    marginRight: "20px",
                    width: { xl: '100px', xs: "100px" },
                    height: { xl: '40px', xs: "35px" },
                    }}
                >
                {t("common.Search")}
              </Button>
              <Button
                  sx={{
                  width: { xl: '100px', xs: "100px" },
                  height: { xl: '40px', xs: "40px" },
                }}
                id='ResetButton'
                variant={"outlined"}
                onClick={() => {
                  filterForm.reset()
                  setFilterParams({})
                  dataGridRef.current?.refresh(true);
                }}
                >
                {t("common.Reset")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }

  const ShowTime = (params: GridRenderCellParams) => {

    const [anchorEl, setAnchorEl] = React.useState(false);
    const noOfTimes = params.value
    const sliceTime = noOfTimes.reduce((newArray: Array<any>, value: any, index:any) => {
      if (index % 2 === 0) {
        if(noOfTimes[index + 1] !== undefined) {
          newArray.push([value, noOfTimes[index + 1]]);
        } else {
          newArray.push([value]);
        }
      }
      return newArray;
    }, []);

    const contentUI = (item:any) => (
      <Box maxHeight={"300px"} maxWidth={"180px"} mr={-2} overflow={"auto"}>
        {
          item.map((obj: any, index: number) => {
            return (
              <Box
                key={index}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  ":hover": {
                    backgroundColor: '#36c96d1f',
                    borderRadius: "6px"
                  },
                  cursor: 'pointer',
                  padding: "2px"
                }}
              >
                <Typography pl={"5px"}>
                  {obj[1] !== undefined ? (`${obj[0]}, ${obj[1]}`) :
                   (`${obj[0]}`)}

                </Typography>
              </Box>
            );
          })
        }
      </Box>
    );

    const handleHoverChange = (open:  React.MouseEvent<HTMLElement, MouseEvent> | boolean, data: any) => {
      if (data.length > 0) {
        if (open)
          setAnchorEl(true)
        else
          setAnchorEl(false)
      } else {
        setAnchorEl(false)
      }
    };

    if(noOfTimes.length > 2) {
      const newTimes: any = []
      sliceTime?.forEach((data: any) => {
        if(data.length > 1) {
          if(data[1] !== undefined)
          {
            newTimes.push(<div style={{ padding: "5px 0px 5px 0px"}}>{data[0]}{', '}{data[1]} </div>)
          } else {
            newTimes.push(<div style={{ padding: "5px 0px 5px 0px"}}>{data[0]}</div>)
          }
        } else {
          newTimes.push(<div style={{ padding: "5px 0px 5px 0px"}}>{data[0]}</div>)
        }
      })

      let newTimeLst = []

      if(newTimes.length) {
        newTimeLst = newTimes.slice(0, 5)
      }

      return (
        <>
          <Box sx={{ float: "left", width: "150px"}}>

            {newTimeLst.map((val: any, idx: number) => (
              <div key={idx}>{val}</div>
            ))}

            {newTimes.length > 5 ? (
            <Popover
              placement="right"
              title={t("att.report.Time")}
              open={anchorEl}
              onOpenChange={(e) => handleHoverChange(e, newTimes)}
              content={contentUI(sliceTime)}
              >
                <Typography sx={{
                  cursor: "default",
                  color: "#36C96D"
                }}>
                  {"..."}
                </Typography>
              </Popover>
            ) : ('')}

          </Box>
        </>
      )
    } else {

      if(noOfTimes.length > 0) {
        let newTime  = ''
        noOfTimes.map((val: any, idx: number) =>{
          if(idx > 0) {
            newTime += ", " + val
          } else {
            newTime = val
          }
        })
        return (
          <>
            <Box sx={{ float: "left", width: "150px"}}>
              {newTime}
            </Box>
          </>
        )

      } else {

        return (
          <>
            <Box sx={{ float: "left", width: "150px"}}>
              {noOfTimes.map((val: any, idx: number) => (
                <div style={{ padding: "5px 0px 5px 0px"}}>{val}</div>
              ))}
            </Box>
          </>
        )

      }
    }
  }

  const gridColumn: GridColDef[] = [
    {field: 'employee_code', headerName: t("common.Person ID"), type: "string", flex: 0.1, width: 200},
    {field: 'employee_name', headerName: t("common.Person Name"), flex: 0.1, width: 200},
    {field: 'department_name', headerName: t("common.Department"), flex: 0.1, width: 200},
    { field: 'punch_date', headerName: t("att.report.Date"), type: "date", flex: 0.1, width: 100},
    { field: 'total_time', headerName: t("att.report.No. of Punch"), type: "string", flex: 0.1, width: 50},
    { field: 'punch_times', headerName: t("att.report.Time"),headerAlign: "left", flex: 0.1, width: 400, renderCell: ShowTime },
  ]

  const handleRefresh = () => {
    setFilterParams({})
    filterForm.reset()
    setFilterShow(false)
  }

  return (
    <>
      <CustomDataGrid
        ref={dataGridRef}
        uri={ATTEndpoints.ATT_TIMECARD_TIMES_URI}
        requestParams={filterParams}
        columns={gridColumn}
        checkboxSelection={false}
        getRowHeight={() => 'auto'}
        noResultFound={t("att.timecard.No timecard found")}
        getRowId={(row) => {return Math.random()}}
        toolbar={{
          title: t("att.timecard.Timecard"),
          search: false,
          searchPlaceholder: t("att.report.Search by Person, TimeSheet"),
          filter: <FilterAction handleShowFilter={handleShowFilter} />,
          refresh: <RefreshAction handleRefresh={handleRefresh} />,
          breadcrumbs: [
            {breadcrumbName: "", path: WebURL.ATT_REPORT_HOME},
            {breadcrumbName: t("att.timecard.Timecard"), path: WebURL.ATT_TIMECARD_TIMECARD}
          ],
          // children: <ExtraAction />  // Tip content needs to be corrected
          belowChildren: <FilterComponent/>
        }}
      />
    </>
  )
}
export default TimecardTimes

