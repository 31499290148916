import Client from "..";
import {AuthEndpoints} from "./endpoints";

const list = async (params: Record<string, any>) => {
  const response = await Client().get(AuthEndpoints.AUTH_PERMISSION_URI, {params});
  return response.data;
}

const PermissionService = {
  list,
}

export default PermissionService;