import { Observer } from "./observer";
import { CustomAxiosError, CustomAxiosRequestConfig, CustomAxiosResponse } from "./interface";


export interface InterceptorSubject {
  attach(observer: Observer): void;
  detach(observerId: string): void;
  notifyRequest(config: CustomAxiosRequestConfig): any;
  notifyResponse(response: CustomAxiosResponse): any;
  notifyError(err: CustomAxiosError): any;
}

export class AxiosInterceptorSubject implements InterceptorSubject {
  private observers: Map<string, Observer> = new Map();

  attach(observer: Observer) {
    const isExist = this.observers.has(observer.id);
    if (isExist) {
      return console.warn("Subject: Observer has been attached already.");
    }
    this.observers.set(observer.id, observer);
  }

  detach(observerId: string) {
    const isExist = this.observers.has(observerId);
    if (!isExist) {
      return console.warn('Subject: Nonexistent observer.');
    }
    this.observers.delete(observerId);
  }

  notifyRequest(config: CustomAxiosRequestConfig) {
    const observerIds = config?.observerIds;
    if (observerIds) {
      observerIds.forEach(id => {
        const observer = this.observers.get(id);
        if (observer) {
          config = observer.update(config);
        }
      })
      return config;
    }
    for (const [ id, observer ] of this.observers) {
      if (observer) {
        config = observer.update(config);
      }
    }
    return config;
  }

  notifyResponse(response: CustomAxiosResponse) {
    const observerIds = response.config?.observerIds;
    if (observerIds) {
      observerIds.forEach(id => {
        const observer = this.observers.get(id);
        if (observer) {
          response = observer.doResponse(response);
        }
      })
      return response;
    }
    for (const [id, observer] of this.observers) {
      if (observer) {
        response = observer.doResponse(response);
      }
    }
    return response;
  }

  notifyError(err: CustomAxiosError) {
    const observerIds = err.config?.observerIds;
    if (observerIds) {
      observerIds.forEach(id => {
        const observer = this.observers.get(id);
        if (observer) {
          err = observer.doError(err);
        }
      })
      return err;
    }
    for (const [id, observer] of this.observers) {
      if (observer) {
        err = observer.doError(err);
      }
    }
    return err;
  }
}