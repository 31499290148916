import React, {Dispatch, SetStateAction, useState} from "react";
import {Box} from "@mui/system";
import {Checkbox, Divider, FormControlLabel, Grid, Paper} from "@mui/material";
import {ReactComponent as DeviceBound} from "@/assets/images/DeviceBound.svg";
import {ReactComponent as DeviceNetwork} from "@/assets/images/DeviceNetwork.svg";
import {ReactComponent as DevicePower} from "@/assets/images/DevicePower.svg";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";


type DeviceGuideProps = {
  setStep: Dispatch<SetStateAction<string>>
}

const StepDesc: React.FC<{ title: string, description: string, picture: React.ReactNode }> = (props) => {
  return (
    <Grid item xs={3} >

      <Box fontSize={'18px'} height={"64px"} sx={{ fontWeight: "bold" }}>
        {props.title}
      </Box>
      <Box fontSize={'16px'} color={'gray'} sx={{ minHeight:"120px" }}>
        {props.description}
      </Box>
      <Box textAlign={'center'}>
        {props.picture}
      </Box>
    </Grid>
  )
}

const DeviceConnGuide: React.FC<DeviceGuideProps> = (props) => {
  const {t} = useTranslation()
  const [isContinue, setContinue] = useState<boolean>(false)
  return (
    <>
      <Paper elevation={0} sx={{backgroundColor: '#fff', borderRadius: '8px', paddingTop: '2vh'}}>
        <Grid container direction='row' justifyContent='space-evenly' minHeight={'300px'}>
          <StepDesc
            title={t("dms.step1Title")}
            description={t("dms.step1Desc")}
            picture={<DevicePower/>}
          />
          <Divider orientation="vertical" flexItem/>
          <StepDesc
            title={t("dms.step2Title")}
            description={t("dms.step2Desc")}
            picture={<DeviceNetwork/>}
          />
          <Divider orientation="vertical" flexItem/>
          <StepDesc
            title={t("dms.step3Title")}
            description={t("dms.step3Desc")}
            picture={<DeviceBound/>}
          />
          <Grid item xs={12} display={'flex'} justifyContent={'space-between'} margin={'32px 16px'}>
            <FormControlLabel
              control={
                <Checkbox onChange={(event) => {
                  setContinue(event.target.checked)
                }}/>
              }
              label={t("dms.readInstruction")}
            />
            <LoadingButton
              id='ContinueButton'
              variant="contained"
              disabled={!isContinue}
              onClick={() => {
                props.setStep('register')
              }}
            >
              {t("common.Continue")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default DeviceConnGuide;
