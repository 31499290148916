import { merge } from "lodash";
import { AxiosError, AxiosRequestConfig } from "axios";
import { BaseObserver } from "../observer";
import WebURL from "@/urls";
import CommonUtils from "@/utils/CommonUtils";


export class AxiosAuthInterceptor extends BaseObserver {
  id = "auth-interceptor";

  update(config: AxiosRequestConfig<any>) {
    if (process.env.REACT_APP_API_HOST) {
      config.url = `${process.env.REACT_APP_API_HOST}${config.url}`;
    } else {
      config.url = `/web${config.url}`;
    }

    const zlink_token = CommonUtils.getCookie('zlink_token');
    const local_token = localStorage.getItem("accessToken");
    const authorization = zlink_token || local_token ? {
      Authorization: zlink_token ? zlink_token : `Bearer ${local_token}`
    } : {}
    const headers = {
      "Content-Type": "application/json",
      "Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      "Offset": new Date().getTimezoneOffset(),
      "Accessor": "Web"
    };
    config.headers = merge({}, config.headers, merge({}, headers, authorization));
    console.log({ config });
    return config;
  }

  doError(err: AxiosError): AxiosError {
    if(err.response?.status === 401){
      const redirectFrom = localStorage.getItem('redirectFrom');
      const oldAccessToken = localStorage.getItem('accessToken');
      const oldRefreshToken = localStorage.getItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      if (redirectFrom) {
        if (oldAccessToken) {
          localStorage.setItem('oldAccessToken', oldAccessToken || '')
        }
        if (oldRefreshToken) {
          localStorage.setItem('oldRefreshToken', oldRefreshToken || '')
        }
        window.location.href = redirectFrom
      } else {
        if (process.env.REACT_APP_ZLINK_HOST) {
          window.location.href = WebURL.ZKLINK_REDIRECT;
        } else {
          // development
          window.location.href = WebURL.AUTH_USER_LOGIN;
        }
      }
    }
    return err;
  }
}