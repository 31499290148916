import React from "react";
import { Box } from "@mui/material";
import {ReactComponent as LogoIcon} from "@/assets/icons/logoIcon.svg";
import { useNavigate } from "react-router-dom";
import WebURL from "@/urls";


const ProductLogo: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{display: "flex", height: "40px", alignItems: "center", cursor: "pointer"}} 
      onClick={() => navigate(WebURL.DASHBOARD)}>
      <LogoIcon />
      <Box sx={{
        fontSize: "20px",
        fontFamily: "Roboto",
        fontWeight: 500,
        paddingLeft: "10px"
      }}>
        CloudATT
      </Box>
    </Box>
  )
};
export default ProductLogo
