import React from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import { checkStringLength } from "@/pages/att/Schedule/components/NewSchedule/utils";


const CompanyProfile: React.FC = () => {
  const company = useSelector((state: any) => state.app.company);
  return (
    <>
      {company &&
        <Box style={{ padding: 0 }}>
          <Tooltip title={company?.name}>
            <div>
              <Grid style={{ display: "flex", alignItems: "center", padding: "0 16px" }}>
                <AvatarIcon name={company?.name ? company.name : "None"} profilePhoto={company?.imagePreSignedURL} />
                <Typography variant={"subtitle2"} padding={'8px'}>
                  {checkStringLength(company?.name, 20)}
                </Typography>
              </Grid>
            </div>
          </Tooltip>
        </Box>
      }
    </>
  )
}
export default CompanyProfile
