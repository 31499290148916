import MDMS from './mdms'
import MLMS from './mlms'
import MOMS from './moms'
import MUAS from './muas'
import TAMS from './tams'
import SnackbarUtil from '../SnackbarUtil'

const common_code = new Map([
    ["I0000", "Success."],

    ["W0000", "Warning."],

    ["E0000", "Error."],
    ["E0001", "Invalid input."],
    ["E0002", "Incorrect authentication credentials."],
    ["E0003", "Authentication credentials were not provided."],
    ["E0004", "You do not have permission to perform this action."],
    ["E0005", "Malformed request."],
    ["E0006", "Not found."],
    ["E0007", "Method not allowed."],
    ["E0008", "Could not satisfy the request Accept header."],
    ["E0009", "Unsupported media type in request."],
    ["E0010", "Request was throttled."],
    ["E0011", "System busy, Please try later."],
    ["E0012", "MIOT system busy, Please try later."],
    ["E0013", "Company Database Not Using."],

    ["ERR_BAD_RESPONSE", "Request failed with Gateway timeout, please try again later"],
    ["ECONNABORTED", "Request timeout, please click refresh or try again later"],
]);


const commonMap = new Map();
const svcs = ['TAMS', 'MLMS', 'MOMS', 'MDMS', 'MUAS'];
for (const index in svcs) {
    for (const [key, value] of common_code) {
        commonMap.set(svcs[index] + key, value);
    }
}

const codeMap = new Map([...commonMap, ...TAMS, ...MLMS,...MOMS,...MDMS,...MUAS,])

const getMessage = (code: string, default_message?: string) => {
    const message = codeMap.get(code);
    if (message) {
        return message;
    } else if (default_message) {
        return default_message;
    } else {
        return code;
    }
}

const responseCodeAlert = (data: Record<string, any>) => {
    const content = getMessage(data.code, data.message);
    SnackbarUtil.error(content, {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
}

export default responseCodeAlert
