import React, {useEffect, useState} from "react";
import {Box, Grid, Paper, ButtonBase, Typography} from "@mui/material";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {ReactComponent as SiteMap} from "@/assets/images/SiteMap.svg"
import {timezones} from "@/components/resource/timezone";
import GenericService from "@/services/GenericService";
import {LocationEndpoints} from "@/services/location/endpoints";
import {useParams, useLocation} from "react-router-dom";
import InlineTextField from "@/components/form/InlineTextField"
import {useTranslation} from "react-i18next";
import InlineTextFieldNew from "@/components/form/InlineTextFieldNew";
import LocationText from "@/components/form/LocationText";

const showSiteView = ( site  : LOCATION.Site | undefined, name : string) => {

  if(name=== "state") {
    return site?.address.state !== undefined ? ( (site?.address.state.length > 0) ? site?.address.state : '-' ) : ('-')
  } else if (name=== "addressLine1") {
    return site?.address.addressLine1 !== undefined ? ( (site?.address.addressLine1.length > 0) ? site?.address.addressLine1 : '-' ) : ('-')
  }else if (name=== "addressLine2") {
    return site?.address.addressLine2 === undefined ? '-' : ( (site?.address.addressLine2.length > 0) ? site?.address.addressLine2 : '-' )
  } else if (name=== "city") {
    return site?.address.city !== undefined ? ( (site?.address.city.length > 0) ? site?.address.city : '-' ) : ('-')
  }else if (name=== "country") {
    return site?.address.country !== undefined ? ( (site?.address.country.length > 0) ? site?.address.country : '-' ) : ('-')
  }else if (name=== "latitude") {
    return site?.address.latitude !== undefined ? ( (site?.address.latitude.length > 0) ? site?.address.latitude : '-' ) : ('-')
  }else if (name=== "longitude") {
    return site?.address.longitude !== undefined ? ( (site?.address.longitude.length > 0) ? site?.address.longitude : '-' ) : ('-')
  }else if (name=== "description") {
    return site?.description !== undefined ? ( (site?.description.length > 0) ? site?.description : '-' ) : ('-')
  }
}

const SiteView: React.FC = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const state = location.state as { id: string }
  const timezoneList = timezones();
  const [siteInfo, setSiteInfo] = useState<LOCATION.Site>();
  useEffect(() => {
    GenericService.retrieve(LocationEndpoints.SITE_URI, state.id || "").then(res => {
      setSiteInfo(res.data)
    })
  }, [])

  return (
    <Box>
      <CustomBreadcrumbs title={t("common.View Site")}/>
      <Paper elevation={0} style={{ minHeight: '400px', backgroundColor: '#fff', borderRadius: '8px', padding: '20px' }}>
      <Grid item >
        <SiteMap width={178} height={178}/>
      </Grid>
      <Grid direction='row' alignItems={'flex-start'} id={"site-view-grid"} sx={{ paddingTop: "15px" }}>
        <LocationText title={t("dms.Site Name")} content={siteInfo?.name ?? '-' }/>
        <LocationText title={t("dms.Site Time Zone")} content={timezoneList.find(option => option.value === siteInfo?.address.timeZone)?.name ?? '-' }/>
        <LocationText title={t("common.Country")} content={ showSiteView(siteInfo, "country") }/>
        <LocationText title={t("common.Province state")} content={ showSiteView(siteInfo, "state") }/>
        <LocationText title={t("common.City")} content={ showSiteView(siteInfo, "city") }/>
        <LocationText title={t("common.Address line 1")} content={ showSiteView(siteInfo, "addressLine1") }/>
        <LocationText title={t("common.Address line 2")} content={ showSiteView(siteInfo, "addressLine2") }/>
        <LocationText title={t("common.Latitude")} content={ showSiteView(siteInfo, "latitude") }/>
        <LocationText title={t("common.Longitude")} content={ showSiteView(siteInfo, "longitude") }/>
        <LocationText title={t("dms.Description")} content={ showSiteView(siteInfo, "description") }/>
      </Grid>
      </Paper>
    </Box>
  )
};
export default SiteView
