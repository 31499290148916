const CommonUtils = {  
    setCookie(name: string, value: string, expires: number) {
        const date = new Date();
        date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
        const cookie_expires = 'expires=' + date.toUTCString();
        document.cookie = name + '=' + value + ';' + cookie_expires + ';path=/';
    },
    getCookie(name: string) {
        const cname = name + "=";
        const cookies = document.cookie ? document.cookie.split(';') : [];
        for (let i = 0; i < cookies.length; i++) {
            let value = cookies[i];
            while (value.charAt(0) === ' ') {
                value = value.substring(1);
            }
            if (value.indexOf(cname) === 0) {
                return value.substring(cname.length, value.length);
            }
        }
        return '';
    }

}

export default CommonUtils;