import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { KeyboardArrowDown, Logout } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import LanguageMenuItem from "@/parts/Header/components/LanguageMenuItem";
import TranslationUtil from "@/utils/TranslationUtil";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import { checkStringLength } from "@/pages/att/Schedule/components/NewSchedule/utils";

type MenuItemProps = {
  hidePopup?: () => void;
}

const LogoutMenuItem: React.FC<MenuItemProps> = (props) => {
  const navigate = useNavigate();
  const onLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate("/user/login");
  }
  return (
    <MenuItem onClick={onLogout}>
      <ListItemIcon>
        <Logout fontSize='small' sx={{ color: "#304669" }} />
      </ListItemIcon>
      <ListItemText>{TranslationUtil.t("common.Logout")}</ListItemText>
    </MenuItem>
  )
}
const UserProfile: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.app.user);
  const popupState = usePopupState({ variant: "popover", popupId: "userProfile" });

  return (
    <>
      {user &&
        <Box>
          <Tooltip title={t("common.Open Setting")} placement="bottom-end">
            <Grid
              style={{ display: "flex", alignItems: "center", padding: "0 16px" }}
              {...bindTrigger(popupState)}
            >
              <IconButton id='AvatarIconButton' sx={{ p: 0 }}>
                <AvatarIcon name={user?.firstName ? user.firstName : "None"} profilePhoto={user?.imagePreSignedURL} />
              </IconButton>
              <Typography sx={{ cursor: "pointer" }} variant={"subtitle2"} padding={'8px'}>
                {checkStringLength(( user?.firstName + " " + user?.lastName), 20)}
              </Typography>
              <IconButton id='DownIconButton' sx={{ p: 0 }}>
                <KeyboardArrowDown
                  sx={{
                    fontSize: { xs: '14px', lg: '28px' },
                    margin: { xs: '2px 4px 4px 4px', lg: '2px 4px 4px 4px' }
                  }}
                />
              </IconButton>
            </Grid>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted={true}
            {...bindMenu(popupState)}
          >
            <LanguageMenuItem hidePopup={async () => popupState.close()} />
            {/* <LogoutMenuItem/> */}
          </Menu>
        </Box>
      }

    </>

  )
}
export default UserProfile
