import {configureStore} from "@reduxjs/toolkit";
import appReducer from "./slices/App";
import organReducer from "./slices/Organization";
import siteReducer from "./slices/Site";
import zoneReducer from "./slices/Zone";

const reducer = {
  app: appReducer,
  organ: organReducer,
  site: siteReducer,
  zone: zoneReducer,
}

const store = configureStore({
  reducer,
  devTools: true
});
export default store;
