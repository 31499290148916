import Client from "..";
import {CMSEndpoints} from "./endpoints";

const create = async (data: any) => {
  const response = await Client().post(`${CMSEndpoints.CMS_CREDENTIAL_URI}`, data);
  return response;
}

const update = async (id: string, data: any) => {
  const response = await Client().put(`${CMSEndpoints.CMS_CREDENTIAL_URI}${id}/`, data);
  return response;
}

const updateUserRole = async (id: string, data: { [key: string]: any }) => {
  const response = await Client().post(`${CMSEndpoints.CMS_CREDENTIAL_URI}${id}/update_user_role/`, data);
  return response;
}

const setPersonPassword = async (data: { [key: string]: any }) => {
  const response = await Client().post(`${CMSEndpoints.CMS_CREDENTIAL_URI}set_password/`, data);
  return response;
}

const CredentialService = {
  create, 
  update,
  updateUserRole,
  setPersonPassword
}
export default CredentialService