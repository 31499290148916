import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CustomRowButton from "@/components/data-grid/CustomRowButton";
import CustomToolBar from "@/components/data-grid/CustomToolBar";
import Dialog from "@/components/dialog";
import RoleService from "@/services/auth/RoleService";
import WebURL from "@/urls";
import { useSnackbar } from "notistack";
import { Add, DeleteOutline, Edit, RemoveRedEyeOutlined } from "@mui/icons-material";
import {Box, CircularProgress, Grid, Paper, Stack, Tooltip, Typography} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthEndpoints } from "@/services/auth/endpoints";
import {useTranslation} from "react-i18next";
import GenericService from "@/services/GenericService";
import Button from '@mui/material/Button';
import Client from "@/services";
import LoadingButton from '@mui/lab/LoadingButton';
import SnackbarUtil from "@/utils/SnackbarUtil";
import { Breadcrumbs } from "@mui/material";
import { ReactComponent as NoRecordFoundIcon } from '@/assets/images/noResultFound.svg';
import { isRoleOwner, getCurrentPermslist, isNullPermsList } from "@/utils/permissions/FilterUnauthed";
import { URL2PERMS2 } from "@/pages/auth/Role/components/static";
import { noPermissionToAccess, titlePermissionObject } from "@/pages";

type ItemProps = {
  name: string,
  id: string,
  code: string,
  default?: boolean,
  users:PersonProps[],
  isScopeGlobal: boolean,
  general_person?: boolean
}

type PersonProps = {
  id:string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  verified: boolean,
}


function Row(props: ItemProps) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = React.useState(false);


  const handleRolesUsers = async (roleID: string) => {
    const response = await Client().post(AuthEndpoints.AUTH_GET_ROLE_USERS_URI, roleID);
    // if (response.data.users.length === 0){
    //   SnackbarUtil.info(t("role.There are no users for this role"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
    // }
    // else{
    //   setUsers(response.data.users)
    // }
    if (response.data.users.length !== 0){
      setUsers(response.data.users)
    }

    setLoading(false)
    return response.data;
  }
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset'}, "&:last-child td, &:last-child th": { border: 0 }}}
                onClick={() => {setOpen(!open)
                  if (!open){
                    handleRolesUsers(props.id)
                    setLoading(true);
                  }}}>
        <TableCell>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
        <TableCell align="left" scope="row" sx={{paddingLeft: "20px"}}>
          {props.name}
        </TableCell>
        <TableCell align="left" component="th" scope="row">{props.code}</TableCell>
        <TableCell>
          {props.code === "SUPERADMIN"||props.code === "ORGANIZATION OWNER" ?
          (<Tooltip title={t('common.View')} placement='bottom-start'>
            <div>
            <CustomRowButton insideIcon={RemoveRedEyeOutlined} buttonProps={{
                key: "views",
                onClick: () => {
                  navigate(WebURL.AUTH_ROLE_EDIT, {state: {id: props.id, name: props.name, code: props.code, view: true}})
                }
              }} />
              </div>
            </Tooltip>)
            :
             (props.isScopeGlobal && !props.general_person ? (
            <Grid container spacing={1}>
              <Grid item>
              <Tooltip title={t('common.View')} placement='bottom'>
                <div>
                <CustomRowButton insideIcon={RemoveRedEyeOutlined} buttonProps={{
                  key: "views",
                  onClick: () => {
                    navigate(WebURL.AUTH_ROLE_EDIT, {state: {id: props.id, name: props.name, code: props.code, view: true}})
                  }
                }} />
                </div>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t('common.Edit')} placement='bottom'>
                  <div>
                <CustomRowButton insideIcon={Edit} buttonProps={{
                    key: "edit",
                    onClick: () => {
                      navigate(WebURL.AUTH_ROLE_EDIT, {state: {id: props.id, name: props.name, code: props.code, view: false}})
                    }
                  }} />
                  </div>
                  </Tooltip>
              </Grid>
            </Grid>) : (
              <Grid container spacing={1}>
              <Grid item>
              <Tooltip title={t('common.View')} placement='bottom'>
                <div>
                <CustomRowButton insideIcon={RemoveRedEyeOutlined} buttonProps={{
                  key: "views",
                  onClick: () => {
                    navigate(WebURL.AUTH_ROLE_EDIT, {state: {id: props.id, name: props.name, code: props.code, view: true}})
                  }
                }} />
                </div>
                </Tooltip>
              </Grid>
            </Grid>)
            )
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ paddingLeft: "30px"}}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{backgroundColor: '#F0F1F4!important', outline: 'none'}}>
                     <TableCell sx={{width: "1px"}} ></TableCell>
                     <TableCell style={{width: "350px", padding: '13px'}}>
                        <Typography sx={{fontSize: "0.875rem"}} color={"#304669!important"}>
                          {t("common.Person Name")}
                        </Typography>
                     </TableCell>
                     <TableCell style={{width: "350px", padding: '13px'}}>
                        <Typography sx={{fontSize: "0.875rem"}} color={"#304669!important"}>
                          {t("signUp.Email")}
                        </Typography>
                     </TableCell>
                     <TableCell style={{width: "290px", padding: '13px'}}>
                        <Typography sx={{fontSize: "0.875rem"}} color={"#304669!important"}>
                          {t("common.Mobile")}
                        </Typography>
                     </TableCell>
                     <TableCell>
                        <Typography sx={{fontSize: "0.875rem"}} color={"#304669!important"}>
                          {t("dms.Status")}
                        </Typography>
                     </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.length === 0 ? <TableRow>
                    <TableCell colSpan={5} align='center'>
                      <Paper  elevation={0} style={{ minHeight:'100%',backgroundColor: '#ffffff', borderRadius: '8px', paddingTop: '16px', paddingBottom: '16px', overflow: 'hidden' }}>
                        <Box display={'flex'}justifyContent='center'>
                          <NoRecordFoundIcon height={"60px"}/>
                        </Box>
                        <Typography textAlign={'center'}>
                          {t("organ.Employee no rows")}
                        </Typography>
                      </Paper>
                       </TableCell>
                     </TableRow> : users.map((User:PersonProps) => (
                    <TableRow key={User.id}>
                      <TableCell></TableCell>
                      <TableCell style={{width: "150px", padding: '13px'}}>
                        {User.firstName}  {User.lastName}
                      </TableCell>
                      <TableCell style={{width: "150px", padding: '13px'}}>{User.email}	</TableCell>
                      <TableCell style={{width: "150px", padding: '13px'}}>
                        {User.phone}
                      </TableCell>
                      <TableCell>
                        {User.verified ? <Button color="success" sx={{ textTransform: "none",
                            backgroundColor: '#f4f6f7!important'}}>Active</Button>
                          : <Button color="warning" sx={{ textTransform: "none",
                            backgroundColor: '#f4f6f7!important'}}>Pending</Button>}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const Role: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isScopeGlobal, setIsScopeGlobalScope] = useState<boolean>(false);
  const [haveRolePermission, setHaveRolePermission] = useState<boolean>(true)

  const handleRoles = () => {
    GenericService.list(AuthEndpoints.AUTH_ROLE_URI, {}).then((response) => {
      console.log("???", response.data.data)
      setData(response.data.data)

      const result = response.data.data.filter((row: any) => {
         return row.scope === "GLOBAL"
      })

      if(result.length > 0) {
        setIsScopeGlobalScope(true)
      }

    }).catch((error) => {
      return error;
    });
  }
  useEffect(() => {
    handleRoles()
  }, [])

  useEffect( () => {

    if(isRoleOwner()) {
      setHaveRolePermission(true)
    } else {

      if(isNullPermsList()) {
        setHaveRolePermission(false)
      } else {

        const currentPermsList = getCurrentPermslist()
        const allowedPerms = currentPermsList.filter(( perms: any) => {
          return URL2PERMS2.get(perms.code) == location.pathname;
        })

        if (allowedPerms.length <= 0) {
          setHaveRolePermission(false)
        }
      }
    }
  }, [location.pathname])


  if(!haveRolePermission) {
    const result =  noPermissionToAccess[location.pathname]
    const title = titlePermissionObject[location.pathname]

    return (
      <Box>
        <Grid className={"toolbar-container"}>
          <Breadcrumbs sx={{height: "32px", p: '8px'}}>
            <Typography>{t(title)}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid>
          <Grid container={true} rowSpacing={2} sx={{margin: "0"}}>
            <h1>{t(result)}</h1>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
   <>
    <CustomToolBar
      title={t("role.Role and permission")}
      breadcrumbs={[{breadcrumbName: "Organization", path: WebURL.ORGANIZATION_HOME}, {breadcrumbName: t("role.Role and permission"), path: WebURL.AUTH_ROLE}]}
      refresh={false}
    />
      <Grid item={true} xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow sx={{backgroundColor: '#F0F1F4!important', outline: 'none'}}>
                <TableCell style={{ width: '2%' }}/>
                <TableCell style={{alignItems: 'center', justifyContent: 'center'}}>
                  <Typography sx={{fontSize: "0.875rem"}} color={"#304669!important"} variant="h6">
                    {t("role.User Role Name")}
                  </Typography>
                </TableCell>
                <TableCell style={{ alignItems: 'center', justifyContent: 'center'}}>
                  <Typography sx={{fontSize: "0.875rem"}} color={"#304669!important"} variant="h6">
                    {t("role.User Role Code")}
                  </Typography>
                </TableCell>
                <TableCell style={{alignItems: 'center', justifyContent: 'center'}}>
                  <Typography sx={{fontSize: "0.875rem"}} color={"#304669!important"} variant="h6">
                    {t("common.Action")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row:ItemProps) => (
                <Row name={row.name}  code={row.code} id={row.id} key={row.id} users={row.users}
                isScopeGlobal={isScopeGlobal} general_person={row.general_person}/>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
     </>
  );
}


export default memo(Role);
