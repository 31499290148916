import {useTranslation} from "react-i18next";
import { useLocation } from 'react-router-dom';
import { noPermissionToAccess } from "..";

function NoPermission() {
  const {t} = useTranslation()
  const location = useLocation()
  const { pathname } = location
  const result =  noPermissionToAccess[pathname]

   return (
       <div>
          {result ? (
            <h1>{t(result)}</h1>
          ) :
          (
            <h1>{t("role.No permission to access this page")}</h1>
          )}
       </div>
   )
}
export default NoPermission
