import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Stack} from "@mui/material";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {ReactComponent as SiteMap} from "@/assets/images/SiteMap.svg"
import {object, string, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {timezones} from "@/components/resource/timezone";
import {countryList} from "@/components/resource/country";
import GenericService from "@/services/GenericService";
import {LocationEndpoints} from "@/services/location/endpoints";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import WebURL from "@/urls";
import ControlTextField from "@/components/hook-form/ControlTextField";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import {useTranslation} from "react-i18next";


const EditSite: React.FC = () => {
  const location = useLocation()
  const {t} = useTranslation()
  const state = location.state as { id: string }
  const navigate = useNavigate()
  const [saving, setSaving] = useState<boolean>(false)
  const siteSchema = object({
    name: z.string().min(1, t("common.This field is required!")),
    timezone: z.object({
      value: z.string(),
      name: string()
    }),
    country: z.object({
      name: z.string()
    }),
    state: z.string().nullable(),
    city: z.string().min(1, t("common.This field is required!")),
    addressLine1: z.string().min(1, t("common.This field is required!")),
    addressLine2: z.string().nullable(),
    description: z.string().nullable(),
  })
  type siteFormType = TypeOf<typeof siteSchema>

  const siteForm = useForm<siteFormType>({
    resolver: zodResolver(siteSchema),
    defaultValues: {
      timezone: {value: '', name: ''},
      country: {name: ''},
    }
  })
  const timezoneList = timezones();
  useEffect(() => {
    GenericService.retrieve(LocationEndpoints.SITE_URI, state.id || "").then(res => {
      const site = res.data
      const timezone = timezoneList.find(option => option.value === site.address?.timeZone)
      const country = countryList.find(option => option.name === site.address?.country)
      siteForm.reset({
        name: site.name,
        timezone,
        country,
        state: site.address?.state || "",
        city: site.address?.city || "",
        addressLine1: site.address?.addressLine1 || "",
        addressLine2: site.address?.addressLine2 || "",
        description: site.description || ""
      })
    })
  }, [siteForm.reset])

  const onSave = async () => {
    setSaving(true)
    try {
      const isValid = await siteForm.trigger()
      if (isValid) {
        const values = siteForm.getValues()
        const newSite = {
          name: values.name,
          description: values.description,
          address: {
            timeZone: values.timezone.value,
            country: values.country.name,
            state: values.state,
            city: values.city,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2
          }
        }
        console.log(newSite)
        await GenericService.update(LocationEndpoints.SITE_URI, state.id || '', newSite)
        navigate(WebURL.LOCATION_SITE)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Box>
      <CustomBreadcrumbs title={"Edit Site"}/>
      <Grid container={true}>
        <Grid item lg={4} xs={6} sx={{display: "flex", justifyContent: "center", alignItems: "start"}}>
          <SiteMap/>
        </Grid>
        <Grid item lg={7.70} xs={6} component={"form"}
              sx={{display: "flex", justifyContent: "center", alignItems: "start"}}>
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <ControlTextField label={t("dms.Site Name")} name={"name"} form={siteForm} required={true}/>
            </Grid>
            <Grid item xs={6}>
              <ControlAutoComplete
                label={t('dms.Timezone')}
                name={'timezone'}
                form={siteForm}
                options={timezoneList}
                required={true}
                isOptionEqualToValue={(option, value) => option.name === value.name}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlAutoComplete
                label={t('common.Country')}
                name={'country'}
                form={siteForm}
                options={countryList}
                required={true}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlTextField label={t("common.State")} name={"state"} form={siteForm}/>
            </Grid>
            <Grid item xs={12}>
              <ControlTextField label={t("common.City")} name={"city"} form={siteForm} required={true}/>
            </Grid>
            <Grid item xs={12}>
              <ControlTextField label={t("common.Address Line 1")} name={"addressLine1"} form={siteForm} required={true}/>
            </Grid>
            <Grid item xs={12}>
              <ControlTextField label={t("common.Address Line 2")} name={"addressLine2"} form={siteForm}/>
            </Grid>
            <Grid item xs={12}>
              <ControlTextField label={t("common.Description")} name={"description"} form={siteForm}/>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={2} sx={{display: "flex", justifyContent: "end"}}>
                <LoadingButton id='SaveButton' variant={"contained"} onClick={onSave} loading={saving}>{t("common.Save")}</LoadingButton>
                <Button id='CancelButton' variant={"outlined"} onClick={() => {
                  navigate(WebURL.LOCATION_SITE)
                }}>
                  {t("common.Cancel")}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};
export default EditSite
