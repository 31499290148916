import Client from "..";
import {AuthEndpoints} from "./endpoints";

const login = async (credentials: Record<string, any>) => {
  const response = await Client().post(AuthEndpoints.AUTH_USER_LOGIN, credentials);
  return response.data;
}

const signUp = async (data: Record<string, any>) => {
  const response = await Client().post(AuthEndpoints.AUTH_USER_SIGN_UP, data)
  return response.data;
}

const userInfo = async () => {
  const response = await Client().get(AuthEndpoints.AUTH_USER_INFO_URI);
  console.log("User Service >> userInfo >>>>", response.data)
  return response.data;
}

const userList = async () => {
  return Client().get(AuthEndpoints.AUTH_USER_URI);
}

const UserService = {
  login,
  userInfo,
  userList,
  signUp,
}
export default UserService