const TAMS = new Map([
    ["TAMSE1001", "End date must be greater than Start date."],
    // ["TAMSE1002", "Employee have overlapping schedules, please check the request data."],
    ["TAMSE1003", "Break end time must be greater than Break start time"],
    // ["TAMSE1004", "Records With Duplicate Name {name}"],
    // ["TAMSE1005", "Wrong time range selection for Day {index}"],
    // ["TAMSE1006", "The break time is set incorrectly, please make sure that the break time must be included in the clock time range"],
    // ["TAMSE1007", "Make sure Max Work Hours is positive integer and in the range 1-24"],
    ["TAMSE1008", "Time input cannot be empty, please confirm"],
    // ["TAMSE1009", "The time format is wrong, please check the time input"],
    // ["TAMSE1010", "This TimeSheet is already in use, deletion is prohibited"],
])

export default TAMS
