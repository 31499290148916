import {createTheme} from "@mui/material";

import 'typeface-roboto';


const mainColor = "#36C96D";
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: mainColor,
      light: mainColor,
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '60px',
          background: 'white',
          color: '#304669',
          boxShadow: '0px 0px 0px 0px'
        }
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          '&:hover': {
            backgroundColor: "white",
          },
          '&.Mui-focused': {
            backgroundColor: "white",
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          width: '180px',
          height: '46px',
          borderRadius: '8px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 7,
          height: 36,
          "&.Mui-checked": {
            "& $thumb": {
              "backgroundColor": "#fff"
            },
            "& + $track": {
              "opacity": "1 !important"
            }
          },
        },
        thumb: {
          width: 20,
          height: 20,
          backgroundColor: '#fff',
          boxShadow: '0 0 12px 0 rgba(0,0,0,0.08), 0 0 8px 0 rgba(0,0,0,0.12), 0 0 4px 0 rgba(0,0,0,0.38)'
        },
        switchBase: {
          color: 'rgba(0,0,0,0.38)',
          paddingTop: 8,
          paddingBottom: 8
        },
        track: {
          borderRadius: 20,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        scrollPaper: {
          boxShadow: "0 0 black",
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 400,
        },
        subtitle2: {
          fontFamily: "Roboto",
          fontSize: "18px",
          fontWeight: 400,
          color: '#304669',
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: '4px',
          marginRight: '4px',
        }
      }
    }
  },
  typography: {
    fontFamily: "Roboto"
  },
});

export default theme;
