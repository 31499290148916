import React from "react";
import {Autocomplete, AutocompleteRenderOptionState, AutocompleteValue, CircularProgress, TextField} from "@mui/material";
import {Controller, UseFormReturn} from "react-hook-form";
import {AutocompleteProps} from "@mui/material/Autocomplete/Autocomplete";

type ControlAutoCompleteType = {
  label: string;
  name: string;
  form: UseFormReturn<any>;
  loading?: boolean;
  options: any[];
  required?: boolean;
  value?: any;
  id?:any
  inputValue?: string;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: string) => void;
  groupBy?: (option: any) => string;
  getOptionLabel?: (option: any) => string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  onChange?: (value: AutocompleteValue<any, any, any, any>) => void;
  renderOption?: (
    props: React.HTMLAttributes<HTMLElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
}

const ControlAutoComplete: React.FC<ControlAutoCompleteType> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.form.control}
      render={({field: {onChange, value}, fieldState}) => {
        return (
          <Autocomplete
            fullWidth={true}
            options={props.options}
            groupBy={props?.groupBy}
            loading={props?.loading}
            id={props?.id}
            renderOption={props?.renderOption}
            inputValue={props?.inputValue}
            onInputChange={props?.onInputChange}
            getOptionLabel={props?.getOptionLabel ? props.getOptionLabel : option => option.name}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={props.label}
                  required={props.required}
                  onChange={onChange}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {props?.loading && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )
            }}
            onChange={(event, value) => {
              onChange(value)
              props.onChange?.(value)
            }}
            value={props?.value ? props.value : value}
            isOptionEqualToValue={props?.isOptionEqualToValue}
          />
        )
      }}
    />
  )
}
export default ControlAutoComplete
