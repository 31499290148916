import React from "react";
import {TextField, TextFieldProps} from "@mui/material";
import {Controller, UseFormReturn} from "react-hook-form";

type ControlTextFieldTimeSheetType = Omit<TextFieldProps, 'name'> & {
  name: string;
  form: UseFormReturn<any>;
  tooltip?: string
}

const ControlTextFieldTimeSheet: React.FC<ControlTextFieldTimeSheetType> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.form.control}
      render={({field, fieldState}) => (
        <TextField
          label={fieldState.error?.message }
          sx={{ width: "300px"}}
          error={!!fieldState.error}
          helperText={fieldState.error?.message }
          {...props}
          {...field}
          InputLabelProps={{
            shrink: !!field.value
          }}
          InputProps={{
            ...props.InputProps
          }}
        />)}
    />
  )
}
export default ControlTextFieldTimeSheet
