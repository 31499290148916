import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom"
import { ReactComponent as MainImg } from '../../../assets/images/loginImage.svg';


const OnBordlayout = (props: any) => {
  return (
    <Grid style={{ height: '100%', width: '100%' }}>
      <Grid
        sx={{
          width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <Grid
          sx={{
            width: { xs: '0%', sm: '0%', md: '50%', lg: '50%' },
            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Grid style={{ marginBottom: '-90px' }}>{props.Image}</Grid>
        </Grid>
        <Grid
          sx={{
            width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <Logo width={'220'} height={'120'} /> */}

          {/* <img src={cloudAccess} alt='logo' className='left_img' /> */}
          <Grid sx={{ width: { lg: '60%', xs: '90%', overflow: 'auto' } }}>
            <Box marginRight={'10px'}>{props.children}</Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}


const SessionOut = () => {
  return (
    <OnBordlayout Image={<MainImg />}>
      <Typography variant="h4" component={'div'} gutterBottom sx={{color: '#474A4E'}}>
        Session timeout.
      </Typography>

      <br></br>
      <Button
        id='LoginWithZlinkButton'
        variant="contained"
        sx={{width: '100%', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF'}}
        onClick={() => {
          if (process.env.REACT_APP_ZLINK_HOST) {
            window.location.assign(process.env.REACT_APP_ZLINK_HOST);
          }
        }}
      >
        Login with zlink
      </Button>
      {/* <Button
        id='TryAgainButton'
        variant="contained"
        sx={{width: '100%', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF', marginTop: '20px'}}
        onClick={() => {
          window.location.href = '/dashboard'
        }}
      >
        Try Again
      </Button> */}
    </OnBordlayout>
  )
}

export default SessionOut;
