import React, {useEffect, useState} from "react";
import {Box, Grid, Paper, ButtonBase} from "@mui/material";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {ReactComponent as SiteMap} from "@/assets/images/SiteMap.svg"
import {timezones} from "@/components/resource/timezone";
import GenericService from "@/services/GenericService";
import {LocationEndpoints} from "@/services/location/endpoints";
import {useParams, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import InlineTextFieldNew from "@/components/form/InlineTextFieldNew";
import LocationText from "@/components/form/LocationText";

const showZoneView = ( zone  : LOCATION.Zone | undefined, name : string) => {

  if(name=== "state") {
    return zone?.address.state !== undefined ? ( (zone?.address.state.length > 0) ? zone?.address.state : '-' ) : ('-')
  } else if (name=== "addressLine1") {
    return zone?.address.addressLine1 !== undefined ? ( (zone?.address.addressLine1.length > 0) ? zone?.address.addressLine1 : '-' ) : ('-')
  }else if (name=== "addressLine2") {
    return zone?.address.addressLine2 !== undefined ? ( (zone?.address.addressLine2.length > 0) ? zone?.address.addressLine2 : '-' ) : ('-')
  } else if (name=== "city") {
    return zone?.address.city !== undefined ? ( (zone?.address.city.length > 0) ? zone?.address.city : '-' ) : ('-')
  }else if (name=== "country") {
    return zone?.address.country !== undefined ? ( (zone?.address.country.length > 0) ? zone?.address.country : '-' ) : ('-')
  }
}

const ZoneView: React.FC = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const state = location.state as { id: string }
  const timezoneList = timezones();
  const [zoneInfo, setZoneInfo] = useState<LOCATION.Zone>();
  useEffect(() => {
    GenericService.retrieve(LocationEndpoints.ZONE_URI, state.id || "").then(res => {
      setZoneInfo(res.data)
    })
  }, [])

  return (
    <Box>
      <CustomBreadcrumbs title={t("common.View Zone")}/>
      <Paper elevation={0} style={{ minHeight: '400px', backgroundColor: '#fff', borderRadius: '8px', padding: '20px' }}>
        <Grid item >
          <SiteMap width={178} height={178}/>
        </Grid>
        <Grid direction='row' alignItems={'flex-start'} id={"site-view-grid"} sx={{ paddingTop: "15px" }}>
          <LocationText title={t("dms.Zone Name")} content={zoneInfo?.name ?? '-' }/>
          <LocationText title={t("dms.Site Name")} content={zoneInfo?.site_name ?? '-'}/>
          <LocationText title={t("dms.Site Time Zone")} content={ (timezoneList.find(option => option.value === zoneInfo?.timezone)?.name) ?? '-' }/>
          <LocationText title={t("common.Country")} content={ showZoneView(zoneInfo, "country") }/>
          <LocationText title={t("common.Province state")} content={ showZoneView(zoneInfo, "state") }/>
          <LocationText title={t("common.City")} content={ showZoneView(zoneInfo, "city") }/>
          <LocationText title={t("common.Address line 1")} content={ showZoneView(zoneInfo, "addressLine1") }/>
          <LocationText title={t("common.Address line 2")} content={ showZoneView(zoneInfo, "addressLine2") }/>
          {/* <InlineTextField label={"Description"} value={zoneInfo?.description}/> */}
        </Grid>
      </Paper>
    </Box>
  )
};
export default ZoneView
