import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import Prefix from "@/utils/static/NamePrefix"

type SubMenuItemProps = {
  name: string;
  title: string;
  icon: SvgIconComponent | React.FunctionComponent;
  path: string;
}

const SubMenuItem: React.FC<SubMenuItemProps> = (props) => {
  const navigate = useNavigate();
  const onItemClick = () => {
    navigate(props.path);
  }
  return (
    <Grid
      key={props.name}
      item={true}
      xs={6}
      md={3}
    >
      <Box display={"flex"} alignItems={"center"}  onClick={onItemClick}
        sx={{
          border:"1px solid #DDDDDD", 
          padding:{xl:"30px",xs:"20px"},
          marginRight:"24px",
          cursor: "pointer",
          borderRadius:{xl:"10px", xs:"8px"},
          backgroundColor:"#FFF",
          '&:hover':{
            boxShadow:"0px 2px 8px #474b4f80",
            transition: "0.2s ease-in-out"
          }
          }}>
        <Box
          display={"flex"} alignItems={"center"} justifyContent={"center"}  id={`${Prefix.MENU_ICON}${props.name}`}
          sx={{
            width: "48px",
            height: "48px",
            backgroundColor: "#36C96D",
            borderRadius: "50%",
          
          }}
         
        >
          <props.icon sx={{color: "#ffffff"}}/>
        </Box>
        <Box sx={{padding: "0 8px", cursor: "pointer"}} id={`${Prefix.MENU_TEXT}${props.name}`}>
          <Typography>{props.title}</Typography>
        </Box>
      </Box>
    </Grid>
  )
}
export default SubMenuItem
