import {Box, Breadcrumbs, Grid, Typography} from "@mui/material";
import React from "react";
import PresentStatus from "./components/PresentStatus";
import EmployeeAttendance from "./components/EmployeeAttendance";
import {useTranslation} from "react-i18next";
import {isEligibleAction} from "@/utils/permissions/FilterUnauthed"
import NoPermission from "@/pages/others/NoPermission";
import { HREndpoints } from "@/services/hr/endpoints";
import Client from "@/services";
import CompanyService from "@/services/auth/CompanyService";

const DASHBOARD_PERMISSION_NAME = "DASHBOARD_VIEW"

const Dashboard: React.FC = () => {
  const {t} = useTranslation();
  const isAuthed = isEligibleAction(DASHBOARD_PERMISSION_NAME)
  const [rdyShow, setRdyShow] = React.useState(false)

  React.useEffect( () => {

    const getCompanyInfo = async () => {
      try {
        const {data} = await CompanyService.companyInfo();
        return data
      } catch (error) {
        return undefined
      }
    }

    getCompanyInfo().then((obj) => {
      const data = {company_id: obj.id}
      Client().post(`${HREndpoints.HR_EMPLOYEE_URI}update_or_create_cache_employee/`, data)
    })

  }, [])

  return (
    <>
      {!isAuthed? <NoPermission/>:
        <Box>
          <Grid className={"toolbar-container"}>
            <Breadcrumbs sx={{height: "32px", paddingLeft: '8px', paddingTop: '8px'}}>
              <Typography>{t("common.sidebar.Dashboard")}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid container={true} rowSpacing={2} columnSpacing={6} sx={{ p: '8px'}}>
            <Grid item={true} xs={4}>
              <PresentStatus category={"day"} title={t("common.Today")} waiting={rdyShow}/>
            </Grid>
            <Grid item={true} xs={4}>
              <PresentStatus category={"week"} title={t("common.This Week")} waiting={rdyShow}/>
            </Grid>
            <Grid item={true} xs={4} >
              <PresentStatus category={"month"} title={t("common.This Month")} waiting={rdyShow}/>
            </Grid>
            <Grid item xs={12}>
              <EmployeeAttendance setWaiting={setRdyShow}/>
            </Grid>
          </Grid>
        </Box>
      }
    </>
  )
}
export default Dashboard
