import { Grid, IconButton, Tooltip } from "@mui/material"
import { GridFilterAltIcon } from "@mui/x-data-grid"
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";


const FilterAction = (props: any) => {
  const {t} = useTranslation()

  return (
    <Fragment>
      <Tooltip title={t("att.report.Filter")}>
        <IconButton id='GridFilterIconButton' sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }} onClick={()=>{
          props.handleShowFilter()
        }}>
          <GridFilterAltIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  )
}

export default FilterAction
