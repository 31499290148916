import Client from "..";
import {DMSEndpoints} from "./endpoints";

const rebootDevice = async (id: string, options?: { [key: string]: any }) => {
  const response = await Client().post(`${DMSEndpoints.DMS_DEVICE_URI}${id}/reboot/`, {});
  return response.data;
}

const syncDateTimeDevice = async (id: string, options?: { [key: string]: any }) => {
  const response = await Client().post(`${DMSEndpoints.DMS_DEVICE_URI}${id}/sync_date_time/`, {});
  return response.data;
}

const enableDevice = async (id: string, options?: { [key: string]: any }) => {
  const response = await Client().put(`${DMSEndpoints.DMS_DEVICE_URI}${id}/enable/`, {});
  return response.data;
}

const disableDevice = async (id: string, options?: { [key: string]: any }) => {
  const response = await Client().put(`${DMSEndpoints.DMS_DEVICE_URI}${id}/disable/`, {});
  return response.data;
}

const addPerson = async (id: string, options?: { [key: string]: any }) => {
  const response = await Client().post(`${DMSEndpoints.DMS_DEVICE_PERSON_URI}${id}/add_person/`, options);
  return response.data;
}

const deviceVerify = async (sn: string) => {
  const response = await Client().post(`${DMSEndpoints.DMS_DEVICE_URI}verify/`,{sn});
  return response.data;
}

const deviceBinding = async (data: { [key: string]: any }) => {
  const response = await Client().put(`${DMSEndpoints.DMS_DEVICE_URI}binding/`, data);
  return response.data;
}

const update = async (id: string, data: { [key: string]: any }) => {
  const response = await Client().put(`${DMSEndpoints.DMS_DEVICE_URI}${id}/`, data);
  return response.data;
}

const uploadTransactions = async (data?: { [key: string]: any }) => {
  return Client().post(`${DMSEndpoints.DMS_DEVICE_URI}transactions/`, data);
}

const remoteRegistration = async (id: string, data: { [key: string]: any }) => {
  return Client().post(`${DMSEndpoints.DMS_DEVICE_URI}${id}/registration/`, data);
}

const deleteDevicePerson = async (id: string, config: { [key: string]: any }) => {
  return Client().delete(`${DMSEndpoints.DMS_DEVICE_PERSON_URI}${id}/delete_device_person/`, config);
}

const AccDeviceFilter = async (data?: { [key: string]: any }) => {
  return Client().get(`${DMSEndpoints.DMS_DEVICE_URI}acc_device_list/`, data);
}

const DeviceService = {
  rebootDevice,
  syncDateTimeDevice,
  enableDevice,
  disableDevice,
  addPerson,
  deviceVerify,
  deviceBinding,
  update,
  uploadTransactions,
  remoteRegistration,
  deleteDevicePerson,
  AccDeviceFilter
}
export default DeviceService
