import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HistoryIcon from '@mui/icons-material/History';


const HistoryAction = ({historyUrl}: any) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <Tooltip title={t("common.History")}>
      <IconButton id='HistoryIconButton' onClick={() => {
        if (historyUrl) {
          navigate(historyUrl);
        }
      }} sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
        <HistoryIcon />
      </IconButton>
    </Tooltip>
  )
}

export default HistoryAction
