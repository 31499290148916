import jwtDecode from "jwt-decode";
import { OWNER, SUPERADMIN, URL2PERMS } from "@/pages/auth/Role/components/static";

export const isRoleOwner = () => {
  const jwtToken = localStorage.getItem("accessToken") || "";
  if (!jwtToken)
    return true
  const decodedToken = jwtDecode<{roleName: string}>(jwtToken);
  return decodedToken.roleName === OWNER || decodedToken.roleName === SUPERADMIN
}

export const getCurrentPermslist = () => {
  return JSON.parse(localStorage.getItem('tmpUserPerms') || "[]")
}

export const isNullPermsList = () => {
  // 判断该角色是否被分配了权限
  return Object.keys(getCurrentPermslist()).length===0
}

// 过滤掉menu.name不在权限列表中的menu项
export const filterEligibleMenu = (menus: any[]) => {
  if (isRoleOwner()) return menus
  if (isNullPermsList()) return []

  const currentPermsList = getCurrentPermslist()
  const eligibleMenu: any[] = menus.filter((menu: any)=>{
    return currentPermsList.some((perm:any) => perm.code.toLowerCase().includes(menu.name))
  })
  return eligibleMenu
}

// 如果设置了authedAction且该actionName能匹配权限列表某一权限，则可正常显示action列，反之不显示
export const isEligibleAction = (actionName?: string) => {
  if (isRoleOwner()) return true
  if (isNullPermsList()) return false
  if (typeof actionName === 'undefined') return true

  const currentPermsList = getCurrentPermslist()
  const eligibleAction: any[] = currentPermsList.filter((perm: any)=>{
    return perm.code.toLowerCase().includes(actionName?.toLowerCase())
  })
  return eligibleAction.length > 0
}

//  根据路径名字获取对应的权限名， 若权限名不在角色权限列表中，则对应模块不予显示
export const isAuthedAccess = (pathName?: string) => {
  if (isRoleOwner()) return true
  if (isNullPermsList()) return false
  if (typeof pathName === 'undefined') return true
  const urlPermName = URL2PERMS.get(pathName)
  if (typeof urlPermName === 'undefined') return true

  const currentPermsList = getCurrentPermslist()
  const matchedPerm: any[] = currentPermsList.filter((perm: any)=>{
    return perm.code.toLowerCase().includes(urlPermName?.toLowerCase())
  })
  return matchedPerm.length > 0
}
