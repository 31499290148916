import React, { Fragment, useContext, useEffect, useState } from "react";
import moment, { Moment } from "moment/moment";
import { GridColDef } from "@mui/x-data-grid";
import { ATTEndpoints } from "../../../../services/att/endpoints";
import WebURL from "../../../../urls";
import CustomDataGrid from "../../../../components/data-grid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import MonthlyService from "@/services/att/services/MonthlyService";
import { Box, Grid, TextField, Tooltip } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGridContext, DataGridDispatchContext } from "@/components/data-grid/DataGridContext";
import Util from "@/utils/Util";
import ExportAction from "@/components/actions/Export";
import {isEligibleAction} from "@/utils/permissions/FilterUnauthed"
import SnackbarUtil from "@/utils/SnackbarUtil";

const MONTHLYREPORT_PERMISSION_NAME = "MONTHLY_EXPORT"

const ExtraAction = (props: any) => {
  const {t} = useTranslation();
  const [month, setMonth] = React.useState<Date | null>(new Date())
  const dispatch = useContext(DataGridDispatchContext)
  const { searchKeyword } = useContext(DataGridContext)
  const handleRefresh = () => {
    dispatch({type: "refreshed"})
  }
  const hasExportPermission = isEligibleAction(MONTHLYREPORT_PERMISSION_NAME)

  return (
    <Fragment>
      <Grid item={true}>
        <Box>
          <Grid
            container={true}
            columnSpacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "200px", paddingLeft: "10px" }}>
              <Tooltip title={t('common.Date Picker')} placement="bottom-end">
              <div>
                <DatePicker
                  disableFuture
                  views={['year', 'month']}
                  value={month}
                  onChange={(newValue: any) => {
                    setMonth(newValue)
                    if (props.setFilterParams) {
                      props.setFilterParams({...props?.filterParams, ...{month: Util.datetime2string(newValue, "yyyy-MM")}})
                    }
                    handleRefresh()
                  }}
                  slotProps={{
                    textField: {
                      size: "small"
                    }
                  }}
                />
              </div>
              </Tooltip>
            </Box>
          </Grid>
        </Box>
      </Grid>
      {hasExportPermission && <Grid item={true}>
        <ExportAction setExportFormat={(fileType: string) => { props.setFilterParams({ ...props?.filterParams, keyword: searchKeyword}); props.setExportFormat(fileType); }}  />
      </Grid>
      }
    </Fragment>
  )
}

const MonthlyReport: React.FC = () => {
  const {t} = useTranslation()
  const [filterParams, setFilterParams] = useState<Record<any, any>>({});
  const [exportFormat, setExportFormat] = useState<"xlsx" | "csv" | null>(null);

  const initialColumns: GridColDef[] = [
    { headerName: t("common.Person ID"), field: 'employee_code', width: 120 },
    { headerName: t("common.Person Name"), field: 'employee_name', width: 160 },
    // {headerName: 'Department', field: 'department_name', width: 120},
    // {headerName: 'Position', field: 'designation_name', width: 120},
    { headerName: t("att.report.Timesheet Name") , field: 'timesheet_name', minWidth: 140 },
  ]

  const updateColumns = (start?: Moment, end?: Moment) => {
    return () => {
      const columnsCpy: GridColDef[] = [...initialColumns]
      const monthEnd = end || moment().endOf('month')
      const monthStart =  start || moment().startOf('month')
      for (let i = 0; i < monthEnd.date(); i++) {
        columnsCpy.push({
          minWidth: 80,
          headerName: monthStart.date().toString(),
          field: monthStart.format("YYYYMMDD"),
          flex: 0.1
        })
        monthStart.add(1, 'day')
      }
      console.log({ columnsCpy });
      return columnsCpy
    }
  }
  const [columns, setColumns] = useState<GridColDef[]>(updateColumns())

  const setFilter = (newValue: Record<any, any>) => {
    const { month } = newValue
    if (!month) {
      return
    }

    const firstDayOfMonth = moment(month, "YYYY/MM").startOf("month");
    const lastDayOfMonth = moment(month, "YYYY/MM").endOf("month");

    setColumns(updateColumns(firstDayOfMonth, lastDayOfMonth))
    setExportFormat(null)
    setFilterParams(newValue)
  }

  useEffect(() => {
    if (exportFormat) {
      const fields: Array<{name: string, alias?: string}> = []
      for (const column in columns) {
        fields.push({name: columns[column]["field"], alias: columns[column]["headerName"]})
      }
      const export_meta = {
        fields,
        title: "Monthly Report",
        file_type: exportFormat,
        share: []
      }
      const params: string[] = []
      for (const key in filterParams) {
        params.push(`${key}=${filterParams[key]}`)
      }
      MonthlyService.exportData(params.join("&"), export_meta
        ).then((response) => {
          console.log("lod", response, response.headers["content-type"])
        const blob = new Blob([response.data], {type: response.headers["content-type"], endings: "transparent"})
        const url = URL.createObjectURL(blob)
        const aLink = document.createElement("a")
        aLink.style.display = "none"
        aLink.href = url
        document.body.appendChild(aLink)
        aLink.click()
        URL.revokeObjectURL(url)
        document.body.removeChild(aLink)
      }).catch((error) => {
        if (error.response.status < 500) {
          // do something when status is 4xx.
        }
      })
      setExportFormat(null)
    }
  }, [exportFormat])

  return (
    <CustomDataGrid
      uri={ATTEndpoints.ATT_REPORT_MONTHLY_REPORT}
      columns={columns}
      checkboxSelection={false}
      requestParams={filterParams}
      noResultFound={t("att.report.No monthly report found")}
      getRowId={(row) => {
        return Math.random()
      }}
      toolbar={{
        title: t("att.report.Monthly Report"),
        authedAction: MONTHLYREPORT_PERMISSION_NAME,
        search: true,
        searchPlaceholder: t("att.report.Search by Person"),
        breadcrumbs: [
          { breadcrumbName: t("att.report.Report"), path: WebURL.ATT_REPORT_HOME },
          { breadcrumbName: t("att.report.Monthly Report"), path: WebURL.ATT_REPORT_MONTHLY_REPORT },
        ],
        children: <ExtraAction filterParams={filterParams} setFilterParams={setFilter} setExportFormat={setExportFormat} />
      }}
    />
  )
}
export default MonthlyReport;
