import React, { useContext, useRef, useState } from "react";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import CustomDataGrid, { DataGridRef } from "@/components/data-grid/CustomDataGrid";
import { ATTEndpoints } from "@/services/att/endpoints";
import { useNavigate } from "react-router-dom";
import WebURL from "@/urls";
import CalculateIcon from '@mui/icons-material/Calculate';

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Client from "@/services";

const ExtraToolBar = () => {
  return (
    <Grid item={true}>
      <Tooltip title={"Calculate"}>
        <IconButton id='CalculateIconButton' onClick={async () => {
          await Client().post(`${ATTEndpoints.ATT_CALCTASK}run_calculation/?delay=True`, {})
        }} sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
          <CalculateIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  )
}

const CalcTask: React.FC = () => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { field: 'calc_key', headerName: "calc_key", flex: 2 },
    { field: 'created_at', headerName: "calc_created", type: 'date' },
    { field: 'trigger', headerName: "trigger", flex: 0.5 },
  ]
  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <CustomDataGrid
        uri={ATTEndpoints.ATT_CALCTASK}
        authedAction={"SCHEDULE_MANAGE"}
        columns={columns}
        ref={dataGridRef}
        checkboxSelection={false}
        toolbar={{
          title: "Schedule",
          search: true,
          searchPlaceholder: "Search by calc_key",
          breadcrumbs: [
            { breadcrumbName: "CalcTask", path: WebURL.ATT_CALCTASK }
          ],
          children: <ExtraToolBar />
        }}
      />
    </Box>
  )
}
export default CalcTask
