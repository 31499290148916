import { Refresh } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { DataGridDispatchContext } from "../data-grid/DataGridContext"

interface RefreshProps {
  handleRefresh?: () => void
}

const RefreshAction = (props: RefreshProps) => {
  const {t} = useTranslation()
  const dispatch = useContext(DataGridDispatchContext)

  const handleRefresh = () => {
    props.handleRefresh && props?.handleRefresh()
    dispatch({type: "refreshed"})
  }

  return (
    <Tooltip title={t("att.report.Refresh")}>
      <IconButton id='RefreshIconButton' onClick={handleRefresh} sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
        <Refresh />
      </IconButton>
    </Tooltip>
  )
}

export default RefreshAction
