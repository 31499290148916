import React, {Dispatch, SetStateAction, useState} from "react";
import MainLayout from "@/pages/auth/Login/components/MainLayout";
import {ReactComponent as LayoutImage} from "@/assets/images/UserLogin.svg"
import {
  Button,
  Grid, Paper, styled,
  Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {object, TypeOf, z} from "zod";
import WebURL from "@/urls";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useSnackbar} from "notistack";
import UserService from "@/services/auth/UserService";

const signUpSchema = object({
})
type signUpInput = TypeOf<typeof signUpSchema>;

type PolicyProps = {
}

const KnowAboutThisPolicy: React.FC<PolicyProps> = (props) => {
  const native = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const {register, handleSubmit, formState: {errors}} = useForm<signUpInput>({
    resolver: zodResolver(signUpSchema)
  })

  return (
    <Paper elevation={0} sx={{margin: '40px 0'}}>
      <Grid container={true} rowSpacing={3} component={"form"} noValidate sx={{p: "0 16px"}}>
        <Grid item sx={{display: "inline-flex", flexDirection: "column", marginLeft: -1}}  rowSpacing={3}>
          <Typography variant="h6" sx={{'p': '10px 20px'}}>{"1. What is one subscription of application ?"}</Typography>
          <Typography sx={{ marginTop: "-10px", marginLeft: "20px"}}>{"Subscription services work by giving you access to services in exchange for a certain amount of money paid on a regular basis. When you regularly pay a certain amount for a subscription service, you can access that service. You will get the paid features in this app as part of your feature package, which varies depending on the subscription plan. You can be able to select either an annual or monthly payment plan for the term of use."}</Typography>
          <Typography variant="h6" sx={{'p': '30px 20px'}}>{"2. What is the application's feature package?"}</Typography>
          <Typography sx={{ marginTop: "-30px", marginLeft: "20px"}}>{"We have developed feature package with various functions in response to the requirements of various users. You can benefit from a more efficient and comprehensive application experience through each feature package function. Depending on your needs and preferences, you can choose from a variety of feature package on the feature package comparison that will provide you with different monthly or yearly functions. However, if you misuse our resources, we are not responsible for the risks or consequences that come with it."}</Typography>
          <Typography variant="h6" sx={{'p': '30px 20px'}}>{"3. How about the payment ?"}</Typography>
          <Typography sx={{ marginTop: "-30px", marginLeft: "20px"}}>{"We provide several widely used payment options, including Alipay, PayPal, TrueMoney, etc. Please select your preferred mode of payment. We will not support refunds since the service plan is an electronic transaction and will go into effect immediately once you finish the payment; because of this, please be aware and decide on your feature package carefully."}</Typography>
          <Typography variant="h6" sx={{'p': '30px 20px'}}>{"4. How about the duration of validity?"}</Typography>
          <Typography sx={{ marginTop: "-30px", marginLeft: "20px"}}>{"As soon as your successful transaction is complete, you can start using the feature package features. Depending on whether you choose to pay a monthly price or an annual price, the feature package will be valid for one month or one year. You can use the features included in the feature package throughout the validity period, which will be calculated as of the day of your purchase."}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default KnowAboutThisPolicy
