const API_VER = 'v1.0'
export const AuthEndpoints = {
    AUTH_USER_LOGIN: `/oauth2/api/${API_VER}/token`,
    AUTH_USER_URI: `/auth/api/${API_VER}/users/`,
    AUTH_USER_SIGN_UP: `/auth/api/${API_VER}/users/sign_up/`,
    AUTH_USER_INFO_URI: `/auth/api/${API_VER}/users/info/`,
    AUTH_USER_COMPANY: `/auth/api/${API_VER}/companies/`,
    AUTH_USER_COMPANY_INFO_URI: `/auth/api/${API_VER}/companies/info/`,
    AUTH_USER_SELECT_COMPANY: `/auth/api/${API_VER}/companies/switch_company/`,
    AUTH_USER_SUBSCRIPTION: `/auth/api/${API_VER}/subscription/`,

    AUTH_ROLE_URI: `/auth/api/${API_VER}/roles/`,
    AUTH_ROLE_USERS_URI: `/auth/api/${API_VER}/roles/user_list/`,
    AUTH_GET_ROLE_USERS_URI: `/auth/api/${API_VER}/roles/get_role_user/`,
    AUTH_PERMISSION_URI: `/auth/api/${API_VER}/permissions/`,
}
