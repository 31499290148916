import {parse, format} from "date-fns";

const time2datetime = (time: string) => {
  return parse(time, "HH:mm", new Date());
}
const datetime2timeString = (datetime: Date|null) => {
  if(!datetime){
    return ""
  }
  return format(datetime, "HH:mm").toString();
}
const datetime2string = (datetime: Date|null, stringFormat='yyyy-MM-dd HH:mm:ss') => {
  if(!datetime){
    return ""
  }
  return format(datetime, stringFormat).toString();
}

const getCurrentMonthOfStartAndEndDay = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return [format(firstDay, 'yyyy-MM-dd'), format(lastDay, 'yyyy-MM-dd')]
}

const getCurrentMonthOfStartAndEndDayObj = () : Date[] =>  {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return [firstDay, lastDay]
}

const convertDateToString = (data: Date) : string => {
  return format(data, 'yyyy-MM-dd')
}

const Util = {
  time2datetime,
  datetime2timeString,
  datetime2string,
  getCurrentMonthOfStartAndEndDay,
  getCurrentMonthOfStartAndEndDayObj,
  convertDateToString
}
export default Util;
