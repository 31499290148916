import React from "react";
import WebURL from "../../urls";
import { Person } from "@mui/icons-material";
import EngineeringIcon from '@mui/icons-material/Engineering';
import {useTranslation} from "react-i18next";
import EligibleMenu from "@/pages/index"

const OrganizationHome: React.FC = () => {
  const {t} = useTranslation()
  const items = [
    {name: "person_list", title: t("common.Person"), icon: Person, path: WebURL.HR_EMPLOYEE},
    {name: "role", title: t("role.Role and permission"), icon: EngineeringIcon, path: WebURL.AUTH_ROLE},
  ]
  return (
    <EligibleMenu title={t("common.sidebar.Organization")} menus={items}/>
  )
}
export default OrganizationHome
