import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom"
import {Divider, Grid, Paper, Tooltip, Typography} from "@mui/material";
import GenericService from "../../../../services/GenericService";
import {HREndpoints} from "../../../../services/hr/endpoints";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import AddressService from "@/services/location/AddressService";
import {useTranslation} from "react-i18next";
import LocationText from "@/components/form/LocationText";
import { ReactComponent as DefaultFemaleProfile } from '@/assets/images/defaultFemalePersonPic.svg';
import { ReactComponent as DefaultOtherProfile } from '@/assets/images/defaultPersonProfile.svg';
import { ReactComponent as DefaultMaleProfile } from '@/assets/images/MaleProfile.svg';


const showEmployeeInfo = ( employee  : HR.Employee | undefined, name : string) => {
  if(name=== "firstName") {
    return employee?.firstName !== undefined ? ( (employee?.firstName.length > 0) ? employee?.firstName : '-' ) : ('-')
  } else if (name=== "lastName") {
    return employee?.lastName !== undefined ? ( (employee?.lastName.length > 0) ? employee?.lastName : '-' ) : ('-')
  }else if (name=== "employeeCode") {
    return employee?.employeeCode !== undefined ? ( (employee?.employeeCode.length > 0) ? employee?.employeeCode : '-' ) : ('-')
  }else if (name=== "email") {
    return employee?.email !== undefined ? ( (employee?.email.length > 0) ? employee?.email : '-' ) : ('-')
  }else if (name=== "phone") {
    return employee?.phone !== undefined ? ( (employee?.phone.length > 0) ? employee?.phone : '-' ) : ('-')
  }else if (name=== "department_name") {
    return employee?.department_name !== undefined ? ( (employee?.department_name.length > 0) ? employee?.department_name : '-' ) : ('-')
  }else if (name=== "designation_name") {
    return employee?.designation_name !== undefined ? ( (employee?.designation_name.length > 0) ? employee?.designation_name : '-' ) : ('-')
  }else if (name=== "joinDate") {
    return employee?.joinDate !== undefined ? ( (employee?.joinDate.length > 0) ? employee?.joinDate : '-' ) : ('-')
  }else if (name=== "dateOfBirth") {
    return employee?.dateOfBirth !== undefined ? ( (employee?.dateOfBirth.length > 0) ? employee?.dateOfBirth : '-' ) : ('-')
  }
}

const showEmployeeAddress = ( address  : LOCATION.Address | undefined, name : string) => {
  if(name=== "country") {
    return address?.country !== undefined ? ( (address?.country.length > 0) ? address?.country : '-' ) : ('-')
  } else if (name=== "state") {
    return address?.state !== undefined ? ( (address?.state.length > 0) ? address?.state : '-' ) : ('-')
  }else if (name=== "city") {
    return address?.city !== undefined ? ( (address?.city.length > 0) ? address?.city : '-' ) : ('-')
  }else if (name=== "addressLine1") {
    return address?.addressLine1 !== undefined ? ( (address?.addressLine1.length > 0) ? address?.addressLine1 : '-' ) : ('-')
  }else if (name=== "addressLine2") {
    return address?.addressLine2 !== undefined ? ( (address?.addressLine2.length > 0) ? address?.addressLine2 : '-' ) : ('-')
  }else if (name=== "areaCode") {
    return address?.areaCode !== undefined ? ( (address?.areaCode.length > 0) ? address?.areaCode : '-' ) : ('-')
  }
}

type GridItemProps = {
  label: string,
  value: any,
}

const GridItem = (props: GridItemProps) => {
  return (
    <Grid display='flex' paddingBottom={2}>
      <Grid sx={{ paddingRight: 1, minWidth: "8vw", color: "gray"}}>
        <Tooltip title={props.label}>
          <Typography>{props.label}</Typography>
        </Tooltip>
      </Grid>
      <Grid>
        <Tooltip title={props.value}>
          <Typography sx={{ width: "12vw", overflow: "hidden" }}>{props.value}</Typography>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

const DisplayPersonProfileDefault = ({url, gender}:{url: string | undefined, gender: string | undefined}) => {
  if(url === undefined) {
    if(gender=== "Male") {
      return (
        <DefaultMaleProfile height={"178px"} width={"178px"} />
      )
    } else if(gender=== "Female") {
      return (
      <DefaultFemaleProfile height={"178px"} width={"178px"} />
      )
    } else {
      return (
      <DefaultOtherProfile height={"178px"} width={"178px"} />
      )
    }
  } else {
    return (
    <DefaultOtherProfile height={"178px"} width={"178px"} />
    )
  }
}

const Gender = new Map([['M', 'Male'], ['F', 'Female'], ['O', 'Others']]);

const EmployeeView: React.FC = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const state = location.state as { id: string }
  const [employee, setEmployee] = useState<HR.Employee>();
  const [address, setAddress] = useState<LOCATION.Address>();
  useEffect(() => {
    GenericService.retrieve(HREndpoints.HR_EMPLOYEE_URI, state.id || "").then((res) => {
      setEmployee(res.data);
    });
    AddressService.list({keyword: state.id, current: 1, pageSize: 10}).then((address) => {
      const {data} = address.data;
      if (data.length > 0) {
        setAddress(data[0]);
      }
    });
  }, [])
  return (
    <>
      <CustomBreadcrumbs title={t("common.View person profile")} />
      <Paper elevation={0} style={{ minHeight: '500px', backgroundColor: '#fff', borderRadius: '8px', padding: '20px' }}>
      <Grid item >
        {employee?.profilePhotoURL === undefined ? (
          <DisplayPersonProfileDefault gender={employee?.gender && Gender.get(employee.gender)} url={employee?.profilePhotoURL}/>
        ) :
        (
          <AvatarIcon name={employee?.firstName || '-'} diameter={200} profilePhoto={employee?.profilePhotoURL} />
        )}
      </Grid>
      <Grid direction='row' alignItems={'flex-start'} id={"site-view-grid"} sx={{ paddingTop: "15px" }}>
        <LocationText title={t("common.First name")} content={showEmployeeInfo(employee, "firstName")}/>
        <LocationText title={t("common.Last name")} content={showEmployeeInfo(employee, "lastName")}/>
        <LocationText title={t("common.Person ID")} content={showEmployeeInfo(employee, "employeeCode")}/>
        <LocationText title={t("signUp.Email")} content={showEmployeeInfo(employee, "email")}/>
        <LocationText title={t("common.Mobile")} content={showEmployeeInfo(employee, "phone")}/>
        <LocationText title={t("common.Department")} content={showEmployeeInfo(employee, "department_name")}/>
        <LocationText title={t("common.Job Title")} content={showEmployeeInfo(employee, "designation_name") }/>
        <LocationText title={t("common.Date of joining")} content={showEmployeeInfo(employee, "joinDate")}/>
        <LocationText title={t("common.Date of birth")} content={showEmployeeInfo(employee, "dateOfBirth") }/>
        <LocationText title={t("common.Gender")} content={ (employee?.gender && Gender.get(employee.gender)) ?? '-' }/>
        <LocationText title={t("common.Country")} content={showEmployeeAddress(address, "country")}/>
        <LocationText title={t("common.Province state")} content={showEmployeeAddress(address, "state") }/>
        <LocationText title={t("common.City")} content={showEmployeeAddress(address, "city") }/>
        <LocationText title={t("common.Address line 1")} content={showEmployeeAddress(address, "addressLine1")}/>
        <LocationText title={t("common.Address line 2")} content={showEmployeeAddress(address, "addressLine2")}/>
        <LocationText title={t("common.Post code")} content={showEmployeeAddress(address, "areaCode")}/>
      </Grid>
        {/* <Grid container spacing={2} wrap="wrap">
          <Grid xs={6} sm={12} md={4} lg={2} xl={2} item marginRight={"1vw"}>
            <AvatarIcon name={employee?.firstName || '-'} diameter={200} profilePhoto={employee?.profilePhotoURL} />
          </Grid>
          <Grid xs={6} sm={4} md={4} lg={3} xl={3} item>
            <GridItem label={t("common.First name")} value={employee?.firstName} />
            <GridItem label={t("common.Last name")} value={employee?.lastName} />
            <GridItem label={t("common.Person ID")} value={employee?.employeeCode} />
            <GridItem label={t("signUp.Email")} value={employee?.email} />
            <GridItem label={t("common.Mobile")} value={employee?.phone} />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid xs={6} sm={4} md={4} lg={3} xl={3} item>
            <GridItem label={t("common.Department")} value={employee?.department_name} />
            <GridItem label={t("common.Job Title")} value={employee?.designation_name} />
            <GridItem label={t("common.Date of joining")} value={employee?.joinDate} />
            <GridItem label={t("common.Date of birth")} value={employee?.dateOfBirth} />
            <GridItem label={t("common.Gender")} value={employee?.gender && Gender.get(employee.gender)} />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid xs={6} sm={12} md={12} lg={3} xl={3} item>
            <GridItem label={t("common.Country")} value={address?.country} />
            <GridItem label={t("common.Province state")} value={address?.state} />
            <GridItem label={t("common.City")} value={address?.city} />
            <GridItem label={t("common.Address line 1")} value={address?.addressLine1} />
            <GridItem label={t("common.Address line 2")} value={address?.addressLine2} />
            <GridItem label={t("common.Post code")} value={address?.areaCode} />
          </Grid>
        </Grid> */}
      </Paper>
    </>
  )
}
export default EmployeeView;
