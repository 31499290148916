import { AxiosError } from "axios";
import { BaseObserver } from "../observer";
import responseCodeAlert from "@/utils/codes";
import APITRANS from "@/utils/codes/apitrans";


export class AxiosSnackbarInterceptor extends BaseObserver {
  id = "snackbar-interceptor";

  doError(err: AxiosError): AxiosError {
    console.log("reuquest error:", err);
    if (err.response) {
      const data = err.response.data as any;
      if (typeof data === "string"){ // data is not obj
        responseCodeAlert(err)
      }
      if(typeof data === 'object' && typeof data['message'] === 'object')
      {
        const i18nextLng: any = window.localStorage.getItem('i18nextLng') ? window.localStorage.getItem('i18nextLng') :  "en";
        const newError = {code: data['code'], message: data['message'][i18nextLng]}
        responseCodeAlert(newError)
      } else {
        if(APITRANS[data["message"]]) {
          const i18nextLng: any = window.localStorage.getItem('i18nextLng') ? window.localStorage.getItem('i18nextLng') :  "en";
          const newError = {code: data['code'], message: APITRANS[data["message"]][i18nextLng]}
          responseCodeAlert(newError)
        } else {
          responseCodeAlert(data)
        }
      }

    }else{ // error without response   e.g. timeout
      responseCodeAlert(err)
    }
    return err;
  }
}
