import React from "react";
import {Avatar, Box, Grid} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import CustomDataGrid from "../../../../components/data-grid/CustomDataGrid";
import {AuthEndpoints} from "../../../../services/auth/endpoints";
import WebURL from "../../../../urls";


const OrderReport: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: 'employee_name', headerName: "Order No.", minWidth: 180, flex: 0.2},
    {field: 'employee_code', headerName: "Feature trial", type: "string", minWidth: 120, flex: 0.2},
    {field: 'att_date', headerName: "Subscription period", type: "string", minWidth: 180, flex: 0.2},
    {field: 'timesheet_name', headerName: "Who place order", type: "string", minWidth: 120, flex: 0.2},
    {field: 'first_in', headerName: "Subscription Place", type: "string", minWidth: 180, flex: 0.2},
    {field: 'last_out', headerName: "Order Create Time", type: "string", minWidth: 180, flex: 0.2},
    {field: 'start_time', headerName: "Service start Time", type: "string", minWidth: 180, flex: 0.2},
    {field: 'end_time', headerName: "Service end Time", type: "string", minWidth: 180, flex: 0.2},
    {field: 'status', headerName: "Status", type: "string", minWidth: 180, flex: 0.2},
  ]
  return (
    <Box sx={{height: 600, width: '100%'}}>
      <CustomDataGrid
        uri={AuthEndpoints.AUTH_USER_SUBSCRIPTION}
        columns={columns}
        checkboxSelection={false}
        getRowId={(row) => {return Math.random()}}
        toolbar={{
          title: "Subscription Order",
          breadcrumbs: [
            {breadcrumbName: "Subscription", path: WebURL.SUBSCRIPTION_HOME},
            {breadcrumbName: "Subscription Order", path: WebURL.SUBSCRIPTION_ORDER},
          ]
        }}
      />
    </Box>
  )
}
export default OrderReport;
