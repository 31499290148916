import Client from '..';
import { LocationEndpoints } from './endpoints';


const update = async (id: string, params: Record<string, any>) => {
    const response = await Client().put(`${LocationEndpoints.SITE_URI}${id}/`, params);
    return response.data;
}

const SiteService = {
    update
}

export default SiteService;
