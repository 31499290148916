
import React, { useState } from 'react';
import { Cancel } from '@mui/icons-material';
import { ReactComponent as Search } from '@/assets/icons/SearchIcon.svg';
import { Box, TextField, Tooltip } from '@mui/material';
import { SearchBarProps } from './Sheet.Types';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTranslation} from "react-i18next";

const SearchBarGrid: React.FC<SearchBarProps> = (props) => {
  const {t} = useTranslation();
  const smallScreen = useMediaQuery('(max-width:750px)');
  const [expand, setExpand] = useState(false)
  const ValidationTextField = {
    "& .MuiInputBase-input": {
      height: { xl: "23px", xs: "23px" },
    },
    "& input::placeholder": {
      fontSize: "13px"
    },
  };

  const style = {
    width: !expand ? "46px" : !smallScreen ? '250px'  : '90%',
    transition: "0.5s",
    letiant: "standard"
  } as const;

  return (
    <>
      <Box sx={ style }>
        <TextField size='small' autoComplete='search-grid' fullWidth id={"search-bar-grid"}
          classes={{}}
          placeholder={props.placeholder}
          onMouseEnter={() => { if (props.value == "") setExpand(true) }}
          onMouseLeave={() => { if (props.value == "") setExpand(false) }}
          disabled={!expand}
          onBlur={() => { if (props.value == "") setExpand(false) }}
          sx={{ ...ValidationTextField }}
          InputProps={{
            endAdornment: (
              <>
                {props.value ? (
                  <Box id='close-icon' style={{ cursor: "pointer" }}>
                    <Tooltip title={t("common.Clear")}>
                    <Cancel
                      style={{ color: "rgba(71, 75, 79, 0.5)", marginTop: "8px" }}
                      onClick={() => props.clearSearch()}
                    />
                    </Tooltip>
                  </Box>
                ) : (
                  <Box id='search-icon' style={{ cursor: "pointer" }}>
                    <Tooltip title={t("common.Search")}>
                    <Search style={{ cursor: "pointer", marginTop: "6px" }} onClick={() => { if (props.value == "") setExpand(true) }} />
                    </Tooltip>
                  </Box>
                )}
              </>
            ),
          }}
          value={props.value} helperText={props.helperText} onChange={props.handleChange} />
      </Box>
    </>
  );
}

export default SearchBarGrid;
