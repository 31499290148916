import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DepartmentListLeftProps } from "./Sheet.Types";

const DepartmentListLeft: React.FC<DepartmentListLeftProps> = (props: DepartmentListLeftProps) => {
  const { t } = useTranslation();
  const ValidationTextField = {
    "& input::placeholder": {
      fontSize: "13px"
    },
  };

  const style = { width: '100%' } as const
  return (
    <Autocomplete disablePortal id="combo-box-demo"
      value={props.leftDepartmentValue.id.length > 1 ? props.leftDepartmentValue.name : null}
      options={
        props.departments.length > 0
          ? props.departments.map(option => option.name)
          : []
      }
      sx={ style }
      renderInput={(params) =>
        <TextField {...params}
          placeholder={t("common.Select Department")} size='small'
          sx={{ ...ValidationTextField }} />
      }
      onChange={(event, newValue) => {
        if(newValue !== null){
          props.handleDepartmentLeft(newValue)
        } else {
          props.handleDepartmentLeft("")
        }
      }}
    />
  );
};

export default DepartmentListLeft;
