import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { Box, styled } from "@mui/system";
import { grey } from '@mui/material/colors';
import {Avatar, Button, Divider, Grid, Paper, Stack, Tooltip, Typography} from "@mui/material";
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { DMSEndpoints } from "@/services/dms/endpoints";
import GenericService from "../../../../services/GenericService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {timezones} from "@/components/resource/timezone";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { useTranslation } from "react-i18next";
import LocationText from "@/components/form/LocationText";

type FlexBoxItemProps = {
  title: string,
  value: any
  tip?: string,
  edit?: boolean
}

type ReadOrEditInputProps = {
  value: any
  edit?: boolean
}

const StyledInputElement = styled('input')(
  ({ theme }) => `
  font-size: 16px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 8px;
  padding: 6px 6px;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? grey[600] : grey[100]};
  }
`,
);

const EditInput: React.FC<ReadOrEditInputProps> = (props) => {
  const [val, setVal] = React.useState('');
  useEffect(() => {
    setVal(props.value)
  }, [props])
  return (
    <StyledInputElement
      value={val}
      onChange={(event) => setVal(event.target.value)}
    />
  )
}

const ReadOrEditInput: React.FC<ReadOrEditInputProps> = (props) => {
  if (props.edit && true)
    return (<EditInput {...props} />)
  else
    return (<Typography>{props.value}</Typography>)
}

const FlexBoxItem: React.FC<FlexBoxItemProps> = (props) => {
  const field = {
    paddingRight: 1,
    minWidth: '150px',
    color: 'gray'
  }
  return (
    <Box display='flex' paddingBottom={2}>
      <Box {...field}>
        <Tooltip title={props.tip || props.title}>
          <Typography>{props.title}</Typography>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title={`${props.value}`}>
          <ReadOrEditInput value={props.value} edit={props.edit} />
        </Tooltip>
      </Box>
    </Box>
  )
}

const DeviceView: React.FC = () => {
  const location = useLocation()
  const state = location.state as { id: string }
  const {t} = useTranslation();
  const timezoneList = timezones();
  const [device, setDevice] = useState<DMS.DeviceRetrieve>();
  useEffect(() => {
    GenericService.retrieve(DMSEndpoints.DMS_DEVICE_URI, state.id || "").then((res) => {
      setDevice(res.data)
    })
  }, [])
  return (
    <>
      {/* <CustomBreadcrumbs title={t("dms.breadcrumbDeviceView")} />
      <Paper elevation={0} style={{ minHeight: '200px', backgroundColor: '#fff', borderRadius: '8px', paddingTop: '20px' }}>
        <Grid container direction='row' justifyContent='space-evenly' alignItems='flex-start'>
          <Grid lg={2} item>
            <Avatar sx={{ width: 200, height: 200, bgcolor: grey[100] }}>
              <AdUnitsIcon sx={{ fontSize: 150 }} color="action" />
            </Avatar>
          </Grid>
          <Grid lg={4} item>
            <FlexBoxItem title={t('dms.Serial Number')} value={device?.sn} tip={t('dms.Serial Number')} />
            <FlexBoxItem title={t('dms.Device Name')} value={device?.alias} tip={t('dms.Device Name')} edit={false} />
            <FlexBoxItem title={t('dms.Site Name')} value={device?.site_name} />
            <FlexBoxItem title={t('dms.Zone Name')} value={device?.zone_name} />
            <FlexBoxItem title={t('dms.Site Time Zone')} value={timezoneList.find((option:any) => option.value === device?.timezone)?.name|| `UTC${device?.timezone}`} />
            <FlexBoxItem title={t("dms.IP Address")} value={device?.parameters?.ip} />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid lg={5} item>
            <FlexBoxItem title={t('dms.Device Model')} value={device?.model} />
            <FlexBoxItem title={t('dms.Device Type')} value={device?.category} />
            <FlexBoxItem title={t('dms.Protocol Type')} value={device?.protocol_type} />
            <FlexBoxItem title={t("dms.Firmware Ver.")} value={device?.firmware_ver} tip={t("dms.Firmware Ver.")}/>
          </Grid>
        </Grid>
      </Paper> */}
      <Box>
        <CustomBreadcrumbs title={t("dms.breadcrumbDeviceView")} />
        <Paper elevation={0} style={{ minHeight: '400px', backgroundColor: '#fff', borderRadius: '8px', padding: '20px' }}>
          <Grid item >
            <Avatar sx={{ width: 200, height: 200, bgcolor: grey[100] }}>
              <AdUnitsIcon sx={{ fontSize: 150 }} color="action" />
            </Avatar>
          </Grid>
          <Grid direction='row' alignItems={'flex-start'} id={"site-view-grid"} sx={{ paddingTop: "15px" }}>
            <LocationText title={t("dms.Serial Number")} content={device?.sn ?? '-' }/>
            <LocationText title={t("dms.Device Name")} content={device?.alias ?? '-' }/>
            <LocationText title={t("dms.Device Model")} content={device?.model ?? '-' }/>
            <LocationText title={t("dms.Device Type")} content={device?.category ?? '-' }/>
            <LocationText title={t("dms.Protocol Type")} content={device?.protocol_type ?? '-' }/>
            <LocationText title={t("dms.Firmware Ver.")} content={device?.firmware_ver ?? '-' }/>
            <LocationText title={t("dms.IP Address")} content={device?.parameters?.ip ?? '-' }/>
            <LocationText title={t("dms.Site Time Zone")} content={ timezoneList.find((option:any) => option.value === device?.timezone)?.name|| `UTC${device?.timezone}` }/>
            <LocationText title={t("dms.Site Name")} content={device?.site_name ?? '-' }/>
            <LocationText title={t("dms.Zone Name")} content={device?.zone_name ?? '-' }/>
          </Grid>
        </Paper>
      </Box>
    </>
  )
}
export default DeviceView;
