import {Translation} from "react-i18next";

const Translate = (key : string) => {
  return (
    <Translation>
      { t => t(key)}
    </Translation>
  )
}
const TranslationUtil = {
  t: Translate
}
export default TranslationUtil