import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {FormGroup, FormControlLabel, Grid, Paper, Tooltip, Typography} from "@mui/material";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import {Box} from "@mui/system";
import GenericService from "@/services/GenericService";
import {ATTEndpoints} from "@/services/att/endpoints";
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {useTranslation} from "react-i18next";

const theme = {
  field: {
    paddingRight: 1,
    minWidth: '150px',
    color: 'gray'
  }
};

type Employee = {
  clocking_type: {
    device_verification: boolean,
    [x:string]: boolean
  }
} & HR.Employee

const ViewCollectionRule: React.FC = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const state = location.state as { id: string }
    const navigate = useNavigate();
    const [employee, setEmployee] = useState<Employee>();
    useEffect(() => {
      GenericService.retrieve(ATTEndpoints.ATT_COLLECTION_RULE, state.id || "").then((res) => {
        res.data.clocking_type = {device_verification: false, ...res.data.clocking_type};
        setEmployee(res.data);
      })
    }, [])
    return (
        <>
          <CustomBreadcrumbs title={"View person clocking type"} />
          <Paper elevation={0} sx={{minHeight: '200px', backgroundColor: '#fff', borderRadius: '8px', paddingTop: '16px'}}>
            <Grid container direction='row' justifyContent='space-evenly' alignItems='flex-start'>
              <Grid lg={2} item>
                <AvatarIcon name={employee?.firstName || '-'} diameter={200} />
              </Grid>
              <Grid lg={9} item>
                <Box display='flex' paddingBottom={2}>
                  <Box {...theme.field}>
                    <Tooltip title={'First Name'}>
                      <Typography>{t("common.First name")}</Typography>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title={`${employee?.firstName}`}>
                      <Typography>{employee?.firstName}</Typography>
                    </Tooltip>
                  </Box>
                </Box>
                <Box display='flex' paddingBottom={2}>
                  <Box {...theme.field}>
                    <Tooltip title={t("common.Last name")}>
                      <Typography>Last Name</Typography>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title={`${employee?.lastName}`}>
                      <Typography>{employee?.lastName}</Typography>
                    </Tooltip>
                  </Box>
                </Box>
                <Box display='flex' paddingBottom={2}>
                  <Box {...theme.field}>
                    <Tooltip title={'Person ID'}>
                      <Typography>Person ID</Typography>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title={`${employee?.employeeCode}`}>
                      <Typography>{employee?.employeeCode}</Typography>
                    </Tooltip>
                  </Box>
                </Box>
                <Box display='flex' paddingBottom={2}>
                  <Box {...theme.field}>
                    <Tooltip title={'Department'}>
                      <Typography>Department</Typography>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title={`${employee?.department_name}`}>
                      <Typography>{employee?.department_name}</Typography>
                    </Tooltip>
                  </Box>
                </Box>
                <Box display='flex' paddingBottom={2}>
                  <Box {...theme.field}>
                    <Tooltip title={'Job title'}>
                      <Typography>Job title</Typography>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title={`${employee?.designation_name}`}>
                      <Typography>{employee?.designation_name}</Typography>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          <Box alignItems='center' paddingTop={3} paddingBottom={2}>
            <Typography fontSize={'1rem'} fontWeight={'bold'} gutterBottom component={'div'}>
              Clocking type
            </Typography>
          </Box>
          <Paper elevation={0}>
            <FormControl sx={{minHeight: '100px'}} component='fieldset'>
              <FormGroup row>
                {Object.keys(employee?.clocking_type || {}).map((v, i)=>{
                  return (
                    <FormControlLabel sx={{margin: '5px'}} key={i}
                      control={
                        <Checkbox
                          defaultChecked={employee?.clocking_type[v] || false}
                          disabled
                          // onClick={(event: React.MouseEvent<HTMLElement>) => {
                          //   const el = event.target as HTMLInputElement;
                          //   el.checked = !el.checked;
                          // }}
                          // onChange= {event => {
                          //   const data = {employee_id: employee?.id || "", clocking_type: {...employee?.clocking_type}}
                          //   data['clocking_type'][v] = event.target.checked;
                          //   GenericService.create(ATTEndpoints.ATT_COLLECTION_RULE, data);
                          // }}
                        />
                      }
                      label="Device Verification"
                      labelPlacement='end'
                    />
                  )
                })}
              </FormGroup>
            </FormControl>
          </Paper>
        </>
    )
}

export default ViewCollectionRule;
