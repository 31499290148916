import React, { ReactNode, useContext } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import {useTranslation} from "react-i18next";
import {isEligibleAction} from "@/utils/permissions/FilterUnauthed"
import { DataGridContext, DataGridDispatchContext } from "./DataGridContext";
import RefreshAction from "../actions/Refresh";
import FilterAction from "../actions/Filter";
import SearchBarGrid from "@/pages/att/Schedule/components/NewSchedule/SearchBarGrid";

type ActionProps = {
  key: string;
  icon: SvgIconComponent;
  helpTxt?: string;
  authedAction?: string;
  callback: () => Promise<void>;
}
type Breadcrumb = {
  breadcrumbName: string;
  path: string
}
type Upload = {
  setUpload?: any;
}

export type ToolBarProps = {
  title: string;
  authedAction?: string;
  total?: number;
  info?: boolean;
  upload?: Upload;
  refresh?: boolean | ReactNode;

  search?: boolean;
  searchPlaceholder?: string;

  filter?: boolean | ReactNode;
  actions?: ActionProps[];
  breadcrumbs?: Breadcrumb[];

  children?: ReactNode;
  belowChildren?: ReactNode;
}

const CustomToolBarSearchCollapse: React.FC<ToolBarProps> = (props) => {
  const {t} = useTranslation();
  let showTotal = false;
  if (props.total) {
    showTotal = true;
  }
  const navigate = useNavigate();

  const dispatch = useContext(DataGridDispatchContext)
  const {searchKeyword} = useContext(DataGridContext)

  const handleRefresh = () => {
    dispatch({type: "refreshed"})
  }

  const handleSearch = (searchKeyword: string) => {
    dispatch({type: "searched", searchKeyword})
  }

  return (
    <>
      <Grid className={"toolbar-container"}>
        <Box sx={{ display: 'flex' }}>
          <Breadcrumbs separator="" sx={{ height: "32px", p: '8px 0' }}>
            {
              props.breadcrumbs?.map((item, index) => {
                const last = (index + 1) === props.breadcrumbs?.length;
                return (
                  last
                    ? <Typography key={index}>{item.breadcrumbName}</Typography>
                    : <IconButton id='BackIconButton' key={index} onClick={() => navigate(item.path)} sx={{ p: 0, padding: "5px" }}>
                        <ArrowBackIosNewIcon sx={{ display: "flex" }} fontSize="small" />
                      </IconButton>
                )
              })
            }
          </Breadcrumbs>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Grid
            container={true}
            columnSpacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            {props.search &&
            <Grid item={true}>
              <Grid
                container={true}
                columnSpacing={1}
                sx={{
                  alignItems: "center",
                }}
              >
                <Box sx={{ position: "absolute", right: 15, pl: { xl: "30px", xs: "20px" }, display: "flex", justifyContent: "end" }}>
                <SearchBarGrid
                  placeholder={props.searchPlaceholder ? props.searchPlaceholder : ''}
                  clearSearch={() => { handleSearch(""); handleRefresh()} }
                  onSearch={() => console.log()}
                  name={"search"}
                  resetError={() => console.log()}
                  helperText={""}
                  handleChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                    handleSearch(e.target.value)
                    handleRefresh()
                  }}
                  value={searchKeyword}
                />
                </Box>
              </Grid>
            </Grid>}
            {props.filter && <Grid item={true}>
              {typeof props.filter === "boolean" ? <FilterAction /> : props.filter}
            </Grid>}
            {/* TODO(lowell): 将输入型action和按钮型action区分开来, 后续可以考虑优化 */}
            {props.children}
            {props.upload && isEligibleAction(props?.authedAction) && <Grid item={true}>
              <Tooltip title={t("att.report.Upload")}>
                <IconButton id='UploadIconButton' sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }} onClick={()=>{
                  props.upload?.setUpload(true)
                }}>
                  <CloudUploadIcon />
                </IconButton>
              </Tooltip>
            </Grid>}
            {props.refresh && <Grid item={true}>
              {typeof props.refresh === "boolean" ? <RefreshAction /> : props.refresh}
            </Grid>}
            {props.actions?.map((action, index) => {
              if (!isEligibleAction(action?.authedAction)){ // 无权限则不创建新增对应按钮
                return (<></>)
              }
              return (
                <Grid key={action.key} item={true} onClick={action.callback}>
                  <Tooltip title={action.helpTxt || ""}>
                    <Box
                      id={`${action.key}Button`}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.primary.main,
                        padding: "8px 8px",
                        borderRadius: "6px",
                        display: "flex",
                        cursor: "pointer",
                      })}
                    >
                      <action.icon sx={{ color: "white" }} />
                    </Box>
                  </Tooltip>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Grid>
      {props.belowChildren}
    </>
  )
}
CustomToolBarSearchCollapse.defaultProps = {
  search: false,
  refresh: true,
  filter: false,
}
export default CustomToolBarSearchCollapse
