import React from "react";
import {Box, Grid} from "@mui/material";

type LocationProps = {
  title: string,
  content?: string
}

const LocationText = ({ title, content }: LocationProps) => {
  return(
    <Grid item lg={12} xs={12} id={"view-grid"}>
      <Box sx={{ color: "rgba(71, 75, 79, 0.5)",
          letterSpacing: "0.7px",
          font: "14px / 22px Roboto",
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingRight: "10px"
          }}>
        {title}:
      </Box>
      <Box sx={{ flexWrap: 'wrap', wordWrap: 'break-word', maxWidth: "100%",
          color: "rgb(71, 75, 79)",
          letterSpacing: "0.8px",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontSize: "16px",
          lineHeight: "22px",
          fontFamily: "Roboto",
          paddingBottom: "20px",
          }}>
        {content}
      </Box>
    </Grid>
  )
}
export default LocationText;
