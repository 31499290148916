import React, {useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ThemeProvider,
  useMediaQuery,
  useTheme
} from "@mui/material";
import theme from "../../theme";
import {I18nextProvider, useTranslation} from "react-i18next";
import i18n from "../../config/i18n";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import CustomTooltip from "@/components/breadcrumbs/CustomToolTip"

export type DialogFuncProps = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  helpInfo?: string;
  description?: React.ReactNode;
  content?: React.ReactNode;
  onConfirm?: () => any;
}

type ConfirmDialogProps = DialogFuncProps & {
  mode?: string;
  close: () => void;
  open: boolean
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({close, ...props}) => {
  const {
    icon,
    title,
    helpInfo,
    description,
    content,
    open,
    onConfirm
  } = props
  const currentTheme = useTheme();
  const {t} = useTranslation();
  const fullScreen = useMediaQuery(currentTheme.breakpoints.down('md'));
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Dialog
            open={open}
            fullScreen={fullScreen}
            className={"ZKConfirmDialog-container"}
          >
            <DialogTitle>
              <Box sx={{ padding: "5px" }} >{title}</Box>
                {helpInfo && <CustomTooltip helpInfo={helpInfo} anchor={'right'}/>}
            </DialogTitle>
            {(content || description) && <DialogContent sx={{lineHeight: '25px', color: 'gray'}}>
              <Box sx={{ padding: "5px" }} >
                {description}
                {content}
                </Box>
            </DialogContent>}
            <DialogActions >
                  <Box display={'flex'} justifyContent={"center"}>
                    <Button id="ConfirmButton" size='large' fullWidth variant='contained'
                      sx={{marginRight:'20px',textTransform:'none'}}
                      onClick={() => {
                        const hold = onConfirm?.();
                        if(!hold){
                          close();
                        }
                      }}
                      >{t("common.Confirm")}</Button>
                    <Button id= "CancelButton" size='large' fullWidth variant='outlined'
                      sx={{textTransform:'none'}} onClick={close}
                    >{t("common.Cancel")}</Button>
                  </Box>
           </DialogActions>
          </Dialog>
        </LocalizationProvider>
      </I18nextProvider>
    </ThemeProvider>
  )
}
export default ConfirmDialog
