import React, {ForwardedRef, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useReducer, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import GenericService from "../../services/GenericService";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {isEligibleAction} from "@/utils/permissions/FilterUnauthed"
import { DataGridContext, DataGridDispatchContext } from "./DataGridContext";
import { CustomDataGridProp, DataGridRef } from "./CustomDataGrid";
import lodash from "lodash";
import CustomToolBarSearchCollapse from "./CustomToolBarSearchCollapse";

export function calcTextWidth(text: any, fontSize: any = "0.875rem", fontFamily: any = "Roboto",
  fontWeight: any = "500", letterSpacing: any = "0.01071em",) {
  const span = document.createElement("span")
  let width = 0
  span.style.visibility = "hidden"
  span.style.fontSize = fontSize
  span.style.fontFamily = fontFamily
  span.style.fontWeight = fontWeight
  span.style.letterSpacing = letterSpacing
  span.style.display = "inline-block"
  document.body.appendChild(span)
  span.textContent = text
  width = parseInt(window.getComputedStyle(span).width)
  document.getElementsByTagName('body')[0].removeChild(span)
  return width
}

export function updateColumnsDefaultProps(sourceColumns: any) {
  for (const i in sourceColumns) {
    // if (!('sortable' in sourceColumns[i])) {
    //   sourceColumns[i].sortable = false
    // }
    if (!('flex' in sourceColumns[i])) {
      sourceColumns[i].flex = 1
    }
    if (!('minWidth' in sourceColumns[i])) {
      const headerLabel = sourceColumns[i].headerName ? sourceColumns[i].headerName : sourceColumns[i].field
      sourceColumns[i].minWidth = calcTextWidth(headerLabel) + 38
    }
  }
}

const customDataGridStyle = {
  '& .MuiDataGrid-columnHeaders': {backgroundColor: '#F0F1F4!important', color: '#304669!important'},
  '.MuiDataGrid-columnHeader:focus-within': {outline: 'none'},
  '.MuiDataGrid-cell': {borderBottom: '1px solid #E7EBF0', color: '#304669'},
  '.MuiDataGrid-cell:focus-within': {outline: 'none!important', color: '#36c96d!important'},
  '.MuiDataGrid-columnSeparator': {display: 'none !important'},
  '.MuiSvgIcon-root': {backgroundColor: 'transparent'},
  '.MuiDataGrid-root': {padding: '8px', borderRadius: '4px'},
  '.MuiDataGrid-row': {maxHeight: '48px', minHeight: '48px!important'},
  '.MuiCheckbox-root': {color: '#D1D1D1'},
  '.MuiDataGrid-cellContent': {
    color: '#304669!important',
    fontFamily: 'Roboto',
    letterSpacing: '0.4px',
    fontSize: '14px'
  },
  '.MuiDataGrid-virtualScrollerRenderZone': {backgroundColor: '#FFFF'},
  '.MuiDataGrid-footerContainer': {display: 'block', width: '100%', border: '0px'},
  '.MuiTablePagination-spacer': {display: 'none'},
  '.MuiTablePagination-actions': {position: 'absolute', right: '0px', backgroundColor: '#FFF'},
  '.MuiTablePagination-displayedRows': {position: 'absolute', right: '90px'},
  // '.MuiDataGrid-virtualScroller': {overflow: 'hidden'},
  '.MuiTablePagination-select': {backgroundColor: '#F0F1F4', borderRadius: '4px!important'},
  '.MuiTablePagination-toolbar': {paddingLeft: '0px'},
  '.MuiIconButton-root.Mui-disabled': {backgroundColor: '#F0F1F4', borderRadius: '0px'},
  // '.MuiIconButton-root': {borderRadius: '0px'},
  '.Mui-checked': {color: 'rgb(54, 201, 109)!important'},
};

type GridData = {
  data: any;
  total: number;
}

const OriginDataGridSearchCollapse = (props: CustomDataGridProp, ref: ForwardedRef<DataGridRef|undefined>) => {
  const {t} = useTranslation();
  const [gridData, setGridData] = useState<GridData>({data: [], total: 0});
  const [isLoading, setLoading] = useState<boolean>(false);

  const dispatch = useContext(DataGridDispatchContext)
  const task = useContext(DataGridContext)
  const {pageSize, page, searchKeyword, refresh} = task

  const handelRefresh = useCallback(
    lodash.throttle((resetPage = false) => {
      dispatch({type: "refreshed"})
    }, 2000, {
      leading: true,
      trailing: false
    }),
    []
  )

  const handleSearch = useCallback(
    lodash.throttle((searchKeyword: string) => {
      dispatch({type: "searched", searchKeyword})
    }, 2000, {
      leading: true,
      trailing: false
    }),
    [],
  )

  const handlePageSize = useCallback(
    lodash.throttle((pageSize: number) => {
      dispatch({type: "switching_pageSize", pageSize})
    }, 1000, {
      leading: true,
      trailing: false
    }),
    [],
  )

  const handlePage = useCallback(
    lodash.throttle((page: number) => {
      dispatch({type: "turning_page", page})
      console.log({ page });
    }, 3000, {
      leading: true,
      trailing: false
    }),
    [],
  )

  updateColumnsDefaultProps(props.columns)

  const fetchGridData = () => {
    setLoading(true)
    GenericService.list(props.uri, {
      current: page + 1, pageSize: pageSize, keyword: searchKeyword, ...props.requestParams,
      }).then((response) => {
      const data = props?.processResponse === undefined
      ? response.data
      : props?.processResponse(response.data);
      setGridData(data);
    }).catch((error) => {
      if (error.response.status < 500) {
        // do something when status is 4xx.
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  useImperativeHandle(ref, () => ({
    refresh: handelRefresh,
    search: handleSearch,
  }))

  useEffect(() => {
    fetchGridData()
  }, [pageSize, page, refresh]);
  return (
    <Box sx={{width: "100%"}}>
      <DataGrid
        loading={isLoading}
        pagination={true}
        paginationMode={"server"}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 50]}
        page={page}
        onPageChange={(newPage) => handlePage(newPage)}   // 注意datagrid在第一次加载的时候默认page为0
        rows={gridData?.data}
        rowCount={gridData?.total ? gridData?.total : 0}
        autoHeight={true}
        hideFooterSelectedRowCount={true}
        disableColumnMenu={true}
        localeText={{
          noRowsLabel: t("organ.Employee no rows"),
          noResultsOverlayLabel: t("organ.Employee no rows")
        }}
        style={{border: 'none'}}
        components={{
          Toolbar: CustomToolBarSearchCollapse
        }}
        columnVisibilityModel={{  // 无对应manage的权限，则隐藏actions列
          actions: isEligibleAction(props.authedAction)
        }}
        componentsProps={{
          pagination: {
            labelRowsPerPage: t("common.No of records per page"),
          },
          toolbar: {
            total: gridData?.total,

            ...props.toolbar
          }
        }}
        sx={customDataGridStyle}
        {...props}
      />
    </Box>
  )
}
export default forwardRef(OriginDataGridSearchCollapse)
