import Client from "..";
import {AuthEndpoints} from "./endpoints";

const companyList = async (params: Record<string, any>) => {
  const response = await Client().get(AuthEndpoints.AUTH_USER_COMPANY, { params });
  return response.data;
}

const selectCompany = async (data: Record<string, any>) => {
  const response = await Client().put(AuthEndpoints.AUTH_USER_SELECT_COMPANY, data);
  return response.data;
}

const companyInfo = async () => {
  const response = await Client().get(AuthEndpoints.AUTH_USER_COMPANY_INFO_URI);
  return response.data;
}

const companyCreation = async (data: Record<string, any>) => {
  const response = await Client().post(AuthEndpoints.AUTH_USER_COMPANY, data);
  return response.data;
}

const CompanyService = {
  companyList,
  selectCompany,
  companyInfo,
  companyCreation,
}
export default CompanyService;
