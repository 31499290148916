import Client from "@/services";
import {ATTEndpoints} from "../endpoints";

const addSchedule = async (data: any) => {
  const response = await Client().post(ATTEndpoints.ATT_SCHEDULE_URI, data)
  return response.data;
};

const ScheduleService = {
  addSchedule,
}
export default ScheduleService