import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import GenericService from "@/services/GenericService";
import {LocationEndpoints} from "@/services/location/endpoints";
import _ from 'lodash'

const initialState = {
  sites: [],
}

export const getOrganSites = createAsyncThunk('site/byOrgan', async () => {
  const {data} = await GenericService.list(LocationEndpoints.SITE_URI, {current: 1, pageSize: 50});
  return data;
})

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganSites.fulfilled, (state, action) => {
      console.log(">>>>>>>>", state.sites)
      if(_.isEqual(state.sites, action.payload.data)){
        console.log("site>lodash>>Equal>>>>>", action.payload.data)
        return state
      }
      return {
        sites: action.payload.data
      }
    })
  }
});
export default siteSlice.reducer
