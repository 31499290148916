import React, {useEffect, useState} from "react";
import {Avatar, Box, Card, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import DashboardService from "../../../services/att/services/DashboardService";
import {useTranslation} from "react-i18next";

type presentStatus = {
  title: string;
  category: string;
  waiting: boolean;
}

const PresentStatus: React.FC<presentStatus> = (props) => {
  const {t} = useTranslation();
  const cardStyle = {
    backgroundColor: "#ffffff",
    boxShadow: "#36C96D 0px 0px 1px",
    borderBlockEnd: "4px solid #5ed48a",
    borderRadius: "10px"
  }
  const [summary, setSummary] = useState<{present: string, absent: string}>({present: "-", absent: "-"});
  useEffect(() => {
    if (!props.waiting)
      return
    DashboardService.presentSummary(props.category).then(res => {
      const {data} = res;
      switch (props.category) {
        case "day":
          setSummary({present: data.present_this_day, absent: data.absent_this_day})
          break;
        case "week":
          setSummary({present: data.present_this_week, absent: data.absent_this_week})
          break;
        case "month":
          setSummary({present: data.present_this_month, absent: data.absent_this_month})
          break;
      }
    })
  }, [props.waiting])
  return (
    <Card sx={cardStyle}>
      <CardHeader
        title={
          <Typography color={"#3b454b"} sx={{ fontSize: {xs:"20px", xl:"26px"}}}>
            {props.title}
          </Typography>
        }
      />
      <Grid sx={{ borderBottom: "0.5px solid #d2d5d3", width:"92%", margin:'auto'}}></Grid>
      <CardContent>
        <Grid container rowSpacing={4} columnSpacing={4} >
          <Grid item xs={6} container direction="column" alignItems='center'>
             <Typography variant={"subtitle1"} color={"#555555"}>
                {t("common.Present")}
              </Typography>
            <Grid item xs container direction="row" sx={{ justifyContent: "center"}}>
              <PersonIcon color="info" fontSize="large"/>
              <Typography id={`${props.title}-present`} color={"#3b454b"} sx={{fontSize: "1.5rem", marginLeft: "14px"}}>
                {summary.present}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} container direction="column" alignItems='center'>
            <Box>
              <Typography variant={"subtitle1"} color={"#555555"}>
                {t("common.Absent")}
              </Typography>
            </Box>
            <Grid item xs container direction="row" sx={{ justifyContent: "center"}}>
              <PersonIcon color="error" fontSize="large"/>
              <Typography id={`${props.title}-absent`} color={"#3b454b"} sx={{fontSize: "1.5rem", marginLeft: "14px"}}>
                 {summary.absent}
              </Typography>
            </Grid>

          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
export default PresentStatus
