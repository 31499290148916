import React, {Dispatch, SetStateAction, useState} from "react";
import {Stack, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import DeviceService from "@/services/dms/DeviceService";
import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import DeviceBinding from "@/pages/dms/Device/components/DeviceAdd/DeviceBinding";
import ControlTextField from "@/components/hook-form/ControlTextField";
import {LoadingButton} from "@mui/lab";
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import { useSnackbar } from "notistack";
import SnackbarUtil from '@/utils/SnackbarUtil';

type DeviceRegister = {
  setStep: Dispatch<SetStateAction<string>>
}

const specialCharacters  = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/; // eslint-disable-line

const DeviceRegister: React.FC<DeviceRegister> = (props) => {
  const {t} = useTranslation()
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [device, setDevice] = useState<{ deviceId: string, sn: string, deviceType:string }>();

  const checkDeviceSchema = object({
    sn: z.string().min(1, t("common.This field is required!")),
  })
  type checkInput = TypeOf<typeof checkDeviceSchema>;

  const checkForm = useForm<checkInput>({
    resolver: zodResolver(checkDeviceSchema),
    defaultValues: {
      sn: ''
    }
  })

  const onSubmit = async (formData: any) => {
    setLoading(true)
    try {
      const {sn} = checkForm.getValues()

      if(specialCharacters.test(sn)) {
        enqueueSnackbar(
          t("dms.Special characters are not allowed"),
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }
        );
        setLoading(false)
        return;
      }

      const res = await DeviceService.deviceVerify(sn)
      // console.log(">>>>>>>>>>>>>>>>", res, res.data.deviceId)
      if (res.data.deviceId && res.data.deviceType === "att") {

        setDevice({...res.data, sn:sn})
        setVisible(true)

      } else {

        SnackbarUtil.error(t("dms.Access control device is not allowed to add"), {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })

        setVisible(false);
      }

    } catch (e) {
      // console.log(">>>>>>>>>>>>>>")
    } finally {
      setLoading(false)
    }
  };

  return (
    <>
      <Stack style={{margin: "0 20px"}} spacing={2}>
        <Box fontSize={'20px'}>
          <Typography>{t('dms.tip04')}</Typography>
        </Box>
        <Box fontSize={'18px'}>
          <Typography>{t('dms.tip05')}</Typography>
        </Box>
        <Box lineHeight={'30px'} fontSize={'16px'} color={'gray'}>
          <Stack>
            <Typography>{t('dms.tip06')}</Typography>
            <Typography>{t('dms.tip07')}</Typography>
            <Typography>{t('dms.tip08')}</Typography>
            <Typography>{t('dms.tip09')}</Typography>
          </Stack>
        </Box>
        <Box>
          <Grid container>
            <Grid item lg={3} xs={6}>
              <ControlTextField
                required
                autoFocus
                label={t('dms.Serial Number')}
                size={"small"}
                name={"sn"}
                form={checkForm}
                inputProps={{ maxLength: 60 }}
              />
            </Grid>
            <Grid item lg={9} xs={6} style={{display: "flex", justifyContent: "flex-end", height: "40px"}}>
              <LoadingButton
                id='ConfirmButton'
                loading={loading}
                variant={"contained"}
                onClick={checkForm.handleSubmit(onSubmit)}
                >
                {t('common.Confirm')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <DeviceBinding visible={visible} device={device} onClose={() => setVisible(false)}/>
    </>
  )
}
export default DeviceRegister
