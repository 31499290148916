import React from "react";
import {IconButton, styled} from "@mui/material";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp';

type ToolTipProps = {
  anchor?: 'top' | 'left' | 'bottom' | 'right';
  helpInfo?: any;
}

const IconButtonCustom = styled(IconButton)`
  &:hover {
    color: #0084d0;
  }
`;

const StyleedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "650px",
    maxHeight: "480px",
    backgroundColor:"white",
    border: "solid #36C96D 1px",
    color: "gray",
    fontSize: 15,
    lineHeight: 2,
    whiteSpace: "pre-wrap",
    "& .MuiTooltip-arrow": {
        "&::before": {
          border: "solid #36C96D 1px",
          backgroundColor:"white",
        },},
  },
});

const CustomTooltip: React.FC<ToolTipProps> = (props) => {
  return (
    <StyleedTooltip title={props?.helpInfo} placement={props?.anchor} arrow>
      <IconButtonCustom sx={{ marginBottom: "4px", padding: "2px", "&:hover": { backgroundColor: "transparent" }} }>
        <QuestionMarkSharpIcon sx={{fontSize: "18px", color: '#36C96D'}} />
      </IconButtonCustom>
    </StyleedTooltip>
  )
}

export default CustomTooltip;
