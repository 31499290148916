import React from "react";
import {IconButton, ButtonProps, Tooltip} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";

type CustomRowButtonProps = {
  // insideIcon?: SvgIconComponent;
  insideIcon?: any;
  buttonProps?: ButtonProps
  tooltipTitle?: string
}

const CustomRowButton: React.FC<CustomRowButtonProps> = (props) => {
  return (
    <div>
      <Tooltip title={props.tooltipTitle}>
        <IconButton
          id={`${props.buttonProps?.key}RowButton`}
          {...props.buttonProps}
        >
          {props.insideIcon && <props.insideIcon color={"action"} />}
        </IconButton>
      </Tooltip>
    </div>
  )
}
export default CustomRowButton
