import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import PermissionService from "@/services/auth/PermissionService";
import RolePermissionService from "@/services/base/RolePermissionService";
import WebURL from "@/urls";
import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Grid, Paper, Stack, TextField, Typography } from "@mui/material"
import { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Permission from "./Permission";
import jwtDecode from "jwt-decode";
import { OWNER, SUPERADMIN, dependency, OWNER_CODE, SUPERADMIN_CODE } from "./static";


type FutureProps = {
  title: string,
  children: any
}

type TokenProps = {
  roleName: string;
  roleId: string;
}

const Future = (props: FutureProps) => {
  return (
    <Box margin={"16px 8px"}>
      <Grid sx={{color: 'gray', fontSize: "22px", margin: '8px 0'}}>
        <Typography>{props.title}</Typography>
      </Grid>
      <Grid>
        {props.children}
      </Grid>
    </Box>
  )
}

const RoleEdit: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [permissionDependencies, setPermissionDependencies] = useState<Map<string, Array<string>>>();

  const location = useLocation()
  const state = location.state as { id: string, name: string, code: string, view: boolean }
  // TODO(lowell): 暂时写死Owner满权限，前端操作
  const isOwner = state.code === OWNER_CODE || state.code === SUPERADMIN_CODE
  let tokenRoleId = ""
  const jwtToken = localStorage.getItem("accessToken") || "";
  const decodedToken = jwtDecode<TokenProps>(jwtToken);
  tokenRoleId = decodedToken.roleId
  const isNormal = decodedToken.roleName !== OWNER && decodedToken.roleName !== SUPERADMIN

  /**
   * set all permissions initial value in `permission` state
   * store target role permissions in `permissionChecked` state
   */
  const [permission, setPermission] = useState<any>({});
  const [permissionChecked, setPermissionChecked] = useState<Record<string, boolean>>({});
  const permissionForm = useForm({
    defaultValues: useMemo(() => {
      return permissionChecked;
    }, [permissionChecked])
  });

  const UpdateUserPerms = (permsList: any[]) => {
    if (tokenRoleId === state.id){
      localStorage.setItem("tmpUserPerms", JSON.stringify(permsList))
    }
  }

  useEffect(() => {
    PermissionService.list({pageSize: 1000}).then(({data: permissionsData}) => {
      RolePermissionService.view({roleId: state.id, pageSize: 1000}).then(({data}) => {
        const groups = permissionsData.data
        const permissionItems = new Map();
        for (const permissions in groups) {
          groups[permissions].forEach((permission: any, index: number) => {
            const isChoice = isOwner || data.data.some((ele: any) => ele.permissionId === permission.id)
            permission['permissionId'] = permission['id']
            permission['roleId'] = state.id
            permissionChecked[permission.id] = isChoice
            // groups[permissions][index]['disabled'] = isChoice ? true : false

            permissionItems.set(permission.name, permission.id);
          });
        }
        const dependencies = dependency(permissionItems);

        setPermissionDependencies(dependencies)
        setPermissionChecked(permissionChecked)
        setPermission(groups)
      })
    })
  }, [])

  useEffect(() => {
    // monitor the request data and reset the permission form to its initial
    permissionForm.reset(permissionChecked);
  }, [permissionChecked])

  const handleSave = async () => {
    if (isOwner || isNormal) {
      navigate(WebURL.AUTH_ROLE)
      return
    }
    const permissionChecked = permissionForm.getValues();
    const data = new Array<any>()
    const groups = permission
    for (const permissions in groups) {
      groups[permissions].forEach((permission: any, index: number) => {
        const isChoice = permissionChecked[permission.id]
        if (isChoice) {
          data.push(permission)
        }
      });
    }
    RolePermissionService.edit(state.id, data).then(() => {
      navigate(WebURL.AUTH_ROLE);
      UpdateUserPerms(data)
    })
    // {state.info ?
    //   RoleService.update(roleInfo.id, role).then(({data}) => {
    //     navigate(WebURL.AUTH_ROLE);
    //   })
    //  :
      // RoleService.create(role).then(({data}) => {
      //   navigate(WebURL.AUTH_ROLE);
      // })
    // }
  }

  return (
    <Fragment>
    { isEmpty ?
      <Fragment>
        <h1>{ t("role.PermissionTips") }</h1>
      </Fragment>
      :
      <Fragment>
        <Grid className={"toolbar-container"}>
          {state.view? <CustomBreadcrumbs title={t("role.View user role details")} />:
          <CustomBreadcrumbs title={t("role.Edit user role details")} />}
        </Grid>
        <Grid sx={{margin: '8px 8px'}}>
          <Typography>{t("role.View different user roles to control the use of all or part of the functions of the application")}</Typography>
        </Grid>
        {/* <Future title="User role info">
          <Paper>
            <Grid container sx={{padding: '10px', lineHeight: '40px'}}>
              <Grid xs={4} item sx={{margin: '10px'}}>
                <ControlTextField label={"User role name"} name={"name"} form={roleForm} required={true} />
              </Grid>
              <Grid xs={4} item sx={{margin: '10px'}}>
                <ControlTextField label={"User role code"} name={"code"} form={roleForm} required={true} />
              </Grid>
            </Grid>
          </Paper>
        </Future> */}
        <Future title={t("role.Feature for this role")}>
          <Grid>
            {permission ?
              Object.keys(permission).map((item) =>
              <Permission disabled={isOwner || isNormal || state.view} title={item} key={item} name={item} permission={permission[item]} form={permissionForm} permissionDependencies={permissionDependencies!} />)
              :
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress />
              </Box>
            }
          </Grid>
        </Future>
        <Box display="flex" justifyContent="flex-end" sx={{ paddingRight: "9px"}}>
          {
          (!isOwner && !isNormal && !state.view) &&
            <Stack direction={"row"} spacing={2}>
              <LoadingButton id='SaveButton' loading={loading} size={"medium"} variant={"contained"}
                sx={{ width: "100px" }}
                onClick={handleSave}
              >
                {t("common.Save")}
              </LoadingButton>
              <Button size="medium" variant="outlined" onClick={() => {navigate(-1)}}
                sx={{ width: "100px" }}
                >
                {t("common.Cancel")}
              </Button>
            </Stack>
          }
        </Box>
      </Fragment>
    }
    </Fragment>
  )
}

export default RoleEdit;
