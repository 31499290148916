import {GRID_DATE_COL_DEF, GridColTypeDef, GridRenderEditCellParams, useGridApiContext} from "@mui/x-data-grid";
// import {TimePicker} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";
import React from "react";
import Util from "../../utils/Util";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import defaultDayjs, { Dayjs } from 'dayjs';


const GridEditTimeCell = ({id, field, value, colRef}: GridRenderEditCellParams<Date | string | null>) => {
  const apiRef = useGridApiContext();
  const timeFieldStyle = {
    '.MuiOutlinedInput-notchedOutline': {border: "none"}
  }
  return (
      <TimePicker
        value={value}
        onChange={async (newValue) => {
        await apiRef.current.setEditCellValue({id, field, value: newValue});
      }}
      />
  )
}
export const timeColumnType: GridColTypeDef<Date | string, string> = {
  ...GRID_DATE_COL_DEF,
  resizable: false,
  renderEditCell: (params) => {
    return <GridEditTimeCell {...params} />
  },
  valueGetter: (params) => {
    if(typeof params.value === "string"){
      return Util.time2datetime(params.value);
    }
    return params.value;
  }
}
