import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import CommonUtils from '../../../utils/CommonUtils';
import jwtDecode from 'jwt-decode';
import { Box } from '@mui/material';
import {SetRolePerms} from "@/utils/permissions/SetPermission";
import i18n from "@/config/i18n";


const zlinkLANGUAGES: Record<string, any> = {
  en: 'en-US',
  zh: 'zh-CN',
  idn: 'id',
  th: 'th',
  vt: 'vi',
  bur: 'vn'
}


type TokenProps = {
    exp: number;
}

const Zlink: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        const actionBeforeNavigate = async () => {
            await SetRolePerms()
            navigate("/dashboard")
        }

        const RC_LANG_KEY = CommonUtils.getCookie("RC_LANG_KEY")
        const zlinkLang = (RC_LANG_KEY == '') ? "en" : RC_LANG_KEY;
        const lng = zlinkLANGUAGES[zlinkLang] === undefined ? "en-US" : zlinkLANGUAGES[zlinkLang]
        localStorage.setItem("i18nextLng", lng);
        i18n.changeLanguage(lng);

        let jwtToken = CommonUtils.getCookie("zlink_token");
        if (jwtToken) {
            jwtToken = jwtToken.split(" ")[1]
            let refreshToken = CommonUtils.getCookie("zlink_refresh_token")
            if (refreshToken) {
                refreshToken = refreshToken.split(" ")[1]
            }
            const decodedToken = jwtDecode<TokenProps>(jwtToken);
            console.log("decodedToken >>>", decodedToken);
            localStorage.setItem("accessToken", jwtToken);
            localStorage.setItem("refreshToken", refreshToken);
            const redirectFrom = CommonUtils.getCookie("zlink_redirect_url");
            localStorage.setItem("redirectFrom", redirectFrom);
            // localStorage.setItem("redirectFrom", "zlink");
            const currentSeconds = Math.floor(Date.now() / 1000);
            const secondsLeft = decodedToken.exp - currentSeconds;
            const timer = (Math.floor(secondsLeft / 60) - 5) * 60 * 1000;
            console.log('Set refresh token timer', timer);
            console.log('Time left to call refresh token', secondsLeft / 60);
            dispatch({
                type: "SET_CURRENT_USER",
                payload: decodedToken,
            });
            dispatch({
                type: "SET_REFRESH_TOKEN",
                payload: refreshToken,
            });
            dispatch({
                type: "SET_ACCESS_TOKEN",
                payload: jwtToken,
            });
            dispatch({
                type: "REFRESH_TOKEN_TIMER",
                payload: timer,
            });
            actionBeforeNavigate()
        }
    })
    return <Box>this url will navigate to /dashboard</Box>
}

export default Zlink;
