import React, {useEffect, useRef} from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Add, DeleteOutline, RemoveRedEyeOutlined } from "@mui/icons-material";
import CustomDataGrid, { DataGridRef } from "../../../components/data-grid/CustomDataGrid";
import { ATTEndpoints } from "../../../services/att/endpoints";
import { useNavigate } from "react-router-dom";
import WebURL from "../../../urls";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import { useSnackbar } from "notistack";
import { Grid, Stack, Tooltip } from "@mui/material";
import Dialog from "../../../components/dialog";
import GenericService from "../../../services/GenericService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {useTranslation} from "react-i18next";
import HistoryAction from "@/components/actions/History";

const ExtraToolBar = () => {
  return (
    <Grid item={true}>
      <HistoryAction historyUrl={WebURL.ATT_TIMESHEET_HISTORY} />
    </Grid>
  )
}

const TimesheetDataGrid: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {t, i18n, ready} = useTranslation();

  console.log("are you ready?", ready, i18n.language, i18n.languages, i18n.resolvedLanguage)
  const dataGridRef = useRef<DataGridRef>();
  const navigate = useNavigate();
  // useEffect(()=> {
  //   i18n.changeLanguage("zh-CN")
  // }, [])
  // const confirm = useConfirm();
  const columns: GridColDef[] = [
    { field: 'name', headerName: t("att.timesheet.Timesheet Name"), minWidth: 200, flex: 0.3 },
    { field: 'cycle', headerName: t("att.timesheet.Repeat Cycle"), minWidth: 180, flex: 0.3 },
    { field: 'period', headerName: t("att.timesheet.Time Period"), minWidth: 180, flex: 0.3 },
    { field: 'description', headerName: t("att.timesheet.Description"), type: "string", minWidth: 180, flex: 0.4 },
    {
      field: 'actions',
      sortable: false,
      headerName: t("common.Action"),
      type: "actions",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 0.3,
      renderCell: (cell) => {
        return (
          <Stack direction={"row"}>
            <Tooltip title={t('common.View')} placement="bottom">
            <div>
            <CustomRowButton insideIcon={RemoveRedEyeOutlined} buttonProps={{
              key: "view",
              onClick: () => {
                navigate(WebURL.ATT_TIMESHEET_VIEW, {state: {id: cell.row.id}});
              }
            }} />
            </div>
            </Tooltip>
            <Tooltip title={t('common.Delete')} placement="bottom">
              <div>
              <CustomRowButton insideIcon={DeleteOutline} buttonProps={{
                key: "delete",
                onClick: async () => {
                  console.log(cell)
                  Dialog.confirm({
                    title: t("common.Are you sure!"),
                    content: t("common.You want to delete this data permanently?"),
                    onConfirm: () => {
                      GenericService.destroy(ATTEndpoints.ATT_TIMESHEET_URI, `${ cell.id}`).then(
                        (result) => {
                          enqueueSnackbar(t("common.Success"), {
                            variant: "success",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center"
                            }
                          });
                          dataGridRef.current?.refresh();
                        }
                      );
                    }
                  });
                }
              }} />
              </div>
            </Tooltip>
          </Stack>
        )
      }
    },
  ]
  return (
    <CustomDataGrid
      uri={ATTEndpoints.ATT_TIMESHEET_URI}
      authedAction={"TIMESHEET_MANAGE"}
      columns={columns}
      checkboxSelection={false}
      ref={dataGridRef}
      noResultFound={t("att.timesheet.No timesheet found")}
      toolbar={{
        title: "Timesheet",
        search: true,
        searchPlaceholder: t('att.timesheet.Search by Timesheet Name'),
        breadcrumbs: [
          { breadcrumbName: t("att.timesheet.Att Rule"), path: WebURL.ATT_RULE_HOME },
          { breadcrumbName: t("att.timesheet.Timesheet"), path: WebURL.ATT_TIMESHEET }
        ],
        actions: [{
          key: 'add', authedAction: "TIMESHEET_ADD", icon: Add, helpTxt: t("common.Add"), callback: async () => {
            navigate(WebURL.ATT_TIMESHEET_NEW);
          }
        }],
        children: <ExtraToolBar />
      }}
    />
  )
}
export default TimesheetDataGrid
