import React, {useRef, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {GridColDef, GridSelectionModel} from "@mui/x-data-grid";
import { Button, Grid, Stack} from "@mui/material";
import CustomDataGrid, { DataGridRef } from '@/components/data-grid/CustomDataGrid';
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import FilterAction from "@/components/actions/Filter";
import DeviceService from '@/services/dms/DeviceService';
import {HREndpoints} from "@/services/hr/endpoints";
import SnackbarUtil from "@/utils/SnackbarUtil";
import EmployeeFilterFC from "@/pages/hr/Employee/components/EmployeeFilterFC"
import DeviceInfoHeader from "./DeviceInfoHeader"

const AddPerson: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation();
  const state = location.state as { id: string }
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedIDs, setSelectedIDs] = React.useState<GridSelectionModel>([])
  const columns: GridColDef[] = [
    {field: 'employeeCode', headerName: t("common.Person ID"), type: "string", minWidth: 120, flex:0.2},
    {field: 'firstName', headerName: t("common.First name"), type: "string", minWidth: 120, flex:0.2},
    {field: 'lastName', headerName: t("common.Last name"), type: "string", minWidth: 120, flex:0.2},
  ]

  const dataGridRef = useRef<DataGridRef>();
  const [filterParams, setFilterParams] = useState<Record<any, any>>({
  });
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const handleShowFilter = () => {
    setFilterShow(!filterShow)
  }
  const onSubmit = async (formData: any) => {
    setFilterParams(formData)
    if (formData.firstName || formData.lastName || formData.code) {
      dataGridRef.current?.refresh(true);
    }
    else {
      SnackbarUtil.error(t("dms.Enter at least 1 character please"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
    }
  }

  return (
    <>
      <CustomBreadcrumbs title={t("dms.Add person to device")} />
      <DeviceInfoHeader/>
      <CustomDataGrid
        uri={`${HREndpoints.HR_EMPLOYEE_NOT_IN_DEVICE}${state.id || ""}`}
        ref={dataGridRef}
        tableHeight={"690px"}
        columns={columns}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedIDs(newSelectionModel);
        }}
        selectionModel={selectedIDs}
        requestParams={filterParams}
        noResultFound={t("organ.Employee no rows")}
        toolbar={{
          title: "",
          search: false,
          breadcrumbs: [
            {breadcrumbName: t("dms.Choose some persons that need to sync to this device"), path: ""}
          ],
          filter: <FilterAction handleShowFilter={handleShowFilter} />,
          belowChildren: <EmployeeFilterFC show={filterShow} setParams={setFilterParams} onSubmit={onSubmit} refresh={dataGridRef.current?.refresh}/>,
        }}
      />
      <Grid sx={{padding:{xl:'30px 0',xs:"20px 0"}}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
          <LoadingButton id='ConfirmButton' loading={loading} variant={"contained"} size={"large"} onClick={() => {
            if (selectedIDs && selectedIDs.length === 0){
              SnackbarUtil.error(t("dms.No Person Record Selected"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
              return
            }
            setLoading(true)
            DeviceService.addPerson(state.id||'', selectedIDs).then(
              (result) => {
                console.log('DeviceService.addPerson: ', result)
                SnackbarUtil.success(t('dms.People synced to the device successfully', {No: selectedIDs.length}))
                navigate(-1)
              }).catch((error) => {
              if (error.response.status < 500) {
                // do something when status is 4xx.
                console.log('DeviceService.addPerson: ', error)
              }
            }).finally(() => {
              setLoading(false);
            });
          }
          }>{t("common.Confirm")}</LoadingButton>
          <Button id='CancelButton' variant={"outlined"} size={"large"} onClick={() => {
            navigate(-1)
          }
          }>{t("common.Cancel")}</Button>
        </Stack>
      </Grid>
    </>
  )
}
export default AddPerson
