import React from "react";
import {AccessTime, TextSnippet} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import EligibleMenu from "@/pages/index"
import WebURL from "../../../urls";
import AssessmentIcon from '@mui/icons-material/Assessment';

const ReportHome: React.FC = () => {
  const {t} = useTranslation();
  const reports = [
    {name: "punch_list", title: t("att.report.Transaction"), icon: AccessTime, path: WebURL.ATT_TIMECARD_PUNCH_RECORD},
    {name: "timecard_punchtimes", title: t("att.timecard.Timecard"), icon: AssessmentIcon, path: WebURL.ATT_TIMECARD_TIMECARD_TIMES},
    {name: "firstlast_list", title: t("att.report.First And Last"), icon: AccessTime, path: "/att/report/first-and-last"},

    {name: "timecard_list", title: t("att.timecard.Daily Total Timecard"), icon: AssessmentIcon, path: WebURL.ATT_TIMECARD_TIMECARD},
    // {name: "filo", title: t("att.report.First In Last Out"), icon: AccessTime, path: "/att/report/first-in-last-out"},
    {name: "monthly_list", title: t("att.report.Monthly Report"), icon: TextSnippet, path: "/att/report/monthly"},
  ]
  return (
    <EligibleMenu title={t("common.sidebar.Report")} menus={reports}/>
  )
};
export default ReportHome
