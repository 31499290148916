import React, {useState} from "react";
import {Box, Button, Grid, Stack} from "@mui/material";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {ReactComponent as SiteMap} from "@/assets/images/SiteMap.svg"
import {object, string, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {timezones} from "@/components/resource/timezone";
import {countryList} from "@/components/resource/country";
import GenericService from "@/services/GenericService";
import {LocationEndpoints} from "@/services/location/endpoints";
import {useNavigate, useParams} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import WebURL from "@/urls";
import ControlTextField from "@/components/hook-form/ControlTextField";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import {useTranslation} from "react-i18next";


const AddSite: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate()
  const siteSchema = object({
    name: z.string().min(1, t("This field is required!")),
    timezone: z.object({
      value: z.string(),
      name: string()
    }),
    country: z.object({
      country: z.string()
    }).required(),
    state: z.string().nullish(),
    city: z.string().min(1, t("This field is required!")),
    addressLine1: z.string().min(1, t("This field is required!")),
    addressLine2: z.string().nullish(),
    description: z.string().nullish(),
  })
  type siteFormType = TypeOf<typeof siteSchema>

  const [saving, setSaving] = useState<boolean>(false)
  const siteForm = useForm<siteFormType>({
    resolver: zodResolver(siteSchema),
  })
  const timezoneList = timezones()
  const onSave = async () => {
    setSaving(true)
    try {
      const isValid = await siteForm.trigger()
      if (isValid) {
        const values = siteForm.getValues()
        const newSite = {
          name: values.name,
          description: values.description,
          address: {
            country: values.country.country,
            state: values.state,
            city: values.city,
            timeZone: values.timezone.value,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2
          }
        }
        await GenericService.create(LocationEndpoints.SITE_URI, newSite)
        navigate(WebURL.LOCATION_SITE)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setSaving(false)
    }
  }
  return (
    <Box>
      <CustomBreadcrumbs title={"Add Site"}/>
      <Grid container>
        <Grid item lg={4} xs={6} sx={{display: "flex", justifyContent: "center", alignItems: "start"}}>
          <SiteMap/>
        </Grid>
        <Grid item lg={7.70} xs={6} component={"form"}
              sx={{display: "flex", justifyContent: "center", alignItems: "start"}}>
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <ControlTextField label={t("dms.Site Name")} name={"name"} form={siteForm} required={true}/>
            </Grid>
            <Grid item xs={6}>
              <ControlAutoComplete
                label={t('dms.Timezone')}
                name={'timezone'}
                form={siteForm}
                options={timezoneList}
                required={true}
                isOptionEqualToValue={(option, value) => option.name === value.name}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlAutoComplete
                label={t('common.Country')}
                name={'country'}
                form={siteForm}
                options={countryList}
                required={true}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlTextField label={t("common.State")} name={"state"} form={siteForm}/>
            </Grid>
            <Grid item xs={12}>
              <ControlTextField label={t("common.City")} name={"city"} form={siteForm} required={true}/>
            </Grid>
            <Grid item xs={12}>
              <ControlTextField label={t("common.Address Line 1")} name={"addressLine1"} form={siteForm} required={true}/>
            </Grid>
            <Grid item xs={12}>
              <ControlTextField label={t("common.Address Line 2")} name={"addressLine2"} form={siteForm}/>
            </Grid>
            <Grid item xs={12}>
              <ControlTextField label={t("common.Description")} name={"description"} form={siteForm}/>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={2} sx={{display: "flex", justifyContent: "end"}}>
                <LoadingButton id='SaveButton' variant={"contained"} onClick={onSave} loading={saving}>{t("common.Save")}</LoadingButton>
                <Button id='CancelButton' variant={"outlined"} onClick={() => navigate(WebURL.LOCATION_SITE)}>{t("common.Cancel")}</Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};
export default AddSite
