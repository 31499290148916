import React from "react";
import {AccessTimeFilled} from "@mui/icons-material"
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import WebURL from "../../../urls";
import {useTranslation} from "react-i18next";
import EligibleMenu from "@/pages/index"
import {ReactComponent as CalenderIcon} from "@/assets/images/Calender.svg";

const AttRuleHome: React.FC = () => {
  const {t} = useTranslation();
  const items = [
    // {name: "clocking_list", title: t("common.Clocking In Device"), icon: AccessTimeFilled, path: WebURL.ATT_DEVICE_LIST},
    {name: "timesheet_list", title: t("att.timesheet.Timesheet"), icon: PendingActionsIcon, path: WebURL.ATT_TIMESHEET},
    // {name: "gpsfenceclocking", title: "Gps Fence Clocking", icon: AccessTimeFilled, path: WebURL.ATT_GPS_FENCE_CLOCKING}
    {name: "schedule_list", title: t("common.sidebar.Schedule"), icon: CalenderIcon, path: WebURL.ATT_SCHEDULE},
  ]

  return (
    <EligibleMenu title={t("common.sidebar.Attendance Rule")} menus={items}/>
  )
}
export default AttRuleHome
