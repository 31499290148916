import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import UserService from "../../services/auth/UserService";
import CompanyService from "../../services/auth/CompanyService";
import CommonUtils from '@/utils/CommonUtils';
import jwtDecode from "jwt-decode";

const getUserInfo = async () => {
  try {
    const {data} = await UserService.userInfo();
    return data
  } catch (error) {
    return undefined
  }
}

const getCompanyInfo = async () => {
  try {
    const {data} = await CompanyService.companyInfo();
    return data
  } catch (error) {
    return undefined
  }
}

export const obtainUserInfo = createAsyncThunk("app/obtainUserInfo", getUserInfo)

export const obtainCompanyInfo = createAsyncThunk("app/obtainCompanyInfo", getCompanyInfo)

/**
 * Initial App Global State
 */
export const getInitialState = createAsyncThunk("app/getInitialState", async () => {
  const user = await getUserInfo();
  const company = await getCompanyInfo();
  return {
    user,
    company
  }
})

const accessToken = CommonUtils.getCookie('zlink_token');
const BearerToken = accessToken ? accessToken : `Bearer ${localStorage.getItem("accessToken")}`

let decodedToken = {
  lastName: '',
  firstName: '',
  companyName: '',
}
try {
  decodedToken = jwtDecode<any>(BearerToken);
} catch (error) {
  console.log(error)
}

const initUser = {
  firstName: decodedToken.firstName,
  lastName: decodedToken.lastName,
}
const initCompany = {
  name: decodedToken.companyName,
}

export const appSlice = createSlice({
  name: "app",
  initialState: {
    user: initUser,
    company: initCompany
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(obtainUserInfo.fulfilled, (state, action) => {
      return {
        ...state,
        user: action.payload
      }
    })
    builder.addCase(obtainCompanyInfo.fulfilled, (state, action) => {
      return {
        ...state,
        company: action.payload
      }
    })
    builder.addCase(getInitialState.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    })
  }
})
export default appSlice.reducer
