import React, {useState} from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField
} from "@mui/material";
import {Language} from "@mui/icons-material";
import {Box} from "@mui/system";
import TranslationUtil from "@/utils/TranslationUtil";
import i18n from "@/config/i18n";

type LanguageMenuItemProps = {
  hidePopup?: () => Promise<void>;
}

const LanguageMenuItem: React.FC<LanguageMenuItemProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const currentLng = localStorage.getItem("i18nextLng") || "en-US";
  const [lng, setLng] = useState<string>(currentLng);
  const languages = [
    {code: "en-US", name: "English"},
    {code: "zh-CN", name: "简体中文"},
    {code: "id", name: "Indonesia"},
    {code: "th", name: "Thai"},
    {code: "vi", name: "Vietnam"},
    {code: "vn", name: "Myanmar"}
  ]
  return (
    <Box>
      <MenuItem onClick={async () => {
        await props.hidePopup?.();
        setOpen(true)
      }}>
        <ListItemIcon>
          <Language sx={{color: "#304669"}} fontSize='small'/>
        </ListItemIcon>
        <ListItemText>{TranslationUtil.t("common.Language")}</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
      >
        <Box sx={{ width: "400px", height: "200px", backgroundColor: "#fefefe"}}>
          <Box sx={{ padding: "20px" }} display='flex' justifyContent={'space-between'}>
            <Autocomplete
              clearIcon={false}
              value={languages.find((language) => {
                return language.code === lng
              })}
              isOptionEqualToValue={(option, value) => {
                return option.code === value.code
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin={"dense"}
                  fullWidth={true}
                  label={TranslationUtil.t("common.Select Language")}
                  sx={{width: "360px"}}
                />
              )}
              getOptionLabel={(option) => {
                return option.name
              }}
              options={languages}
              onChange={(event, value) => {
                console.log("select value >>>", value)
                setLng(value?.code || "en-US");
              }}
            />
          </Box>
          <Box sx={{ padding: "20px" }} display='flex' justifyContent={'space-between'}>
            <Box sx={{ width: "100%"}}>
              <Grid >
                <Grid item xs={12} sm={12} lg={12} display={'flex'}>
                  <Button id="ConfirmButton"
                    size='large'
                    fullWidth variant='contained'
                    style={{marginRight:'15px',textTransform:'none'}}
                    onClick={async () => {
                      localStorage.setItem("i18nextLng", lng);
                      await i18n.changeLanguage(lng);
                      console.log("lng---------->",lng, i18n.language)
                      setOpen(false)
                    }}
                    >{TranslationUtil.t("common.Confirm")}</Button>
                  <Button id= "CancelButton" size='large' fullWidth variant='outlined'
                    style={{textTransform:'none'}}
                    onClick={() => {
                      setOpen(false)
                    }}
                  >{TranslationUtil.t("common.Cancel")}</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}
export default LanguageMenuItem
