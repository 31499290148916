import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";


type CustomAccordionProps = {
  children: any,
  title: string,
  checkboxProps: Partial<CheckboxProps>,
  accordionProps?: Partial<AccordionProps> 
}


const CustomAccordion = (props: CustomAccordionProps) => {
  return (
    <Accordion {...props.accordionProps}>
      <AccordionSummary
        expandIcon={<ExpandMore />} 
      >
        <FormControlLabel 
          control={
            <Checkbox 
              color="primary" 
              {...props.checkboxProps}
            />
          }
          label={props.title}
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
        />
      </AccordionSummary>
      <AccordionDetails>
        {props.children}
      </AccordionDetails>
    </Accordion>
  )
};

export default CustomAccordion;