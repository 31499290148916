import Client from "@/services";
import {ATTEndpoints} from "../endpoints";

const addTimesheet = async (data: any) => {
  const response = await Client().post(ATTEndpoints.ATT_TIMESHEET_URI, data)
  return response.data;
};

const TimesheetService = {
  addTimesheet,
}
export default TimesheetService