import React from "react";
import { Avatar, Box, Tooltip } from "@mui/material";
import { Link } from "react-router-dom"
import Prefix from "@/utils/static/NamePrefix"
import {useTranslation} from "react-i18next";

const Help: React.FC = () => {
  const {t} = useTranslation();
  return (
    <>
      <Box sx={{ paddingTop: '3px', paddingRight: '24px'  }}>
        <Box
        id={Prefix.HEADER_HELP}
        onClick={() => {
          const w: any = window.open('about:blank')
          w.location.href = '/ZK_ZKBio Zlink UM_EN v1.0_20230901.pdf#page=19'
        }} sx={{ display: "flex" }}>
          <Tooltip title={t("common.Help")}>
            <Avatar sx={{ '&.MuiAvatar-circular' : {cursor: "pointer"} }} src='/images/help.svg' />
          </Tooltip>
        </Box>

      </Box>
    </>
  )
}
export default Help
