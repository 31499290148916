import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import {ReactComponent as ExportIcon} from "@/assets/icons/export.svg";
import { Fragment, useState } from "react";
import {useTranslation} from "react-i18next";
import { makeStyles } from '@mui/styles';

const useStyles  = makeStyles({
  menuItem: {
    "&:hover": {
      color: "rgb(54, 201, 109) !important"
    }
  }
});

type ExportProps = {
  setExportFormat: (format: "xlsx"| "csv") => void
}

const ExportAction = (props: ExportProps) => {
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChoice = (event: any) => {
    props.setExportFormat(event.target.innerText)
    handleClose()
  }

  return (
    <Fragment>
      <Tooltip arrow title={t("att.report.Export")} onClick={handleClick}>
        <IconButton id='ExportIconButton' sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
          <ExportIcon />
        </IconButton>
      </Tooltip>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem className={classes.menuItem} onClick={handleChoice}>xlsx</MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleChoice}>csv</MenuItem>
      </Menu>
    </Fragment>
  )
}

export default ExportAction
