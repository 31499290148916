import React, {useState} from "react";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import DeviceConnGuide from "@/pages/dms/Device/components/DeviceAdd/DeviceConnGuide";
import DeviceRegister from "@/pages/dms/Device/components/DeviceAdd/DeviceRegister";
import {useTranslation} from "react-i18next";

const DeviceAdd: React.FC = () => {
  const {t} = useTranslation()
  const [step, setStep] = useState<string>('guide')
  const [result, setResult] = useState<string>( () => localStorage.getItem('step') || "" )

  React.useEffect(() => {
    if(result && result == "register") {
      localStorage.setItem('step', "register");
    } else {
      localStorage.setItem('step', step);
    }
  }, [step]);

 return (
    <>
      <CustomBreadcrumbs title={t("dms.Add Device")}/>
      {result === 'register' ? <DeviceRegister setStep={setStep}/> : step === 'guide' ?
        <DeviceConnGuide setStep={setStep}/>
        :
        <DeviceRegister setStep={setStep}/>
      }
    </>
  )
}

export default DeviceAdd;
