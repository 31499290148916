import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import {useTranslation} from "react-i18next";
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { Alert, DialogTitle, IconButton, Typography, Stack  } from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { GridColDef } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";
import DeviceService from '@/services/dms/DeviceService';
import { ATTEndpoints } from "@/services/att/endpoints";
import CustomDataGrid, { DataGridRef } from '@/components/data-grid/CustomDataGrid';
import WebURL from "@/urls";
import Util from "@/utils/Util";
import HistoryAction from "@/components/actions/History";
import InfoAction from "@/components/actions/Info";
import FilterAction from "@/components/actions/Filter";
import RefreshAction from "@/components/actions/Refresh";
import GenericService from "@/services/GenericService";
import {DMSEndpoints} from "@/services/dms/endpoints";
import SnackbarUtil from '@/utils/SnackbarUtil';
import PunchRecordService from "@/services/att/services/PunchRecordService";
import { DataGridContext, DataGridDispatchContext } from "@/components/data-grid/DataGridContext";
import {isEligibleAction} from "@/utils/permissions/FilterUnauthed"
import ExportAction from "@/components/actions/Export";

const PUNCHRECORD_PERMISSION_NAME = "PUNCHRECORD_EXPORT"

const punchRecordStyle = {
  '& .MuiPaper-root': { width: '60vw', top: '-10vh' },
  '.MuiDialog-paper': { maxWidth: '100vw' },
};

const [firstDate, lastDate] = Util.getCurrentMonthOfStartAndEndDayObj()
const [firstDay, lastDay] = Util.getCurrentMonthOfStartAndEndDay()

const ExtraAction = (props: any) => {
  const { searchKeyword } = useContext(DataGridContext)
  const [info, setInfo] = useState<boolean>(false)

  const hasExportPermission = isEligibleAction(PUNCHRECORD_PERMISSION_NAME)
  return (
    <Fragment>
      <Grid item={true}>
        <InfoAction setInfo={setInfo} />
      </Grid>
      <Grid item={true}>
        <HistoryAction historyUrl={WebURL.ATT_TIMECARD_PUNCH_RECORD_HISTORY} />
      </Grid>
      <InfoDialog info={info} setInfo={setInfo} />
      {hasExportPermission && <Grid item={true}>
        <ExportAction setExportFormat={(fileType: string) => { props.setFilterParams({ ...props?.filterParams, keyword: searchKeyword });
                                                              props.setExportFormat(fileType) }}  />
      </Grid>
      }
    </Fragment>
  )
}

const InfoDialog = ({info, setInfo}: any) => {
  const {t} = useTranslation()
  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      sx={punchRecordStyle}
    >
      <DialogTitle>
        {t("common.Usage Tips")}
        <IconButton
          id='CloseIconButton'
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ lineHeight: '25px', color: 'gray' }}>
        <Box>
          <Typography>{t("common.Punch Record text_1")}</Typography>
        </Box>
        {/* <Box sx={{ margin: '15px 0' }}>
          <Typography>{t("common.Punch Record text_2")}</Typography>
        </Box> */}
        <Box sx={{ margin: '15px 0' }}>
          <Typography>2.{t("att.timecard.uploadTransactionTip")} </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const  PunchRecordGrid: React.FC = () => {
  const {t} = useTranslation()
  const [upload, setUpload] = useState<boolean>(false);
  const dataGridRef = useRef<DataGridRef>();
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const [accDisplay, setaccDisplay] = useState<boolean>(false);
  const [exportFormat, setExportFormat] = useState<"xlsx" | "csv" | null>(null);
  const [filterParams, setFilterParams] = useState<Record<any, any>>({
    start_time: firstDay,
    end_time: lastDay,
  });

  const handleShowFilter = () => {
    setFilterShow(!filterShow)
  }

  function isValidDate(date: any) {
    return date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 2000
  }

  const filterFormValidate = object({
    keyword: z.string().optional(),
    start_time: z.date(),
    end_time: z.date(),
  })
  type FilterForm = TypeOf<typeof filterFormValidate>;
  const filterForm = useForm<FilterForm>({
    defaultValues: {keyword: '', start_time: firstDate, end_time: lastDate}
  });
  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = filterForm;

  React.useEffect(() => {
    const refreshPage = () => {
      filterForm.reset()
      setFilterParams({})
      dataGridRef.current?.refresh(true);
    };
    refreshPage();
  }, [!filterShow]);

  const FilterComponent = () => {
    if (!filterShow) {
      return (
        <></>
      )
    }
    const onSubmit = async (formData: any) => {
      formData.start_time = Util.convertDateToString(formData.start_time)
      formData.end_time = Util.convertDateToString(formData.end_time)
      setFilterParams(formData)
      if (formData.start_time || formData.end_time || formData.keyword) {
        dataGridRef.current?.refresh(true);
      }
      else {
        SnackbarUtil.error(t("dms.Enter at least 1 character please"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      }
    }

    return (
      <>
      <Grid container={true} component={'form'} onSubmit={handleSubmit(onSubmit)}
          sx={{borderRadius: '8px',
            padding: { xl: '30px', xs: "20px" },
            width: '100%',
            border: '1px solid #DDDDDD',
            backgroundColor:"#FFF"
          }}
          >
          <Grid item xs={12} md={3.4} sx={{p:{md:"0 20px 0 0", xs:"20px 0 0 0"}}}>
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'}>
              <Controller
                control={control}
                name="keyword"
                render={({field}) => (
                  <TextField
                    fullWidth
                    placeholder={t("att.timecard.Search by Person ID/Name / Department Name")}
                    value={field.value}
                    size="small"
                    error={!!errors.keyword}
                    helperText={errors.keyword?.message || ""}
                    {...register("keyword")}
                  />
                )}
              />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={1.2}>
            <Box display={'flex'}>
              <Typography sx={{lineHeight: '40px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: "ellipsis"}}>
                {t("common.Clocking Date")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.4} sx={{p:{md:"0 20px 0 0", xs:"20px 0 0 0"}}}>
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'}>
              <Controller
                control={control}
                name="start_time"
                render={({ field }) => (
                  <DatePicker
                    label={t("att.timecard.From")}
                    onChange={(value) => {
                      const tempEndTime = getValues('end_time')
                      if (value && tempEndTime) {
                        const newValue = Util.convertDateToString(value)
                        const newTempEndTime =Util.convertDateToString(tempEndTime)
                        if (newValue > newTempEndTime) {
                          SnackbarUtil.error(t("common.Invalid input."), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          // return;
                        }
                      }
                      field.onChange(value)
                    }}
                    value={field.value}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth:true
                      }
                    }}
                  />
                )}
              />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.4} sx={{p:{md:"0 20px 0 0", xs:"20px 0 0 0"}}}>
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'}>
              <Controller
                control={control}
                name="end_time"
                render={({ field }) => (
                  <DatePicker
                    label={t("att.timecard.To")}
                    onChange={(value) => {
                      const tempStartTime = getValues('start_time')
                      if (value && tempStartTime) {
                        const newValue = Util.convertDateToString(value)
                        const newTempStartTime = Util.convertDateToString(tempStartTime)
                        if (newValue < newTempStartTime) {
                          SnackbarUtil.error(t("common.Invalid input."), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          return;
                        }
                      }
                      field.onChange(value)
                    }}
                    value={field.value}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth:true
                      }
                    }}
                  />
                )}
              />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={0.2} >
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'}>  </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.4}
            sx={{
              paddingLeft: { md: "20px", xs: "0px" },
              mt: { md: "0px", xs: "20px" },
              display: "flex",
              justifyContent: { md: "flex-end", xs: "center" },
            }}
            >
              <Box display={'flex'}>
                <Button
                  id='SearchButton'
                  variant={"contained"}
                  type='submit'
                  sx={{
                    marginRight: "20px",
                    width: { xl: '100px', xs: "100px" },
                    height: { xl: '40px', xs: "35px" },
                    }}
                >
                {t("common.Search")}
              </Button>
              <Button
                  sx={{
                  width: { xl: '100px', xs: "100px" },
                  height: { xl: '40px', xs: "40px" },
                }}
                id='ResetButton'
                variant={"outlined"}
                onClick={() => {
                  filterForm.reset()
                  setFilterParams({})
                  dataGridRef.current?.refresh(true);
                }}
                >
                {t("common.Reset")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }

  const UploadDialog = () => {
    const [start_date, setStartDate] = useState<Date>(new Date());
    const [end_date, setEndDate] = useState<Date>(new Date());
    const [accUpload, setaccUpload] = useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const UploadTransactions = async () => {
      if (!start_date || !end_date || !isValidDate(start_date) || !isValidDate(end_date)) {
        SnackbarUtil.error("The start date and end date are required, Please select the correct start and end dates",
          {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      }else {
        setLoading(true)
        try {
          await DeviceService.uploadTransactions({
            "start_date": new Date(start_date).toISOString().slice(0, 10),
            "end_date": new Date(end_date).toISOString().slice(0, 10),
             "upload_acc_device": accUpload
          }).then((result) => {
            if (result.status < 400) {
              SnackbarUtil.success(t("att.timecard.Synchronizing device transactions, please refresh the page later"))
            }
          })
        } catch (e) {
          // console.log(e)
        }finally {
          setLoading(false)
          setUpload(false)
        }
      }
    };

    return (
      <Dialog
        open={upload}
        onClose={() => { setUpload(false); setStartDate(new Date()); setEndDate(new Date()) }}
        sx={punchRecordStyle}
      >
        <DialogTitle>
          {t("att.timecard.Upload device transactions")}
        </DialogTitle>
        <DialogContent sx={{ lineHeight: '25px', color: 'gray' }}>
          <Box>
            {t("att.timecard.uploadTransactionConfirm")}
          </Box>
          <Grid lg={3} item sx={{ marginTop: "10px" }}>
            <Box sx={{ marginBottom: "10px" }}>
             {t("att.timecard.From")}
            </Box>
            <DatePicker
              disableFuture
              label={t("att.timecard.Start Date")}
              onChange={(newValue: any) => {
                if (isValidDate(newValue)) {
                  if (newValue.toISOString().slice(0, 10) > end_date.toISOString().slice(0, 10)) {
                    SnackbarUtil.error(t("att.timesheet.Start date is greater than end date"),
                      {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                    return;
                  }
                  setStartDate(newValue);
                }
                else {
                  setStartDate(newValue);
                }
              }}
              value={start_date}
            />
          </Grid>
          <Grid lg={3} item>
            <Box sx={{ marginBottom: "10px" }}>
              {t("att.timecard.To")}
            </Box>
            <DatePicker
              disableFuture
              label={t("att.timecard.End Date")}
              onChange={(newValue: any) => {
                if (isValidDate(newValue)) {
                  if (newValue.toISOString().slice(0, 10) < start_date.toISOString().slice(0, 10)) {
                    SnackbarUtil.error(t("att.timesheet.End date is less than start date"),
                      {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                    return;
                  }
                  setEndDate(newValue);
                }
                else {
                  setEndDate(newValue);
                }
              }}
              value={end_date}
            />
          </Grid>
          <Grid item>
             {t("att.timecard.Select the type of devices")}
             <FormControlLabel sx={{marginLeft: "5px"}} control={<Checkbox id={'attUploadCheck'} defaultChecked disabled />} label="ATT" />
             {accDisplay ?<FormControlLabel control={<Checkbox id={'accUploadCheck'} checked={accUpload} onChange={() => {setaccUpload(!accUpload)}} />} label="ACC" /> : <br></br>}
          </Grid>
          <Alert severity="info" sx={{ marginTop: '20px', maxWidth: "95%"}}>1.{t("att.timecard.uploadAttDeviceTip")} <br/> 2.{t("att.timecard.uploadTransactionTip")} </Alert>
          <DialogActions>
            <LoadingButton
              id='ConfirmButton'
              loading={loading}
              onClick={() => (UploadTransactions())}
              variant={"contained"}>{t('common.Confirm')}</LoadingButton>
            <Button id='CancelButton' onClick={() => { setUpload(false); setStartDate(new Date()); setEndDate(new Date()) }}>{t('common.Cancel')}</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }

   useEffect(() => {
     if(upload){
       GenericService.list(DMSEndpoints.DMS_DEVICE_URI, {current: 1, pageSize: 10, deviceType:"acc"})
         .then(({data}) => {
          if (data.data.length){
            setaccDisplay(true)
          }});
     }}, [upload]
     )

  const handleRefresh = () => {
    setFilterParams({})
    filterForm.reset()
    setFilterShow(false)
  }

  useEffect(() => {
    if (exportFormat) {
      const fields: Array<{name: string, alias: string}> = []
      for (const column in columns) {
        fields.push({name: columns[column]["field"], alias: columns[column]["headerName"]!})
      }
      const export_meta = {
        fields,
        title: t("att.report.Transaction"),
        file_type: exportFormat,
        share: []
      }
      const params: string[] = []
      for (const key in filterParams) {
        params.push(`${key}=${filterParams[key]}`)
      }
      PunchRecordService.exportData(params.join("&"), export_meta
        ).then((response) => {
          console.log("lod", response, response.headers["content-type"])
        const blob = new Blob([response.data], {type: response.headers["content-type"]})
        const url = URL.createObjectURL(blob)
        const aLink = document.createElement("a")
        aLink.style.display = "none"
        aLink.href = url
        document.body.appendChild(aLink)
        aLink.click()
        URL.revokeObjectURL(url)
        document.body.removeChild(aLink)
      }).catch((error) => {
        if (error.response.status < 500) {
          // do something when status is 4xx.
        }
      });
      setExportFormat(null)
    }
  }, [exportFormat])

  const columns: GridColDef[] = [
    { field: 'employee_code', headerName: t("common.Person ID"), type: "string", flex: 0.2 },
    { field: 'employee_name', headerName: t("common.Person Name"), flex: 0.2 },
    { field: 'department_name', headerName: t("common.Department"), type: "string", flex: 0.2 },
    { field: 'punch_date', headerName: t("att.report.Date"), type: "date", flex: 0.2 },
    { field: 'punch_time', headerName: t("common.Time"), flex: 0.2 },
    { field: 'timezone', headerName: t("dms.Timezone"), flex: 0.2 },
    { field: 'punch_from', headerName: t("common.Punch From"), flex: 0.2 },
    { field: 'device_name', headerName: t("dms.Device Name"), flex: 0.2 },
    // { field: 'device_sn', headerName: t("dms.Device SN"), flex: 0.2 },
  ]
  return (
    <>
      <CustomDataGrid
        ref={dataGridRef}
        uri={ATTEndpoints.ATT_PUNCH_RECORD_URI}
        columns={columns}
        requestParams={filterParams}
        checkboxSelection={false}
        noResultFound={t("att.report.No transactions found")}
        toolbar={{
          title: t("att.report.Transaction"),
          authedAction: "PUNCH_UPLOAD",
          search: false,
          searchPlaceholder: t("att.report.Search by Person"),
          filter: <FilterAction handleShowFilter={handleShowFilter} />,
          refresh: <RefreshAction handleRefresh={handleRefresh} />,
          upload: {
            setUpload: setUpload,
          },
          breadcrumbs: [
            { breadcrumbName: "", path: WebURL.ATT_REPORT_HOME },
            { breadcrumbName: t("att.report.Transaction"), path: "" },
          ],
          children: <ExtraAction filterParams={filterParams} setFilterParams={setFilterParams} setExportFormat={setExportFormat} />,
          belowChildren: <FilterComponent/>
        }}
      />
      <UploadDialog />
    </>
  )
}
export default PunchRecordGrid

