import { IconButton, Tooltip } from "@mui/material"
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useTranslation } from "react-i18next";


const InfoAction = ({setInfo}: any) => {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("att.report.Info")}>
      <IconButton id='InfoIconButton' sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }} onClick={()=>{
        setInfo(true)
      }}>
        <QuestionMarkIcon />
      </IconButton>
    </Tooltip>
  )
}

export default InfoAction
