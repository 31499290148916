import React, { useState } from 'react';
import { Cancel } from '@mui/icons-material';
import { ReactComponent as Search } from '@/assets/icons/SearchIcon.svg';
import { Box, TextField, Tooltip } from '@mui/material';
import { SearchBarRightProps } from './Sheet.Types';
import { useTranslation } from "react-i18next";

const SearchBarRight: React.FC<SearchBarRightProps> = (props) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false)

  const ValidationTextField = {
    "& .MuiInputBase-input": {
      height: { xl: "23px", xs: "23px" },
    },
    "& input::placeholder": {
      fontSize: "13px"
    },
  };

  const style = {
    width: !expand ? "46px" : '90%',
    transition: "0.5s",
    letiant: "standard"
  } as const;

  return (
    <>
      <Box sx={style}>
        <TextField
          size='small'
          autoComplete='search-right'
          fullWidth id="test-box-right"
          placeholder={props.placeholder}
          onMouseEnter={() => { if (props.value == "") setExpand(true) }}
          onMouseLeave={() => { if (props.value == "") setExpand(false) }}
          disabled={!expand}
          onBlur={() => { if (props.value == "") setExpand(false) }}
          sx={{ ...ValidationTextField }}
          InputProps={{
            endAdornment: (
              <>
                {props.value ? (
                  <Box id='close-icon' style={{ cursor: "pointer" }}>
                    <Tooltip title={t("common.Clear")}>
                      <Cancel
                        style={{ color: "rgba(71, 75, 79, 0.5)", marginTop: "8px" }}
                        onClick={() => props.clearSearch()}
                      />
                    </Tooltip>
                  </Box>
                ) : (
                  <Tooltip title={t("common.Search")}>
                    <Box id='search-icon' style={{ cursor: "pointer" }}>
                      <Search style={{ cursor: "pointer", marginTop: "6px" }} onClick={() => { if (props.value == "") setExpand(true) }} />
                    </Box>
                  </Tooltip>
                )}
              </>
            ),
          }}
          value={props.value} helperText={props.helperText}  onChange={props.handleChange} />
      </Box>
    </>
  );
}

export default SearchBarRight;
