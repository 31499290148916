import React from "react";
import {Box, Breadcrumbs, Button, Grid, IconButton, Paper, Stack, Typography} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";
import DoneIcon from '@mui/icons-material/Done';
import WebURL from "@/urls";

const SubscriptionPayment: React.FC = () => {
  const navigate = useNavigate();
  const reports = [
    {name: "Subscription", title: "Subscription", icon: ShoppingCartIcon, path: "/sub/new/"},
    {name: "Subscription Order", title: "Subscription Order", icon: WarehouseIcon, path: "/sub/order/"},
  ]
  return (
    <Box>
      <Grid className={"toolbar-container"}>
        <Box sx={{ display: 'flex' }}>
          <Breadcrumbs separator="" sx={{ height: "32px", p: '8px 0' }}>
            <IconButton id='BackIconButton' sx={{padding: "5px"}} onClick={() => navigate(-1)}>
            </IconButton>
            <Typography>Subscription</Typography>
          </Breadcrumbs>
        </Box>
        <CustomBreadcrumbs title={"Payment"} />
      </Grid>
      <Paper elevation={0} style={{ minHeight: '120%', marginLeft: "1.5vw", backgroundColor: '#fff', borderRadius: '8px' }}>
        <Grid>
          <Typography variant="h6" sx={{'p': '30px 20px'}}>{"Subscription Detail"}</Typography>
        </Grid>
        <Grid direction="row" sx={{p: "3vh 1vw" }}>
          <Grid xs={6} container spacing={12} justifyContent="start">
            <Grid item>
              <Typography variant="body1">{"Feature package"}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{"Plan Period"}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{"Subscription Price"}</Typography>
            </Grid>
          </Grid>
          <Grid xs={6} container justifyContent="start" sx={{marginTop:"10px"}}>
            <Grid item>
              <Typography variant="body1">{"Free trail"}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" sx={{marginLeft: "9vw"}}>{"3 months"}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" sx={{marginLeft: "7vw"}}>{"0 $"}</Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography variant="body1" sx={{'p': '50px 0'}}>{"You will get the services as below"}</Typography>
          </Grid>
          <Grid>
            <Typography variant="body1" sx={{marginTop:"-30px"}}>{"CloudATT"}</Typography>
          </Grid>
          <Grid container sx={{p:"5px 10px"}}>
            <DoneIcon sx={{color:"#36C96D"}}/>
            <Typography variant="body1">{"Flexible Time Shift"}</Typography>
          </Grid>
          <Grid container sx={{p:"5px 10px"}}>
            <DoneIcon sx={{color:"#36C96D"}}/>
            <Typography variant="body1">{"3 kinds report formats"}</Typography>
          </Grid>
          <Grid container sx={{p:"5px 10px"}}>
            <DoneIcon sx={{color:"#36C96D"}}/>
            <Typography variant="body1">{"Flexible Time Shift"}</Typography>
          </Grid>
        </Grid>
        <Grid direction="row" sx={{p: "3vh 1vw" }}>
          <Grid>
            <Typography variant="body1">{"You will pay with"}</Typography>
          </Grid>
          <Grid xs={8} container justifyContent="start" sx={{marginTop:"3vh"}}>
            <Grid item sx={{p:"5px 10px"}}>
              <img style={{width: '65px'}} src={"/images/img_truemoney.png"}/>
              <Typography variant="body1">{"Truemoney"}</Typography>
            </Grid>
            <Grid item sx={{p:"5px 30px"}}>
               <img style={{width: '65px'}} src={"/images/img_Stripe.png"}/>
               <Typography variant="body1">{"Stripe"}</Typography>
               <Typography variant="body1">{"payments"}</Typography>
            </Grid>
            <Grid item sx={{p:"5px 30px"}}>
               <img style={{width: '65px'}} src={"/images/img_paytm.png"}/>
               <Typography variant="body1">{"Paytm"}</Typography>
            </Grid>
            <Grid item sx={{p:"5px 30px"}}>
               <img style={{width: '65px'}} src={"/images/img_paypal.png"}/>
               <Typography variant="body1">{"Paypal"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid direction="row" xs={6} sx={{ marginLeft: "58%"}}>
          <Button id='ConfirmButton' sx={{ "padding": "15px 50px", "margin": "0 16px 10px 10px;",
            textTransform: "none"}} variant={"contained"} onClick={() => {navigate("/sub/order/")}}>Confirm</Button>
          <Button id='SaveButton' sx={{ "padding": "15px 50px", "margin": "0 16px 10px 10px;",
            textTransform: "none"}} variant="outlined" onClick={() => {navigate("/sub/order/")}}>Save to order list</Button>
          <Button id='CancelButton' sx={{ "padding": "15px 50px", "margin": "0 16px 10px 10px;",
            textTransform: "none"}} variant="outlined" onClick={() => {navigate("/sub/order/")}}>Cancel</Button>
        </Grid>
      </Paper>
    </Box>
  )
};
export default SubscriptionPayment
