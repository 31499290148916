
const WebURL = {
  APP_ROOT: "/",
  ZKLINK_FORWARD: "/att/zlink",
  ZKLINK_REDIRECT: "/att/redirect",
  AUTH_USER_LOGIN: "/user/login",
  AUTH_SIGN_UP: "/user/sign-up",
  AUTH_USER_COMPANY_SELECTION: "/user/organ-selection",
  AUTH_USER_COMPANY_CREATION: "/user/organ-creation",
  DASHBOARD: "/dashboard",

  ORGANIZATION_HOME: "/hr",
  HR_EMPLOYEE: "/hr/employee",
  HR_EMPLOYEE_VIEW: "/hr/employee/view",
  AUTH_ROLE: "/hr/role",
  AUTH_ROLE_VIEW: "/hr/role/view",
  AUTH_ROLE_NEW: "/hr/role/new",
  AUTH_ROLE_EDIT: "/hr/role/edit",

  ATT_RULE_HOME: "/att/rule",
  // ATT_DEVICE_LIST: "/att/rule/device",
  ATT_DEVICE_PERSONS: "/dms/device/persons/view",
  ATT_DEVICE_PERSONS_ADD: "/dms/device/persons/add",
  ATT_CLOCKING_COLLECTION_RULE: "/att/rule/collection",
  ATT_GPS_FENCE_CLOCKING: "/att/rule/gps-clocking",
  ATT_CLOCKING_COLLECTION_RULE_VIEW: "/att/rule/collection/view",
  ATT_TIMESHEET: "/att/rule/timesheet",
  ATT_TIMESHEET_HISTORY: "/att/rule/timesheet/history",
  ATT_TIMESHEET_VIEW: "/att/rule/timesheet/view",
  ATT_TIMESHEET_NEW: "/att/rule/timesheet/new",

  ATT_SCHEDULE: "/att/rule/schedule",
  ATT_SCHEDULE_HISTORY: "/att/rule/schedule/history",
  ATT_SCHEDULE_NEW: "/att/rule/schedule/new",
  ATT_TIMECARD_HOME: "/att/timecard",
  ATT_TIMECARD_PUNCH_RECORD: "/att/report/punch-record",
  ATT_TIMECARD_PUNCH_RECORD_HISTORY: "/att/report/punch-record/history",
  ATT_TIMECARD_TIMECARD: "/att/report/timecard",
  ATT_TIMECARD_TIMECARD_TIMES: "/att/report/timecard-times",
  ATT_REPORT_HOME: "/att/report",
  ATT_REPORT_FIRST_LAST: "/att/report/first-and-last",
  ATT_REPORT_FILO: "/att/report/first-in-last-out",
  ATT_REPORT_MONTHLY_REPORT: "/att/report/monthly",
  ATT_CALCTASK_LOG: "/att/calctasklog",
  ATT_CALCTASK: "/att/calctask",

  LOCATION_HOME: "/location",
  LOCATION_SITE: "/location/site",
  LOCATION_SITE_VIEW: "/location/site/view",
  LOCATION_SITE_NEW: "/location/site/add",
  LOCATION_SITE_EDIT: "/location/site/edit",
  LOCATION_ZONE: "/location/zone",
  LOCATION_ZONE_VIEW: "/location/zone/view",

  DMS_DEVICE: "/dms/device",
  DMS_DEVICE_ADD: "/dms/device/add",
  DMS_DEVICE_VIEW: "/dms/device/view",
  DMS_DEVICE_HISTORY: "/dms/device/history",
  DMS_DEVICE_EDIT: "/dms/device/edit",

  SUBSCRIPTION_HOME: "/sub",
  SUBSCRIPTION_ORDER: "/sub/order/",
  SUBSCRIPTION_NEW: "/sub/new/",
  SUBSCRIPTION_payment: "/sub/payment/",

  OTHER_404: "/404",
  OTHER_NO_PERMISSION: "nopermission"
}
export default WebURL
